import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

import { createAxiosClient } from "api";
import { useAuthState, useAuthDispatch } from "context";
import useStyles from "../styles";

import FormElements from "./FormElements";
import {
  GET_NOTIFICATION_CATEGORIES_FOR_USER,
  ENABLE_USERS_NOTIFICATION_CATEGORY,
  DISABLE_USERS_NOTIFICATION_CATEGORY,
} from "../gql";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Notifications() {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [notificationsList, setNotificationsList] = useState([]);

  const [loadReady, setLoadReady] = useState(false); //dont load until ready

  useEffect(() => {
    createAxiosClient(dispatch, state)
      .post("/graphql", {
        query: GET_NOTIFICATION_CATEGORIES_FOR_USER,
      })
      .then((response) => {
        const data = response.data.data;
        setNotificationsList(data.allNotificationCategories);
        setLoadReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toggle = (event, notification) => {
    let tmpList = [...notificationsList];
    let runQry = null;
    let disableId = null;

    tmpList.forEach((n) => {
      if (n.id === notification.id) {
        n.check = !n.check;
        runQry = n.check
          ? (runQry = ENABLE_USERS_NOTIFICATION_CATEGORY)
          : (runQry = DISABLE_USERS_NOTIFICATION_CATEGORY);
        disableId = n.id;
      }
    });

    setNotificationsList([...tmpList]);

    createAxiosClient(dispatch, state)
      .post("/graphql", {
        query: runQry,
        variables: {
          notificationCategoryId: disableId,
        },
      })
      .then((res) => {
        console.log(res.data.data.success);
        setOpenAlert(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCloseAlert = (event, reason) => {
    setHasError(false);
    setOpenAlert(false);
  };

  return (
    <Card className={classes.card}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        {hasError ? (
          <Alert onClose={handleCloseAlert} severity="error">
            An error occured, please try again
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success">
            Profile updated!
          </Alert>
        )}
      </Snackbar>
      <CardActions className={classes.cardActions}>
        {/* <Fragment> */}
        {/* <Grid container>
            <Grid item xs={11} style={{paddingTop: '10p'}}> */}
        {/* </Grid> */}
        {/* <Grid item xs={1}> */}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="small"
        >
          <ExpandMoreIcon />
        </IconButton>
        {/* </Grid> */}
        {/* </Grid> */}
        {/* </Fragment> */}
      </CardActions>

      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Enable/Disable Notifications
        </Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          ></Typography>
          <FormElements
            notifications={loadReady ? notificationsList : []}
            toggle={toggle}
          />
        </Collapse>
      </CardContent>
    </Card>
  );
}
