import { useState } from "react";
import { Route, Switch } from "react-router";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";

import NamedEntityRecognition from "./ner";
import WordEmbedding from "./word-embedding";
import PrototypeKnowledge from "./prototype-knowledge";

const useStyles = makeStyles((theme) => ({
  buttonSpacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > *": {
      width: "30%",
      height: "5%",
      margin: theme.spacing(1),
    },
  },
  buttonRoot: {
    flexGrow: 1,
  },
}));

const TooltipDescriptionModal = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))((props) => {
  const { children, classes, title, tooltip } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton variant="contained" onClick={handleOpen}>
          <InfoIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle disableTypography className={classes.root}>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{children}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default function Tools() {
  const classes = useStyles();
  let match = useRouteMatch();
  const [PopUp, setPopUp] = useState(false);
  const [PopUp2, setPopUp2] = useState(false);

  return (
    <>
      <Typography variant="h5">Tools</Typography>
      <Grid container direction="row" spacing={3}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={RouterLink}
            to={match.url}
            endIcon={
              <TooltipDescriptionModal
                title="Named Entity Recognition (NER)"
                tooltip="Named Entity Recognition (NER) automatically identifies domain entities in text."
              >
                The named entity recognition tool automatically identifies the
                important, energetics-related 'things' in your text. It is
                currently configured for identifying formulations and
                participating chemicals but will soon be updated to capture a
                wider set of interesting terms.
              </TooltipDescriptionModal>
            }
          >
            Named Entity Recognition (NER)
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={RouterLink}
            to={`${match.url}/knowledge-graph`}
            endIcon={
              <TooltipDescriptionModal
                title="Knowledge Graph"
                tooltip="The energetics knowledge graph is a developing ontology representation generated from machine recognized relationships"
              >
                The energetics knowledge graph is a developing ontology
                representation generated from machine recognized relationships.
              </TooltipDescriptionModal>
            }
          >
            Knowledge Graph
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component={RouterLink}
            to={`${match.url}/word-embedding`}
            endIcon={
              <TooltipDescriptionModal
                title="Word Embedding"
                tooltip="Word vectors are the numerical representation of words, learned from text data."
              >
                The word embedding visualizer shows a 2-dimensional projection
                of our word embeddings, trained on thousands of energetics
                documents. Word embedding algorithms embed, or 'bake', semantic
                and syntactic information into the embedding space, such that a
                word's proximity to another shows some semantic or syntactic
                similarity. On display here are thousands of these word vectors,
                each 128 dimensional, projected onto a 2-dimensional plane using
                a dimensionality reduction technique. If two words appear close
                to one another, there is a likelihood that they are semantically
                or syntactically similar. Though, the visualization here is
                imperfect due to the massive loss in information from
                dimensionality reduction. The full 128-dimensional word vectors
                serve as important features for many of our downstream machine
                learning tasks.
              </TooltipDescriptionModal>
            }
          >
            Word Embedding
          </Button>
        </Grid>
      </Grid>
      <Switch>
        <Route path={`${match.path}/word-embedding`}>
          <WordEmbedding />
        </Route>
        <Route path={`${match.path}/knowledge-graph`}>
          <PrototypeKnowledge />
        </Route>
        <Route path={match.path}>
          <NamedEntityRecognition />
        </Route>
      </Switch>
    </>
  );
}
