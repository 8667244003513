import Undex2 from "../../static/video/Undex2.mp4";
// import Javelin from "../../static/articles/Javelin.pdf";
import UndexPdf from "../../static/articles/UndexPdf.pdf";
import UndexSlides2 from "../../static/articles/UndexSlides2.pdf";
import UndexLecture1 from "../../static/articles/UNDEX_Lecture_01.pdf";

const data = {
  videos: [
    // {
    //   id: 0,
    //   src: "https://player.vimeo.com/video/582652186?h=7032aaae6e",
    //   description:
    //     "Bill Wilson, Principal Engineer\n ETC is developing machine-assisted discovery and invention based automated tools in support of scientists and engineers dedicated to the development of new energetic materials and weapon systems. Artificial intelligence and machine learning (AI/ML) algorithms developed by ETC will accelerate engineering and design programs for new undersea weapons.",
    //   component: "iframe",
    // },

    // {
    //   id: 1,
    //   src: "https://player.vimeo.com/video/544715999?h=720e1f88ea",
    //   description:
    //     "Catherine Shingleton, Engineer\n Using the proven approach of “Prize Competitions”, the ETC xTechSearch Program attempts connects technology companies to the Army’s technical community to jointly develop solutions to the Army’s most challenging problems.",
    //   component: "iframe",
    // },
    // {
    //   id: 2,
    //   src: "https://www.dhs.gov/xlibrary/videos/18_0529_st_trace-detection.mp4",
    //   description: " ",
    //   component: "video",
    // },
    {
      id: 3,
      name: "UNDEX Short Course #2",
      src: Undex2,
      pdf: UndexPdf,
      pdf2: UndexSlides2,
      description:
        "This brief provides a detailed introduction to underwater weapon effects and their underlying physics.  First it reviews fundamental explosive concepts in depth, such as explosions, and detonation.  Next, it reviews shock waves, shock wave equations, and second-order interactions, such reflection/rarefactions at boundary layers.  It then discusses the dynamics of bubble generation, bubble motion over time, and bubble effects on external targets.  Finally, the brief concludes with a discussion of surface effects, refraction effects, and a summary of the phenomena covered.",
      component: "video",
      tags: ["Dr. Frank VanGessel", "Underwater energetics", "Detonation"],
    },
  ],
  audio: [],
  images: [],
  pdfs: [
    {
      id: 1,
      name: "UNDEX Short Course #1",
      src: UndexLecture1,
      doc: "UNDEX Course",
      pdf: UndexLecture1,
      description:
        "This brief provides an introduction to the physics, chemistry and applications of underwater energetics.  First, it covers the high-level concepts, including basic definitions and fundamental equations in the field.  Next, it introduces the underwater domain and provides a brief overview of underwater weapon effects calculations.  Finally, the brief concludes with a discussion of the process of designing a formulation from molecular ingredients and the thermochemical calculations used to predict the performance.",
      component: "pdf",
      tags: ["Dr. Frank VanGessel", "Underwater energetics", "Detonation"],
    },
  ],
};

export default data;
