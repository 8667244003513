import useStyles from "../styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function Display({ notifications, toggle }) {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      {notifications?.map((notification, key) => {
        return (
          <Grid item className={classes.switchArray}>
            <FormControlLabel
              label={notification?.defaultMessage + ": "}
              labelPlacement="start"
              key={key}
              control={
                <Switch
                  checked={notification?.check}
                  onChange={(e) => {
                    toggle(e, notification);
                  }}
                />
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
