import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  genButtonPad: {
    marginLeft: "2px",
    marginRight: "2px",
  },
});

//can i use both of these?
const useStyles = makeStyles((theme) => ({
  genButtonPad: {
    marginLeft: "2px",
    marginRight: "2px",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    width: "100%",
    // overflowX: "hidden",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PdfViewerModal(props) {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNextPage = () => {
    setPageNumber((prev) => {
      if (prev < numPages) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  };

  const handlePrevPage = () => {
    setPageNumber((prev) => {
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={classes.genButtonPad}
        onClick={handleOpen}
      >
        {props.buttonLabel}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <DialogTitle id="pdf-viewer-dialog" onClose={handleClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers>
          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handlePrevPage}>
            Prev
          </Button>
          <p style={{ marginRight: "auto", marginLeft: "auto" }}>
            Page {pageNumber} of {numPages}
          </p>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={handleNextPage}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PdfViewerModal.defaultProps = {
  buttonLabel: "View Document",
};
