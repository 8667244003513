import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    paddingBottom: "5px",
  },
  cardContent: {
    paddingTop: "0px",
  },
  cardActions: {
    justifyContent: "flex-end",
    paddingBottom: "0px",
  },
  chipArray: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default useStyles;
