import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import DescriptionAccordion from "./DescriptionAccordion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  instructions: {
    margin: theme.spacing(1),
  },
}));

export default function Overview() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <DescriptionAccordion
        label="Dashboard"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        The Dashboard page aggregates information that is available on the
        system that match your profile Interests.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="Notes"
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        The Notes page allows you to enter new Notes and Ewaves, and browse all
        material submitted by other users. Notes are intended to summarize a
        piece of information by an informed user into an easily digestible
        format. Ewaves are a shorter form of Notes intended for brief updates or
        call-outs.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="Event Reports"
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        Event Reports are meant to detail everything that transpired at an event
        or conference. This is the new version of trip reports, as many events
        are now virtual.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="Transcripts"
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        The Transcripts page is the hosted transcription materials from
        different experts' knowledge capture interviews. Video and audio files
        may be available upon request.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="Knowledge Base"
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        The Knowledge Base contains educational resources to help you learn more
        about energetics and the field of studies related to it.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="News"
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        The News page aggregates all news from various sources, filters them to
        energetics relevant stories, and stores them chronologically.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="Tools"
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        The Tools page is intended to users to interact with developing AI/ML
        tools and provide feedback to Eware administrators. This is
        mid-development access to shorten the time to improvement by getting
        expert feedback early.
      </DescriptionAccordion>
      <DescriptionAccordion
        label="Search"
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        The Search page allows you to search for publications which have been
        uploaded to the system. The publications are a curated list submitted by
        a staff member that are run through a machine learning topic model.
      </DescriptionAccordion>
    </>
  );
}
