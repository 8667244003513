import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderRadius: "5px",
  },
}));

export default function Legend() {
  const classes = useStyles();

  return (
    <List className={classes.root} dense aria-label="event-category-legend">
      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon style={{ color: "#0C5AAD" }} />
        </ListItemIcon>
        <ListItemText primary="Conference" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon style={{ color: "#ED7D31" }} />
        </ListItemIcon>
        <ListItemText primary="Program Review" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon style={{ color: "#005C23" }} />
        </ListItemIcon>
        <ListItemText primary="Trade Show" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon style={{ color: "#B0730B" }} />
        </ListItemIcon>
        <ListItemText primary="Demonstration" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <FiberManualRecordIcon style={{ color: "#5CABFF" }} />
        </ListItemIcon>
        <ListItemText primary="Short Course" />
      </ListItem>
    </List>
  );
}
