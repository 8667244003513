import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { API } from "api";

import EventCard from "./EventCard";
import useStyles from "./styles";

import { useAxios } from "hooks";

const GET_EVENT_REPORTS = /* GraphQL */ `
  {
    allEventReportDocuments(ordering: { entryDate: DESC }) {
      edges {
        node {
          pk
          eventType
          eventName
          startDate
          endDate
          eventDistribution
          reportDistribution
          mainTakeaway
          keywords
          agenda
          attendees
          images {
            file
          }
          enteredBy {
            firstName
            lastName
          }
          entryDate
          comments {
            createdOn
          }
        }
      }
    }
  }
`;

export default function AllEventReports() {
  const api = useAxios();
  const classes = useStyles();
  const [eventReports, setEventReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", { query: GET_EVENT_REPORTS })
      .then((res) => {
        const data = res.data.data;
        setEventReports(data.allEventReportDocuments.edges);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (eventReports.length === 0) {
    return <div>Unable to load event reports...</div>;
  }

  return (
    <Grid container spacing={3}>
      {eventReports.map(({ node: eventReport }, index) => (
        <Grid className={classes.outercard} item xs={12} xl={6}>
          <EventCard
            key={eventReport.id}
            event={eventReport}
            showCommentButton={true}
            viewButton={true}
            viewDetails={false}
          />
        </Grid>
      ))}
    </Grid>
  );
}
