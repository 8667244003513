import React, { useState, useEffect } from "react";
import { useAxios } from "hooks";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import EwaveCard from "./EwaveCard.js";

const GET_EWAVES = /* GraphQL */ `
  {
    allEwaveDocuments(ordering: { pubDate: DESC }) {
      edges {
        node {
          id
          pk
          title
          body
          impact
          interests
          pubDate
          author {
            email
            organization
            firstName
            lastName
          }
          comments {
            createdOn
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

export default function AllEwaves() {
  const api = useAxios();
  const classes = useStyles();
  const [ewaves, setEwaves] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", { query: GET_EWAVES })
      .then((res) => {
        const data = res.data.data;

        setEwaves(data.allEwaveDocuments.edges);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (ewaves.length === 0) {
    return <div>Unable to load eWaves...</div>;
  }

  return (
    <Grid container spacing={3}>
      {ewaves.map(({ node: ewave }, index) => (
        <Grid className={classes.outercard} item xs={12} xl={6}>
          <EwaveCard
            key={ewave.id}
            ewave={ewave}
            showCommentButton={true}
            viewButton={true}
          />
        </Grid>
      ))}
    </Grid>
  );
}
