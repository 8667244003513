import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";

export default function CommentList({ comments }) {
  if (comments.length === 0) {
    return <></>;
  }
  return (
    <Card>
      <CardContent>
        <List>
          {comments.map((c, index) => {
            const fullName = c.user.firstName + " " + c.user.lastName;
            return (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={fullName} src="/to/be/determined.jpg" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={c.content}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {fullName}
                        </Typography>
                        {` — ${moment(c.createdOn).fromNow()}`}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {index !== comments.length - 1 && (
                  <Divider variant="inset" component="li" />
                )}
              </React.Fragment>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}
