const UPDATE_ACCOUNT = /* GraphQL */ `
  mutation UpdateAccount(
    $firstName: String
    $lastName: String
    $organization: String
    $requestReason: String
    $bio: String
    $interests: JSONString
    $onboardingComplete: Boolean
  ) {
    updateAccount(
      firstName: $firstName
      lastName: $lastName
      organization: $organization
      requestReason: $requestReason
      bio: $bio

      interests: $interests
      onboardingComplete: $onboardingComplete
    ) {
      success
      errors
    }
  }
`;

const UPLOAD_PROFILE_PICTURE = /* GraphQL */ `
  mutation($image: Upload!) {
    uploadProfilePicture(image: $image) {
      success
    }
  }
`;

const GET_NOTIFICATION_CATEGORIES_FOR_USER = /* GraphQL */ `
  {
    allNotificationCategories {
      id
      defaultMessage
      contentType {
        model
      }
      check
    }
  }
`;

const ENABLE_USERS_NOTIFICATION_CATEGORY = /* GraphQL */ `
  mutation EnableNotificationCategory($notificationCategoryId: ID!) {
    enableNotificationCategoryForUser(
      notificationCategoryId: $notificationCategoryId
    ) {
      success
    }
  }
`;

const DISABLE_USERS_NOTIFICATION_CATEGORY = /* GraphQL */ `
  mutation DisableNotificationCategory($notificationCategoryId: ID!) {
    disableNotificationCategoryForUser(
      notificationCategoryId: $notificationCategoryId
    ) {
      success
    }
  }
`;

export {
  UPDATE_ACCOUNT,
  UPLOAD_PROFILE_PICTURE,
  ENABLE_USERS_NOTIFICATION_CATEGORY,
  DISABLE_USERS_NOTIFICATION_CATEGORY,
  GET_NOTIFICATION_CATEGORIES_FOR_USER,
};
