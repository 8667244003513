import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { useAxios } from "hooks";

import TranscriptCard from "./TranscriptCard";

//GQL Query to fetch actual data
//TODO remove data, only a temporary measure
import data from "./data.js";

export default function TranscriptDetail() {
  let { id } = useParams();
  // const api = useAxios();
  const [transcript, setTranscript] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    data.transcripts.forEach((t) => {
      if (t.id === Number(id)) {
        setTranscript(t);
      }
      setIsLoading(false);
    });
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (transcript === null) {
    return <div>No transcript with that ID exists</div>;
  }

  return (
    <TranscriptCard
      transcript={transcript}
      showCommentButton={false}
      viewButton={false}
      viewMore={true}
    />
  );
}
