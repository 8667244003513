import React, { createRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import { useAxios } from "hooks";

import useStyles from "../styles";
import FormikTextField from "components/shared/formik/FormikTextField";
import { UPLOAD_PROFILE_PICTURE } from "../gql";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FormElements() {
  const api = useAxios();
  const classes = useStyles();
  const [image, _setImage] = useState(null);
  const [file, setFile] = useState(null);
  const inputFileRef = createRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [hasError, setHasError] = useState(false);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const onFileChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }

    setFile(newImage);
    setIsEditing(true);
  };

  const cancelEdit = () => {
    setImage(null);
    setIsEditing(false);
  };

  const uploadImage = () => {
    const formData = new FormData();

    formData.append(
      "operations",
      JSON.stringify({
        query: UPLOAD_PROFILE_PICTURE,
        variables: {
          image: null,
        },
      })
    );

    formData.append(
      "map",
      JSON.stringify({
        0: ["variables.image"],
      })
    );

    formData.append("0", file);

    api
      .post("/graphql", formData)
      .then((response) => {
        //console.log(response);
        setOpenAlert(true);
        setIsEditing(false);
      })
      .catch((error) => {
        console.log(error);
        setHasError(true);
        setOpenAlert(true);
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setHasError(false);
    setOpenAlert(false);
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        {hasError ? (
          <Alert onClose={handleCloseAlert} severity="error">
            An error occured, please try again
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success">
            Profile updated!
          </Alert>
        )}
      </Snackbar>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <input
            ref={inputFileRef}
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={onFileChange}
            hidden
          />
          <label htmlFor="contained-button-file">
            <IconButton component="span">
              <Avatar src={image} className={classes.avatar} />
            </IconButton>
          </label>
        </Grid>
        {isEditing && (
          <Grid item>
            <IconButton
              aria-label="cancel-edit-image"
              size="small"
              onClick={cancelEdit}
            >
              <ClearIcon />
            </IconButton>
            <IconButton
              aria-label="submit-edit-image"
              size="small"
              onClick={uploadImage}
            >
              <CheckIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <FormikTextField
        name="firstName"
        label="First name"
        className={classes.formControl}
        variant="outlined"
        helperText
      />
      <FormikTextField
        name="lastName"
        label="Last name"
        className={classes.formControl}
        variant="outlined"
        helperText
      />
      <FormikTextField
        name="organization"
        label="Organization"
        className={classes.formControl}
        variant="outlined"
        helperText
      />
    </Grid>
  );
}
