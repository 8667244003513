import React from "react";
import useStyles from "../styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

export default function Display({ firstName, lastName, organization }) {
  const classes = useStyles();

  const fullName = firstName + " " + lastName;
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <Avatar
          alt={fullName}
          src="/broken-img.jpg"
          className={classes.avatar}
        />
      </Grid>
      <Grid item>
        <Typography>{fullName}</Typography>
      </Grid>
      <Grid item>
        <Typography>{organization}</Typography>
      </Grid>
    </Grid>
  );
}
