import Box from "@material-ui/core/Box";
import ENTITY_LABEL_COLORS from "./entityLabelColors";

function renderNER({ text, entities }) {
  let visualizer = [];
  let offset = 0;
  entities.forEach(({ mention, label, charStart, charEnd }) => {
    const fragments = text.slice(offset, charStart).split("\n");

    fragments.forEach((fragment, i) => {
      visualizer.push(fragment);
    });

    visualizer.push(
      <Box
        key={charStart}
        style={{
          background: ENTITY_LABEL_COLORS[`${label}`] || "#7aecec",
          padding: "0.45em 0.6em",
          margin: "0 0.25em",
          lineHeight: 1,
          borderRadius: "0.35em",
          whiteSpace: "nowrap",
        }}
        display="inline"
        component="mark"
      >
        {mention}
        <Box
          style={{
            fontSize: "0.8em",
            fontWeight: "bold",
            lineHeight: 1,
            borderRadius: "0.35em",
            verticalAlign: "middle",
            marginLeft: "0.5rem",
          }}
          component="span"
        >
          {label}
        </Box>
      </Box>
    );
    offset = charEnd;
  });

  visualizer.push(text.slice(offset, text.length));

  return (
    <Box style={{ lineHeight: 2.5 }} component="div">
      {visualizer}
    </Box>
  );
}

export default function EntityVisualizer({ ner }) {
  return (
    <Box
      style={{
        overflowX: "auto",
        border: "1px solid rgb(230, 233, 239)",
        borderRadius: "0.25rem",
        padding: "1rem",
      }}
    >
      {renderNER(ner)}
    </Box>
  );
}
