import { FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import useStyles from "./styles";
import FormikTextField from "components/shared/formik/FormikTextField";

function ParticipantsForm({ ...props }) {
  const classes = useStyles();
  return (
    <FieldArray
      name={`conversations.${props.index}.participants`}
      render={(arrayHelpers) => (
        <Grid container direction="column">
          <Grid item>
            <Button
              variant="contained"
              color="default"
              aria-label="add a participant"
              endIcon={<AddIcon />}
              className={classes.formControl}
              onClick={() =>
                arrayHelpers.push({
                  fullName: "",
                  organization: "",
                  jobTitle: "",
                })
              }
            >
              Add a Participant
            </Button>
          </Grid>

          {props.values.participants.map((participant, index) => (
            <Grid
              key={`participant-${index}`}
              container
              item
              alignItems="center"
              wrap="nowrap"
            >
              <FormikTextField
                label="Last name, First"
                name={`conversations.${props.index}.participants.${index}.fullName`}
                variant="outlined"
                className={classes.formControl}
                helperText
              />
              <FormikTextField
                label="Organization"
                name={`conversations.${props.index}.participants.${index}.organization`}
                variant="outlined"
                className={classes.formControl}
                helperText
              />
              <FormikTextField
                label="Title"
                name={`conversations.${props.index}.participants.${index}.jobTitle`}
                variant="outlined"
                className={classes.formControl}
                helperText
              />
              <IconButton
                aria-label="remove-participant"
                size="small"
                onClick={() => arrayHelpers.remove(index)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}

export default function ConversationForm({ ...props }) {
  const classes = useStyles();
  return (
    <FieldArray
      name="conversations"
      render={(arrayHelpers) => (
        <>
          {props.values.conversations.map((conversation, index) => (
            <Card key={`conversation-${index}`} className={classes.card}>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item container>
                    <Typography variant="h6" color="textPrimary">
                      {`Conversation ${index + 1}`}
                    </Typography>
                    <IconButton
                      aria-label="remove-conversation"
                      size="small"
                      className={classes.right}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                  <ParticipantsForm values={conversation} index={index} />
                  <FormikTextField
                    label="Discussion"
                    name={`conversations.${index}.discussion`}
                    variant="outlined"
                    className={classes.formControl}
                    multiline
                    rows={4}
                    helperText
                  />
                  <Grid item>
                    <input
                      className={classes.fileInput}
                      id={`conversation-files-${index}`}
                      type="file"
                      name={`conversations.${index}.files`}
                      multiple
                      onChange={(event) => {
                        props.setFieldValue(`conversations.${index}.files`, [
                          ...event.currentTarget.files,
                        ]);
                      }}
                    />
                    <label htmlFor={`conversation-files-${index}`}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Uploads
                      </Button>
                    </label>
                  </Grid>
                  <List dense={true}>
                    {props.values.conversations[index].files.map(
                      (file, idx) => (
                        <ListItem>
                          <ListItemIcon>
                            <AttachFileIcon />
                          </ListItemIcon>
                          <ListItemText primary={file.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label={`delete-conversation-files-${idx}`}
                              onClick={() => {
                                props.values.conversations[index].files.splice(
                                  idx,
                                  1
                                );
                                props.setFieldValue(
                                  `conversations.${index}.files`,
                                  props.values.conversations[index].files
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )}
                  </List>
                </Grid>
              </CardContent>
            </Card>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="default"
              aria-label="add a conversation"
              endIcon={<AddIcon />}
              className={classes.button}
              onClick={() =>
                arrayHelpers.push({
                  participants: [
                    {
                      fullName: "",
                      organization: "",
                      jobTitle: "",
                    },
                  ],
                  discussion: "",
                  files: [],
                })
              }
            >
              Add a Conversation
            </Button>
          </Grid>
        </>
      )}
    />
  );
}
