import { useField } from "formik";
import TextField from "@material-ui/core/TextField";

export default function FormikTextField(props) {
  const [field, meta] = useField(props);
  const { helperText, maxCount, ...rest } = props;


  var displayError = helperText && meta.touched && meta.error;
  var over = field.value.length >= maxCount; 

  return (
    <TextField
      id={props.id || props.name}
      {...field}
      {...rest}
      error={(meta.touched && Boolean(meta.error)) || over} //Indicate error if over char limit
      helperText={displayError  //Show error message if exists
          ? displayError
          : maxCount            //Otherwise, show char count if provided
          ? (field.value.length + "/" + maxCount)
          : null
      }
    />
  );
}
