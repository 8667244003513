import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "hooks";

import NoteForm from "./NoteForm";

const GET_NOTE = /* GraphQL */ `
  query($id: String!) {
    noteById(id: $id) {
      id
      title
      whyItMatters
      topic
      interests
      details
      url
      references
      entryDate
      files {
        id
        file
      }
      enteredBy {
        email
        firstName
        lastName
      }
    }
  }
`;

export default function NoteEdit() {
  let { id } = useParams();
  const api = useAxios();
  const [note, setNote] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", {
        query: GET_NOTE,
        variables: { id },
      })
      .then((res) => {
        const data = res.data.data;
        setNote(data.noteById);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (note === null) {
    return <div>No note with that ID exists</div>;
  }

  return (
    <>
      <NoteForm note={note} />
    </>
  );
}
