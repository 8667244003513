import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";

import { useAxios } from "hooks";

import FormikTextField from "components/shared/formik/FormikTextField";
import FormikLabDropdown from "components/shared/formik/FormikLabDropdown";
import FormikLabMultiple from "components/shared/formik/FormikLabMultiple";
import MaxAttachmentWarning from "components/shared/MaxAttachmentWarning";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "50%",
  },
  infoButton: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  endAdornment: {
    position: "absolute",
    right: 0,
    top: "calc(50% - 14px)",
  },
  inputRoot: {
    "$hasClearIcon &": {
      paddingRight: 26 + 4 + 9,
    },
    '&[class*="MuiOutlinedInput-root"]': {
      padding: 9,
      "$hasClearIcon &": {
        paddingRight: 26 + 4 + 9,
      },
      "& $input": {
        padding: "9.5px 4px",
      },
      "& $input:first-child": {
        paddingLeft: 6,
      },
      "& $endAdornment": {
        right: 9,
      },
    },
  },
  fileInput: {
    "&$focused $clearIndicatorDirty": {
      visibility: "visible",
    },
    "@media (pointer: fine)": {
      "&:hover $clearIndicatorDirty": {
        visibility: "visible",
      },
    },
  },
  clearIndicator: {
    marginRight: -2,
    padding: 4,
    visibility: "hidden",
  },
  clearIndicatorDirty: {},
  hasClearIcon: {},
}));

const CREATE_EWAVE = /* GraphQL */ `
  mutation CreateEwave(
    $title: String!
    $body: String!
    $impact: String!
    $interests: JSONString!
    $files: [Upload]
  ) {
    createEwave(
      title: $title
      body: $body
      impact: $impact
      interests: $interests
      files: $files
    ) {
      success
    }
  }
`;

const impactDefault = "1 - High Impact";

const impactOptions = [impactDefault, "2 - Medium Impact", "3 - Low Impact"];

const interestsOptions = [];

const parseImpact = (impact) => {
  switch (impact) {
    case "1 - High Impact":
      return "1";
    case "2 - Medium Impact":
      return "2";
    case "3 - Low Impact":
      return "3";
    default:
      return "1";
  }
};

export default function EwaveForm() {
  const api = useAxios();
  const classes = useStyles();
  const filesRef = useRef();
  const [openAlert, setOpenAlert] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const { title, body, impact, interests, files } = values;

    const formData = new FormData();
    const map = {};

    files.forEach((f, index) => {
      map[index] = [`variables.files.${index}`];
      formData.append(`${index}`, f);
    });

    if (Object.entries(map).length !== 0) {
      formData.append("map", JSON.stringify(map));
    }

    formData.append(
      "operations",
      JSON.stringify({
        query: CREATE_EWAVE,
        variables: {
          title,
          body,
          impact: parseImpact(impact),
          interests: JSON.stringify(interests),
          files: new Array(files ? Object.entries(files).length : 0).fill(null),
        },
      })
    );

    api
      .post("/graphql", formData)
      .then((res) => {
        console.log(res);
        if (res.data.data.createEwave.success) {
          resetForm();
          filesRef.current.value = null;
        } else {
          setHasError(true);
        }
        setOpenAlert(true);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setOpenAlert(true);
        setSubmitting(false);
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setHasError(false);
    setOpenAlert(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        {hasError ? (
          <Alert onClose={handleCloseAlert} severity="error">
            An error occured, please try again
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success">
            E-Wave created!
          </Alert>
        )}
      </Snackbar>

      <Formik
        initialValues={{
          title: "",
          body: "",
          impact: impactDefault,
          interests: [],
          files: [],
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .max(50, "Max 50 characters")
            .required("Title is required"),
          body: Yup.string()
            .max(200, "Max 200 characters")
            .required("Message is required"),
          impact: Yup.string().required("Impact is required"),
          interests: Yup.array()
            .of(Yup.string())
            .required()
            .min(1, "Enter at least one interest"),
          files: Yup.mixed(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Card className={classes.card}>
            <Form>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid container item alignItems="center">
                    <Grid item>
                      <Typography variant="h6" color="textPrimary">
                        Create an eWave
                      </Typography>
                      <Typography variant="h7" color="textSecondary">
                        A short message to help spread awareness
                      </Typography>
                    </Grid>
                  </Grid>
                  <FormikTextField
                    label="Title"
                    name="title"
                    variant="outlined"
                    className={classes.formControl}
                    maxLength={2}
                    helperText
                  />
                  <FormikLabDropdown
                    id="impact"
                    label="Impact"
                    name="impact"
                    variant="outlined"
                    className={classes.formControl}
                    options={impactOptions}
                    helperText
                    disableClearable
                  />
                  <FormikLabMultiple
                    name="interests"
                    label="Interests"
                    className={classes.formControl}
                    options={interestsOptions}
                    helperText
                  />
                  <FormikTextField
                    label="Message"
                    name="body"
                    variant="outlined"
                    multiline
                    rows={4}
                    className={classes.formControl}
                    helperText
                    maxCount={200}
                  />
                  <TextField
                    name="files"
                    type="file"
                    variant="outlined"
                    inputProps={{ multiple: true }}
                    InputProps={{
                      className: classes.inputRoot,
                      endAdornment: (
                        <div className={classes.endAdornment}>
                          <IconButton
                            tabIndex={-1}
                            onClick={() => {
                              filesRef.current.value = null;
                              setFieldValue("files", []);
                            }}
                            aria-label="Clear"
                            title="Clear"
                            className={clsx(classes.clearIndicator, {
                              [classes.clearIndicatorDirty]:
                                values.files.length > 0 ||
                                values.files !== null,
                            })}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </div>
                      ),
                    }}
                    className={clsx(
                      classes.formControl,
                      classes.fileInput,
                      classes.hasClearIcon
                    )}
                    onChange={(event) => {
                      setFieldValue("files", [...event.currentTarget.files]);
                    }}
                    inputRef={filesRef}
                  />
                  <MaxAttachmentWarning
                    className={classes.formControl}
                    files={values.files}
                  />
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.formControl}
                >
                  {isSubmitting ? <CircularProgress size={24.5} /> : "Submit"}
                </Button>
              </CardActions>
            </Form>
          </Card>
        )}
      </Formik>
    </div>
  );
}
