import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import { API } from "api";

const VERIFY_ACCOUNT = /* GraphQL */ `
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

export default function VerifyAccount() {
  let { token } = useParams();
  const [verifyResult, setVerifyResult] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    async function verifyAccount() {
      var result = await API.post("/graphql", {
        query: VERIFY_ACCOUNT,
        variables: {
          token,
        },
      });
      setVerifyResult(result.data.data.verifyAccount);
      setIsLoading(false);
    }
    verifyAccount().catch((e) => {
      setHasErrors(e.data.verifyAccount.errors);
    });
  });

  if (isLoading) {
    return (
      <Grid container direction="column" alignItems="center">
        <CircularProgress />
        <p>Verifying Account...</p>
      </Grid>
    );
  }

  if (
    verifyResult.success === false &&
    verifyResult.errors.nonFieldErrors[0].code === "invalid_token"
  ) {
    return (
      <Redirect
        to={{
          pathname: "/activate",
          state: { invalidToken: true },
        }}
      />
    );
  }

  if (
    verifyResult.success === true ||
    (verifyResult.success === false &&
      verifyResult.errors.nonFieldErrors[0].code === "already_verified")
  ) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { verifyAccountSuccess: true },
        }}
      />
    );
  }

  return <></>;
}
