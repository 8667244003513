import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import PdfViewerModal from "../shared/PdfViewerModal";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(0.5),
  },
  right: {
    marginLeft: "auto",
  },
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  button: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  pdf: {
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    whiteSpace: "pre-line",
  },
  descriptionClamped: {
    whiteSpace: "pre-line",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function PdfViewer(props) {
  const classes = useStyles();
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const { name, pdf, description, tags, src } = props;

  const toggleClamp = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  return (
    <Card>
      <CardActions disableSpacing>
        {pdf && (
          <div className={classes.pdf}>
            <PdfViewerModal
              className={classes.right}
              title={name}
              file={pdf}
              buttonLabel="View Presentation"
            />
          </div>
        )}
      </CardActions>

      <Divider variant="middle" light />

      <CardContent>
        <Typography variant="h5" gutterBottom>
          {name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Description
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={clsx({
            [classes.descriptionClamped]: !detailsExpanded,
            [classes.description]: detailsExpanded,
          })}
          gutterBottom
        >
          {description}
        </Typography>
        {detailsExpanded &&
          tags.map((tag) => <Chip className={classes.chip} label={tag} />)}
      </CardContent>

      <CardActions disableSpacing>
        <Button
          color="default"
          variant="outlined"
          size="small"
          onClick={toggleClamp}
        >
          {detailsExpanded ? "Show Less" : "Show More"}
        </Button>
      </CardActions>
    </Card>
  );
}
