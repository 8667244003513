import React from "react";
import { Route, Switch } from "react-router";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import NoteForm from "./NoteForm";
import NoteEdit from "./NoteEdit";
import AllNotes from "./AllNotes";
import NoteDetail from "./NoteDetail";
import EwaveForm from "./EwaveForm";
import AllEwaves from "./AllEwaves";
import Ewave from "./EwaveDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  buttonSpacing: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > *": {
      width: "15%",
      height: "5%",
      margin: theme.spacing(1),
    },
  },
}));

export default function NoteComponent() {
  const classes = useStyles();
  let match = useRouteMatch();

  return (
    <div>
      <Typography variant="h5">Notes and eWaves</Typography>
      <div className={classes.buttonSpacing}>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={match.url}
        >
          New Note
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`${match.url}/all`}
        >
          All Notes
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`${match.url}/ewaves`}
        >
          New eWave
        </Button>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`${match.url}/ewaves/all`}
        >
          All eWaves
        </Button>
      </div>
      <Switch>
        <Route path={`${match.path}/ewaves/all`}>
          <AllEwaves />
        </Route>
        <Route path={`${match.path}/ewaves/:id`}>
          <Ewave />
        </Route>
        <Route path={`${match.path}/ewaves`}>
          <EwaveForm />
        </Route>
        <Route path={`${match.path}/all`}>
          <AllNotes />
        </Route>
        <Route path={`${match.path}/:id/edit`}>
          <NoteEdit />
        </Route>
        <Route path={`${match.path}/:id`}>
          <NoteDetail />
        </Route>
        <Route path={match.path}>
          <NoteForm />
        </Route>
      </Switch>
    </div>
  );
}
