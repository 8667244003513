import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import moment from "moment";

import { useAxios } from "hooks";

import Legend from "./Legend";

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    maxWidth: "450px",
    zIndex: "10000",
  },
  popoverExpand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  popoverExpandOpen: {
    transform: "rotate(180deg)",
  },
  popper: {
    zIndex: 10000, // so we can popover the FullCalendar popover (zIndex of 9999)
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
}));

const GET_EVENTS = /* GraphQL */ `
  {
    allEventDocuments {
      edges {
        node {
          title
          url
          eventCategory
          additionalNotes
          start
          end
          registrationDeadline
          abstractDeadline
          allDay
        }
      }
    }
  }
`;

const renderEventContent = (eventInfo) => {
  if (
    moment(eventInfo.event._instance.range.start).isSame(
      eventInfo.event._instance.range.end,
      "day"
    )
  ) {
    console.log(eventInfo);
  }
  return <Typography variant="body2">{eventInfo.event._def.title}</Typography>;
};

export default function Calendar() {
  const classes = useStyles();
  const api = useAxios();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const categoryToColor = (category) => {
      switch (category) {
        case "Conference":
          return "#0C5AAD"; // ETC Blue
        case "Program Review":
          return "#ED7D31"; // ETC Orange
        case "Trade Show":
          return "#005C23"; // ETC Green
        case "Demonstration":
          return "#B0730B";
        case "Short Course":
          return "#5CABFF";
        default:
          return "#0C5AAD";
      }
    };

    const addCalendarClasses = (events) => {
      return events.map((e) => {
        const backgroundColor = categoryToColor(e.eventCategory);
        return {
          ...e,
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
        };
      });
    };

    api
      .post("/graphql", { query: GET_EVENTS })
      .then((res) => {
        const data = res.data.data;
        const nodes = data.allEventDocuments.edges.map((edge) => edge.node);

        const calendarEvents = addCalendarClasses(nodes);

        setEvents(calendarEvents);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [popoverContent, setPopoverContent] = useState({});

  const handleEventClick = (clickInfo) => {
    clickInfo.jsEvent.preventDefault();

    setAnchorEl(clickInfo.el);
    setPopoverContent({
      title: clickInfo.event._def.title,
      url: clickInfo.event._def.url,
      additionalNotes: clickInfo.event._def.extendedProps.additionalNotes,
      registrationDeadline:
        clickInfo.event._def.extendedProps.registrationDeadline,
      abstractDeadline: clickInfo.event._def.extendedProps.abstractDeadline,
      start: clickInfo.event._instance.range.start,
      end: clickInfo.event._instance.range.end,
    });
  };

  const handleClickAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }

    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? "event-popper" : undefined;

  return (
    <Grid item container spacing={2}>
      <Grid item xs={10}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends={false}
          dayMaxEvents={true}
          eventClick={handleEventClick}
          selectable={true}
          events={events}
          eventContent={renderEventContent}
        />
      </Grid>
      <Grid item xs={2}>
        <Legend />
      </Grid>
      <Popper
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        placement="bottom"
        style={{ zIndex: "10000" }}
        className={classes.popper}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <ClickAwayListener onClickAway={handleClickAway}>
          <Card className={classes.popoverContent} elevation={8}>
            <CardHeader
              title={popoverContent.title}
              subheader={
                moment(popoverContent.start).isSame(popoverContent.end, "day")
                  ? moment(popoverContent.start).format("MMM Do, YYYY")
                  : `${moment(popoverContent.start).format(
                      "MMM Do, YYYY"
                    )} - ${moment(popoverContent.end).format("MMM Do, YYYY")}`
              }
              action={
                <IconButton
                  aria-label="event-link"
                  component={Link}
                  rel="noopener"
                  target="_blank"
                  href={popoverContent.url}
                >
                  <LinkIcon />
                </IconButton>
              }
            />
            <CardContent>
              {popoverContent.registrationDeadline && (
                <>
                  <Typography display="inline" variant="body2">
                    Registration Deadline:{" "}
                  </Typography>
                  <Typography display="inline">
                    {moment(popoverContent.registrationDeadline).format(
                      "MMM Do, YYYY"
                    )}
                  </Typography>
                  <br />
                </>
              )}
              {popoverContent.abstractDeadline && (
                <>
                  <Typography display="inline" variant="body2">
                    Abstract Deadline:{" "}
                  </Typography>
                  <Typography display="inline">
                    {moment(popoverContent.abstractDeadline).format(
                      "MMM Do, YYYY"
                    )}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
}
