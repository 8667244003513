import React, { useEffect, useState, useMemo } from "react";

import { NotifyContext } from "./context.js";

import { useAuthState, useAuthDispatch } from "../context";
import { createAxiosClient } from "api";

const MARK_NOTIFY_READ = /* GraphQL */ `
  mutation markNotificationRead($id: ID!) {
    markNotificationRead(id: $id) {
      success
    }
  }
`;

const MARK_NOTIFY_UNREAD = /* GraphQL */ `
  mutation markNotificationUnread($id: ID!) {
    markNotificationUnread(id: $id) {
      success
    }
  }
`;

const MARK_NOTIFY_DELETED = /* GraphQL */ `
  mutation markNotificationDeleted($id: ID!) {
    markNotificationDeleted(id: $id) {
      success
    }
  }
`;

export default function NotifyProvider({ children }) {
  const state = useAuthState();
  const dispatch = useAuthDispatch();

  const [notificationsList, setNotificationsList] = useState([]);

  useEffect(() => {
    console.log("Provider");
    createAxiosClient(dispatch, state)
      .post("/graphql", {
        query: /* GraphQL */ `
          {
            notificationsToUser {
              id
              notificationCategory {
                defaultMessage
                contentType {
                  model
                }
              }
              contentType {
                model
              }
              objectId
              createdOn
              read
              sourceId
              categoryEnabled
            }
          }
        `,
      })
      .then((response) => {
        const data = response.data.data;
        setNotificationsList(data.notificationsToUser);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [state, dispatch]);

  const markNotifyRead = (n_id) => {
    createAxiosClient(dispatch, state)
      .post("/graphql", {
        query: MARK_NOTIFY_READ,
        variables: {
          id: n_id,
        },
      })
      .then((response) => {
        const data = response.data.data.markNotificationRead.success;
        if (data) {
          const newList = notificationsList.map((n) => {
            if (n.id === n_id) {
              return { ...n, read: true };
            }

            return n;
          });
          setNotificationsList(newList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markNotifyUnread = (n_id) => {
    createAxiosClient(dispatch, state)
      .post("/graphql", {
        query: MARK_NOTIFY_UNREAD,
        variables: {
          id: n_id,
        },
      })
      .then((response) => {
        const data = response.data.data.markNotificationUnread.success;

        if (data) {
          const newList = notificationsList.map((n) => {
            if (n.id === n_id) {
              return { ...n, read: false };
            }

            return n;
          });
          setNotificationsList(newList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const markNotifyDeleted = (n_id) => {
    createAxiosClient(dispatch, state)
      .post("/graphql", {
        query: MARK_NOTIFY_DELETED,
        variables: {
          id: n_id,
        },
      })
      .then((response) => {
        const data = response.data.data.markNotificationDeleted.success;
        if (data) {
          const newList = notificationsList.map((n) => {
            if (n.id === n_id) {
              return { ...n, deleted: true };
            }

            return n;
          });
          setNotificationsList(newList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <NotifyContext.Provider
      value={{
        notificationsList,
        markNotifyRead,
        markNotifyUnread,
        markNotifyDeleted,
      }}
    >
      {children}
    </NotifyContext.Provider>
  );
}
