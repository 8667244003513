import Recommendations from "./Recommendations";
import Typography from "@material-ui/core/Typography";

export default function Dashboard() {
  return (
    <>
      <Typography variant="h5">Dashboard</Typography>
      <Recommendations />
    </>
  );
}
