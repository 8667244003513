import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//really not sure how much I like this

//Function duplicated here so this can be used in more than one place
const constructAuthors = (authors) => {
  return (
    <em>
      {authors.map((author, i) => {
        var fullName = author.givenName + " " + author.surname;
        if (fullName === " ") {
          return "";
        } else if (authors.length !== i + 1) {
          fullName += ", ";
        }
        return fullName;
      })}
    </em>
  );
};

export default function SearchResultListItem(props) {
  const [open, setOpen] = React.useState(false);

  const authors = constructAuthors(props.authors);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        className="right"
        onClick={handleClickOpen}
      >
        Details
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p> {props.abstract}</p>
            <h5> {authors} </h5>
            {props.classifications.map((c, i) => (
              <p key={i}>
                {c.topic}: {Math.trunc(c.rank * 100)}%
              </p>
            ))}
            <h5>Keywords</h5>
            {props.keywords}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

// title={hit.title}
// abstract={hit.abstract}
// authors={hit.authors}
// classifications={hit.classifications}
// keywords={hit.keywords}
