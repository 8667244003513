import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { Container } from "../shared";
import ResendVerificationForm from "./ResendVerificationForm";
import VerifyAccount from "./VerifyAccount";

export default function EmailVerification() {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.url}/:token`} children={<VerifyAccount />} />
        <Route path={match.url} children={<ResendVerificationForm />} />
      </Switch>
    </Container>
  );
}
