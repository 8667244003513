import axios from "axios";

import { logout, setNewToken } from "context/actions";

let baseURL;

switch (process.env.NODE_ENV) {
  case "development":
    baseURL = process.env.REACT_APP_DEV_API_BASE_URL;
    break;
  case "test":
    baseURL = process.env.REACT_APP_TEST_API_BASE_URL;
    break;
  default:
    baseURL = window.location.origin;
}

export const API = axios.create({
  baseURL: baseURL,
});

const REFRESH_TOKEN = /* GraphQL */ `
  mutation RefreshToken($refreshToken: String!, $otpDeviceId: String) {
    refreshToken(refreshToken: $refreshToken, otpDeviceId: $otpDeviceId) {
      success
      errors
      payload
      token
    }
  }
`;

export const createAxiosClient = (
  dispatch,
  { token, refreshToken, otpDeviceId }
) => {
  const axiosApiInstance = axios.create({
    baseURL,
  });

  axiosApiInstance.interceptors.request.use(
    (config) => {
      if (token && !config.headers["Authorization"]) {
        config.headers["Authorization"] = `JWT ${token}`;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosApiInstance.interceptors.response.use(
    async (response) => {
      const previousRequest = response.config;

      // Justin: commenting this out because it's in the way
      // console.log("Before intercept");
      // console.log(response);
      // console.log("response.data.data?.me is ");
      // console.log(response.data.data?.me);

      // console.log("update account response is ");
      // console.log(
      //   response.data.data?.updateAccount?.errors?.nonFieldErrors?.[0].message
      // );

      // TODO investigate possible error codes
      if (
        (response.data.errors?.[0].message ===
          "You do not have permission to perform this action" ||
          response.data.data?.updateAccount?.errors?.nonFieldErrors?.[0]
            .message === "Unauthenticated." ||
          response.data.data?.me === null) &&
        !previousRequest._retry
      ) {
        previousRequest._retry = true;
        console.log("performing refresh");

        try {
          const res = await axios.post(`${baseURL}/graphql`, {
            query: REFRESH_TOKEN,
            variables: {
              refreshToken: refreshToken,
              otpDeviceId: otpDeviceId,
            },
          });

          console.log("refresh response");
          console.log(res);

          const { token } = res.data.data.refreshToken;

          if (res.status === 200) {
            setNewToken(dispatch, token);
            previousRequest.headers["Authorization"] = `JWT ${token}`;
            return axiosApiInstance(previousRequest);
          }
        } catch (error) {
          logout(dispatch);
          return Promise.reject(error);
        }
      }
      return Promise.resolve(response);
    },
    async (error) => {
      const previousRequest = error.config;
      console.log(error);

      if (
        error.response.status === 401 &&
        (error.response.data.errors?.[0].message ===
          "You do not have permission to perform this action" ||
          error.response.data.data?.updateAccount?.errors?.nonFieldErrors?.[0]
            .message === "Unauthenticated." ||
          error.response.data.data?.me === null) &&
        !previousRequest._retry
      ) {
        previousRequest._retry = true;

        try {
          const res = await axios.post(`${baseURL}/graphql`, {
            query: REFRESH_TOKEN,
            variables: {
              refreshToken: refreshToken,
              otpDeviceId: otpDeviceId,
            },
          });

          const { token } = res.data.data.refreshToken;

          if (res.status === 200) {
            setNewToken(dispatch, token);
            previousRequest.headers["Authorization"] = `JWT ${token}`;
            return axiosApiInstance(previousRequest);
          }
        } catch (error) {
          logout(dispatch);
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosApiInstance;
};
