import { Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";

import PublicationView from "./PublicationDetails";
import SearchPage from "./SearchPage";

export default function Search() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <PublicationView />
      </Route>
      <Route path={match.path}>
        <SearchPage />
      </Route>
    </Switch>
  );
}
