import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import NoteIcon from "@material-ui/icons/Note";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FlightIcon from "@material-ui/icons/Flight";
import { NavLink as RouterLink } from "react-router-dom";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BuildIcon from "@material-ui/icons/Build";
import Badge from "@material-ui/core/Badge";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import EventIcon from "@material-ui/icons/Event";

import { useAuthState } from "context";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -18,
    top: 5,
  },
}))(Badge);

function BadgedListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink
          to={to}
          ref={ref}
          {...itemProps}
          activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
        />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

BadgedListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
};

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink
          to={to}
          ref={ref}
          {...itemProps}
          activeStyle={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}
        />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    // width: 240,
  },
});

export default function ListRouter() {
  const classes = useStyles();
  const state = useAuthState();

  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <List aria-label="main mailbox folders">
          <ListItemLink
            to="/dashboard"
            primary="Dashboard"
            icon={<DashboardIcon />}
          />
          <ListItemLink to="/notes" primary="Notes" icon={<NoteIcon />} />
          <ListItemLink
            to="/calendar"
            primary="Calendar"
            icon={<EventIcon />}
          />
          <ListItemLink
            to="/events"
            primary="Event Reports"
            icon={<FlightIcon />}
          />
          <ListItemLink
            to="/transcripts"
            primary="Transcripts"
            icon={<AssignmentIcon />}
          />
          <ListItemLink
            to="/knowledge"
            primary="Knowledge Base"
            icon={<AccountBalanceIcon />}
          />
          <ListItemLink to="/news" primary="News" icon={<LibraryBooksIcon />} />
          <ListItemLink to="/tools" primary="Tools" icon={<BuildIcon />} />
          <ListItemLink to="/search" primary="Search" icon={<SearchIcon />} />
          {state.user.isStaff && (
            <ListItemLink
              to="/upload"
              primary="Publication Upload"
              icon={<CloudUploadIcon />}
            />
          )}
        </List>
        <Divider />
      </Paper>
    </div>
  );
}
