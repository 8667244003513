import { FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import useStyles from "./styles";
import FormikTextField from "components/shared/formik/FormikTextField";

function TakeawaysForm({ ...props }) {
  const classes = useStyles();

  return (
    <FieldArray
      name={`presentations.${props.index}.takeaways`}
      render={(arrayHelpers) => (
        <Grid container direction="column">
          <Grid item>
            <Button
              variant="contained"
              color="default"
              aria-label="add a takeaway"
              endIcon={<AddIcon />}
              className={classes.formControl}
              onClick={() => arrayHelpers.push("")}
            >
              Add a Significant Takeaway
            </Button>
          </Grid>

          {props.values.takeaways.map((takeaway, index) => (
            <Grid
              key={`takeaway-${index}`}
              container
              item
              alignItems="center"
              wrap="nowrap"
            >
              <FormikTextField
                label={`Takeaway ${index + 1}`}
                name={`presentations.${props.index}.takeaways.${index}.takeaway`}
                variant="outlined"
                multiline
                rows={10}
                className={classes.formControl}
                fullWidth
                helperText
              />
              <Grid item>
                <IconButton
                  aria-label="remove-takeaway"
                  size="small"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    />
  );
}

export default function PresentationForm({ ...props }) {
  const classes = useStyles();
  return (
    <FieldArray
      name="presentations"
      render={(arrayHelpers) => (
        <>
          {props.values.presentations.map((presentation, index) => (
            <Card key={`presentation-${index}`} className={classes.card}>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item container>
                    <Typography variant="h6" color="textPrimary">
                      {`Presentation ${index + 1}`}
                    </Typography>
                    <IconButton
                      aria-label="remove-presentation"
                      size="small"
                      className={classes.right}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                  <Grid item container spacing={2}>
                    <FormikTextField
                      label="Last name, First"
                      name={`presentations.${index}.presenterName`}
                      variant="outlined"
                      className={classes.formControl}
                      helperText
                    />
                    <FormikTextField
                      label="Organization"
                      name={`presentations.${index}.presenterOrg`}
                      variant="outlined"
                      className={classes.formControl}
                      helperText
                    />
                  </Grid>
                  <FormikTextField
                    label="Presentation Title"
                    name={`presentations.${index}.title`}
                    variant="outlined"
                    className={classes.formControl}
                    helperText
                  />
                  {/* <Grid item container spacing={2}>
                    <FormikTextField
                      label="Presentation Date"
                      name={`presentations.${index}.date`}
                      type="date"
                      variant="outlined"
                      className={classes.formControl}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText
                    />
                    <FormikTextField
                      label="Presentation Time"
                      name={`presentations.${index}.time`}
                      type="time"
                      variant="outlined"
                      className={classes.formControl}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText
                    />
                  </Grid> */}
                  <TakeawaysForm values={presentation} index={index} />
                  <Grid container item spacing={2}>
                    <Grid item>
                      <input
                        id={`presentation-file-${index}`}
                        className={classes.fileInput}
                        type="file"
                        name={`presentations.${index}.presentation`}
                        onChange={(event) => {
                          props.setFieldValue(
                            `presentations.${index}.presentation`,
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <label htmlFor={`presentation-file-${index}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload Presentation
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <input
                        id={`presentation-image-files-${index}`}
                        accept="image/*"
                        className={classes.fileInput}
                        type="file"
                        name={`presentations.${index}.images`}
                        multiple
                        onChange={(event) => {
                          props.setFieldValue(`presentations.${index}.images`, [
                            ...event.currentTarget.files,
                          ]);
                        }}
                      />
                      <label htmlFor={`presentation-image-files-${index}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload Images
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                  <List dense={true}>
                    {props.values.presentations[index].presentation && (
                      <ListItem>
                        <ListItemIcon>
                          <AttachFileIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Presentation - ${props.values.presentations[index].presentation.name}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label={`delete-presentation-file-${index}`}
                            onClick={() =>
                              props.setFieldValue(
                                `presentations.${index}.presentation`,
                                null
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                    {props.values.presentations[index].images.map(
                      (img, idx) => (
                        <ListItem>
                          <ListItemIcon>
                            <AttachFileIcon />
                          </ListItemIcon>
                          <ListItemText primary={`Image - ${img.name}`} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label={`delete-presentation-image-files-${idx}`}
                              onClick={() => {
                                props.values.presentations[index].images.splice(
                                  idx,
                                  1
                                );
                                props.setFieldValue(
                                  `presentations.${index}.images`,
                                  props.values.presentations[index].images
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )}
                  </List>
                </Grid>
              </CardContent>
            </Card>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="default"
              aria-label="add a presentation"
              endIcon={<AddIcon />}
              className={classes.button}
              onClick={() =>
                arrayHelpers.push({
                  presenterName: "",
                  presenterOrg: "",
                  // date: null,
                  // time: null,
                  title: "",
                  takeaways: [{ takeaway: "" }],
                  presentation: null,
                  images: [],
                })
              }
            >
              Add a Presentation
            </Button>
          </Grid>
        </>
      )}
    />
  );
}
