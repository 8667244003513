import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import VideoPlayer from "./VideoPlayer";
import KBIntroduction from "./KBIntroduction";

//TODO remove data, only a temporary measure
import data from "./data.js";
import PdfViewer from "./PdfViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function KnowledgePage() {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root} spacing={2}>
      <Grid item>
        <Typography variant="h5">Knowledge Base</Typography>
        <Card className={classes.card}>
          <CardContent>
            <KBIntroduction />
          </CardContent>
        </Card>
      </Grid>

      {data.videos.map((video) => (
        <Grid key={video.id} item xs={9}>
          <VideoPlayer
            key={video.id}
            name={video.name}
            src={video.src}
            description={video.description}
            component={video.component}
            autoPlay={false}
            pdf={video.pdf}
            pdf2={video.pdf2}
            tags={video.tags}
          />
        </Grid>
      ))}

      {data.pdfs.map((pdf) => (
        <Grid key={pdf.id} item xs={9}>
          <PdfViewer
            key={pdf.id}
            name={pdf.name}
            description={pdf.description}
            doc={pdf.doc}
            pdf={pdf.pdf}
            tags={pdf.tags}
          />
        </Grid>
      ))}
    </Grid>
  );
}
