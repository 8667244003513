import React from "react";

import useStyles from "../styles";
import FormikLabMultiple from "components/shared/formik/FormikLabMultiple";

const options = [];

export default function FormElements() {
  const classes = useStyles();
  return (
    <FormikLabMultiple
      id="interests"
      name="interests"
      label="Enter interests"
      className={classes.formControl}
      options={options}
      helperText
    />
  );
}
