import { makeStyles } from "@material-ui/core";
import { Formik, Form } from "formik";
import FormikTextField from "components/shared/formik/FormikTextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import SendIcon from "@material-ui/icons/Send";
import { useSnackbar } from "notistack";

import { useAxios } from "hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const GET_NER = /* GraphQL */ `
  query GetNER($text: String!) {
    nerTransformer(text: $text) {
      text
      entities {
        mention
        label
        tokStart
        tokEnd
        charStart
        charEnd
      }
    }
  }
`;

export default function NamedEntityRecognitionForm({
  setNer,
  onboardingRedirect,
}) {
  const api = useAxios();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const { text } = values;

    api
      .post("/graphql", {
        query: GET_NER,
        variables: { text },
      })
      .then((res) => {
        const data = res.data.data;
        setNer(data.nerTransformer);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong, please try again...", {
          variant: "error",
        });
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        text: onboardingRedirect
          ? "The synthesis of 1,1-diamino-2,2-dinitroethene (FOX-7) by nitration of 4,6-dihydroxy-2-methylpyrimidine and hydrolysis of the resulting intermediate 2-dinitromethylene-5,5-dinitropy-rimidine-4,6-dione has been studied. By varying the reaction parameters the optimal conditions for the synthesis of FOX-7 have been identified and gave a >90% yield of the pure product. The optimised process allowed the spent acid to be recycled without loss of yield, with almost stoichiometric consumption of nitric acid. The purity of the FOX-7 has been determined using a newly developed HPLC method 1,1-Diamino-2,2-dinitroethene (FOX-7, 4) has become a widely known, new explosive with great potential for a variety of applications."
          : "",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values, setSubmitting, resetForm);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card className={classes.card}>
            <CardContent>
              <FormikTextField
                label="Text"
                name="text"
                variant="outlined"
                multiline
                fullWidth
                rows={12}
              />
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                className={classes.button}
                endIcon={<SendIcon />}
              >
                {isSubmitting ? (
                  <CircularProgress size={24.5} />
                ) : (
                  "Perform NER"
                )}
              </Button>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
