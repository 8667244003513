const data = {
    nodes: [
        { type: "node", id: "326", labels: ["document_object"], properties: {name :"doc_0"} },
        { type: "node", id: "327", labels: ["energeticFormulation"], properties: {name :"lx-02-1"} },
        { type: "node", id: "328", labels: ["formulationIngredient"], properties: {name :"petn"} },
        { type: "node", id: "329", labels: ["formulationIngredient"], properties: {name :"butyl rubber"} },
        { type: "node", id: "330", labels: ["formulationIngredient"], properties: {name :"acetyltributyl citrate"} },
        { type: "node", id: "331", labels: ["formulationIngredient"], properties: {name :"cab-o-sil 73.5/17, 6/6, 9/2.0"} },
        { type: "node", id: "332", labels: ["document_object"], properties: {name :"doc_1"} },
        { type: "node", id: "333", labels: ["energeticFormulation"], properties: {name :"comp b"} },
        { type: "node", id: "334", labels: ["percent"], properties: {name :"59.5%"} },
        { type: "node", id: "335", labels: ["formulationIngredient"], properties: {name :"rdx"} },
        { type: "node", id: "336", labels: ["percent"], properties: {name :"58.3 ± 1.1%"} },
        { type: "node", id: "337", labels: ["percent"], properties: {name :"39.5%"} },
        { type: "node", id: "338", labels: ["formulationIngredient"], properties: {name :"tnt"} },
        { type: "node", id: "339", labels: ["percent"], properties: {name :"1%"} },
        { type: "node", id: "340", labels: ["formulationIngredient"], properties: {name :"wax"} },
        { type: "node", id: "341", labels: ["document_object"], properties: {name :"doc_2"} },
        { type: "node", id: "342", labels: ["energeticFormulation"], properties: {name :"x0237"} },
        { type: "node", id: "343", labels: ["percent"], properties: {name :"90/5/5 wt %"} },
        { type: "node", id: "344", labels: ["formulationIngredient"], properties: {name :"tatb"} },
        { type: "node", id: "345", labels: ["formulationIngredient"], properties: {name :"elvax"} },
        { type: "node", id: "346", labels: ["quantity"], properties: {name :"1.739 g/cc"} },
        { type: "node", id: "347", labels: ["document_object"], properties: {name :"doc_3"} },
        { type: "node", id: "348", labels: ["energeticFormulation"], properties: {name :"pbx 9501"} },
        { type: "node", id: "349", labels: ["percent"], properties: {name :"95%"} },
        { type: "node", id: "350", labels: ["formulationIngredient"], properties: {name :"hmx"} },
        { type: "node", id: "351", labels: ["percent"], properties: {name :"2.5%"} },
        { type: "node", id: "352", labels: ["formulationIngredient"], properties: {name :"estane®"} },
        { type: "node", id: "353", labels: ["formulationIngredient"], properties: {name :"bdnpa-f"} },
        { type: "node", id: "354", labels: ["document_object"], properties: {name :"doc_4"} },
        { type: "node", id: "355", labels: ["energeticFormulation"], properties: {name :"pbx 9407"} },
        { type: "node", id: "356", labels: ["percent"], properties: {name :"94%"} },
        { type: "node", id: "357", labels: ["percent"], properties: {name :"6%"} },
        { type: "node", id: "358", labels: ["formulationIngredient"], properties: {name :"vctfe; llnl lot b808"} },
        { type: "node", id: "359", labels: ["document_object"], properties: {name :"doc_5"} },
        { type: "node", id: "360", labels: ["percent"], properties: {name :"15%"} },
        { type: "node", id: "361", labels: ["formulationIngredient"], properties: {name :"reactive aluminum powder"} },
        { type: "node", id: "362", labels: ["formulationIngredient"], properties: {name :"magnesium-boron composite powder"} },
        { type: "node", id: "363", labels: ["formulationIngredient"], properties: {name :"lif"} },
        { type: "node", id: "364", labels: ["document_object"], properties: {name :"doc_6"} },
        { type: "node", id: "365", labels: ["energeticFormulation"], properties: {name :"pbx 9502"} },
        { type: "node", id: "366", labels: ["percent"], properties: {name :"95 weight %"} },
        { type: "node", id: "367", labels: ["formulationIngredient"], properties: {name :"dry-aminated tri-amino-tri-nitrobenzene"} },
        { type: "node", id: "368", labels: ["percent"], properties: {name :"5 weight %"} },
        { type: "node", id: "369", labels: ["formulationIngredient"], properties: {name :"kel-f 800"} },
        { type: "node", id: "370", labels: ["document_object"], properties: {name :"doc_7"} },
        { type: "node", id: "371", labels: ["energeticFormulation"], properties: {name :"pentolite"} },
        { type: "node", id: "372", labels: ["percent"], properties: {name :"50 weight %"} },
        { type: "node", id: "373", labels: ["formulationIngredient"], properties: {name :"tnt/"} },
        { type: "node", id: "374", labels: ["document_object"], properties: {name :"doc_8"} },
        { type: "node", id: "375", labels: ["percent"], properties: {name :"5%"} },
        { type: "node", id: "376", labels: ["formulationIngredient"], properties: {name :"kel-f"} },
        { type: "node", id: "377", labels: ["document_object"], properties: {name :"doc_9"} },
        { type: "node", id: "378", labels: ["energeticFormulation"], properties: {name :"composition b"} },
        { type: "node", id: "379", labels: ["percent"], properties: {name :"60 wt%"} },
        { type: "node", id: "380", labels: ["formulationIngredient"], properties: {name :"cyclotrimethylene trinitramine"} },
        { type: "node", id: "381", labels: ["formulationIngredient"], properties: {name :"trinitrotoluene"} },
        { type: "node", id: "382", labels: ["document_object"], properties: {name :"doc_10"} },
        { type: "node", id: "383", labels: ["document_object"], properties: {name :"doc_11"} },
        { type: "node", id: "384", labels: ["energeticFormulation"], properties: {name :"rx-03-gl-2"} },
        { type: "node", id: "385", labels: ["formulationIngredient"], properties: {name :"il-tatb"} },
        { type: "node", id: "386", labels: ["formulationIngredient"], properties: {name :"cytop a"} },
        { type: "node", id: "387", labels: ["document_object"], properties: {name :"doc_12"} },
        { type: "node", id: "388", labels: ["energeticFormulation"], properties: {name :"pbx 9404"} },
        { type: "node", id: "389", labels: ["percent"], properties: {name :"3%"} },
        { type: "node", id: "390", labels: ["formulationIngredient"], properties: {name :"nitrocellulose"} },
        { type: "node", id: "391", labels: ["formulationIngredient"], properties: {name :"chloroethylphosphate[cef]"} },
        { type: "node", id: "392", labels: ["document_object"], properties: {name :"doc_13"} },
        { type: "node", id: "393", labels: ["percent"], properties: {name :"63%"} },
        { type: "node", id: "394", labels: ["percent"], properties: {name :"36%"} },
        { type: "node", id: "395", labels: ["document_object"], properties: {name :"doc_14"} },
        { type: "node", id: "396", labels: ["energeticFormulation"], properties: {name :"rx-03-go"} },
        { type: "node", id: "397", labels: ["formulationIngredient"], properties: {name :"cytop"} },
        { type: "node", id: "398", labels: ["document_object"], properties: {name :"doc_15"} },
        { type: "node", id: "399", labels: ["formulationIngredient"], properties: {name :"triaminotrinitrobenzene-tatb"} },
        { type: "node", id: "400", labels: ["formulationIngredient"], properties: {name :"kelf 800"} },
        { type: "node", id: "401", labels: ["document_object"], properties: {name :"doc_16"} },
        { type: "node", id: "402", labels: ["energeticFormulation"], properties: {name :"lx-17"} },
        { type: "node", id: "403", labels: ["formulationIngredient"], properties: {name :"triaminotrinitrobenzene"} },
        { type: "node", id: "404", labels: ["percent"], properties: {name :"7.5%"} },
        { type: "node", id: "405", labels: ["formulationIngredient"], properties: {name :"binder"} },
        { type: "node", id: "406", labels: ["document_object"], properties: {name :"doc_17"} },
        { type: "node", id: "407", labels: ["energeticFormulation"], properties: {name :"lx-17-1"} },
        { type: "node", id: "408", labels: ["percent"], properties: {name :"92.5%"} },
        { type: "node", id: "409", labels: ["formulationIngredient"], properties: {name :"wet-aminated tatb"} },
        { type: "node", id: "410", labels: ["document_object"], properties: {name :"doc_18"} },
        { type: "node", id: "411", labels: ["document_object"], properties: {name :"doc_19"} },
        { type: "node", id: "412", labels: ["document_object"], properties: {name :"doc_20"} },
        { type: "node", id: "413", labels: ["energeticFormulation"], properties: {name :"pbx-9011"} },
        { type: "node", id: "414", labels: ["energeticFormulation"], properties: {name :"hmx/estane 90/10"} },
        { type: "node", id: "415", labels: ["document_object"], properties: {name :"doc_21"} },
        { type: "node", id: "416", labels: ["percent"], properties: {name :"95 wt%"} },
        { type: "node", id: "417", labels: ["percent"], properties: {name :"5 wt%"} },
        { type: "node", id: "418", labels: ["document_object"], properties: {name :"doc_22"} },
        { type: "node", id: "419", labels: ["percent"], properties: {name :"95 %"} },
        { type: "node", id: "420", labels: ["formulationIngredient"], properties: {name :"kelf800"} },
        { type: "node", id: "421", labels: ["document_object"], properties: {name :"doc_23"} },
        { type: "node", id: "422", labels: ["formulationIngredient"], properties: {name :"oxy 461"} },
        { type: "node", id: "423", labels: ["document_object"], properties: {name :"doc_24"} },
        { type: "node", id: "424", labels: ["percent"], properties: {name :"4%"} },
        { type: "node", id: "425", labels: ["formulationIngredient"], properties: {name :"oxy461"} },
        { type: "node", id: "426", labels: ["document_object"], properties: {name :"doc_25"} },
        { type: "node", id: "427", labels: ["formulationIngredient"], properties: {name :"fk800"} },
        { type: "node", id: "428", labels: ["document_object"], properties: {name :"doc_26"} },
        { type: "node", id: "429", labels: ["document_object"], properties: {name :"doc_27"} },
        { type: "node", id: "430", labels: ["document_object"], properties: {name :"doc_28"} },
        { type: "node", id: "431", labels: ["formulationIngredient"], properties: {name :"kelf"} },
        { type: "node", id: "432", labels: ["document_object"], properties: {name :"doc_29"} },
        { type: "node", id: "433", labels: ["formulationIngredient"], properties: {name :"nc"} },
        { type: "node", id: "434", labels: ["formulationIngredient"], properties: {name :"cef"} },
        { type: "node", id: "435", labels: ["document_object"], properties: {name :"doc_30"} },
        { type: "node", id: "436", labels: ["document_object"], properties: {name :"doc_31"} },
        { type: "node", id: "437", labels: ["formulationIngredient"], properties: {name :"bdnpa/f"} },
        { type: "node", id: "438", labels: ["formulationIngredient"], properties: {name :"estane"} },
        { type: "node", id: "439", labels: ["document_object"], properties: {name :"doc_32"} },
        { type: "node", id: "440", labels: ["percent"], properties: {name :"7, 5%"} },
        { type: "node", id: "441", labels: ["document_object"], properties: {name :"doc_33"} },
        { type: "node", id: "442", labels: ["document_object"], properties: {name :"doc_34"} },
        { type: "node", id: "443", labels: ["document_object"], properties: {name :"doc_35"} },
        { type: "node", id: "444", labels: ["formulationIngredient"], properties: {name :"1, 3, 5-triamino-2, 4, 6trinitrobenzene"} },
        { type: "node", id: "445", labels: ["document_object"], properties: {name :"doc_36"} },
        { type: "node", id: "446", labels: ["document_object"], properties: {name :"doc_37"} },
        { type: "node", id: "447", labels: ["energeticFormulation"], properties: {name :"composition a-3"} },
        { type: "node", id: "448", labels: ["document_object"], properties: {name :"doc_38"} },
        { type: "node", id: "449", labels: ["energeticFormulation"], properties: {name :"pbxn-9"} },
        { type: "node", id: "450", labels: ["percent"], properties: {name :"92.8%"} },
        { type: "node", id: "451", labels: ["document_object"], properties: {name :"doc_39"} },
        { type: "node", id: "452", labels: ["formulationIngredient"], properties: {name :"emulsified polyethylene wax"} },
        { type: "node", id: "453", labels: ["document_object"], properties: {name :"doc_40"} },
        { type: "node", id: "454", labels: ["energeticFormulation"], properties: {name :"pbx9502"} },
        { type: "node", id: "455", labels: ["document_object"], properties: {name :"doc_41"} },
        { type: "node", id: "456", labels: ["energeticFormulation"], properties: {name :"pbx-9501"} },
        { type: "node", id: "457", labels: ["document_object"], properties: {name :"doc_42"} },
        { type: "node", id: "458", labels: ["energeticFormulation"], properties: {name :"xtx-8003"} },
        { type: "node", id: "459", labels: ["formulationIngredient"], properties: {name :"silicone rubber"} },
        { type: "node", id: "460", labels: ["percent"], properties: {name :"80/20"} },
        { type: "node", id: "461", labels: ["document_object"], properties: {name :"doc_43"} },
        { type: "node", id: "462", labels: ["document_object"], properties: {name :"doc_44"} },
        { type: "node", id: "463", labels: ["energeticFormulation"], properties: {name :"seismoplast"} },
        { type: "node", id: "464", labels: ["quantity"], properties: {name :"1.54 g/cm"} },
        { type: "node", id: "465", labels: ["quality"], properties: {name :"formable as knead"} },
        { type: "node", id: "466", labels: ["percent"], properties: {name :"86 %"} },
        { type: "node", id: "467", labels: ["document_object"], properties: {name :"doc_45"} },
        { type: "node", id: "468", labels: ["document_object"], properties: {name :"doc_46"} },
        { type: "node", id: "469", labels: ["energeticFormulation"], properties: {name :"comp b-3"} },
        { type: "node", id: "470", labels: ["percent"], properties: {name :"6.53%"} },
        { type: "node", id: "471", labels: ["document_object"], properties: {name :"doc_47"} },
        { type: "node", id: "472", labels: ["energeticFormulation"], properties: {name :"xtx-8004"} },
        { type: "node", id: "473", labels: ["formulationIngredient"], properties: {name :"silicone binder"} },
        { type: "node", id: "474", labels: ["formulationIngredient"], properties: {name :"sylgard 182"} },
        { type: "node", id: "475", labels: ["document_object"], properties: {name :"doc_48"} },
        { type: "node", id: "476", labels: ["percent"], properties: {name :"80%"} },
        { type: "node", id: "477", labels: ["percent"], properties: {name :"20%"} },
        { type: "node", id: "478", labels: ["formulationIngredient"], properties: {name :"silicone resin"} },
        { type: "node", id: "479", labels: ["formulationIngredient"], properties: {name :"sylgard 182 elastomer"} },
        { type: "node", id: "480", labels: ["document_object"], properties: {name :"doc_49"} },
        { type: "node", id: "481", labels: ["energeticFormulation"], properties: {name :"rdx/tnt 60/40"} },
        { type: "node", id: "482", labels: ["document_object"], properties: {name :"doc_50"} },
        { type: "node", id: "483", labels: ["energeticFormulation"], properties: {name :"petn/tnt 50/50"} },
        { type: "node", id: "484", labels: ["document_object"], properties: {name :"doc_51"} },
        { type: "node", id: "485", labels: ["document_object"], properties: {name :"doc_52"} },
        { type: "node", id: "486", labels: ["formulationIngredient"], properties: {name :"lacquer-grade nitrocellulose"} },
        { type: "node", id: "487", labels: ["energeticFormulation"], properties: {name :"pbx-9404"} },
        { type: "node", id: "488", labels: ["document_object"], properties: {name :"doc_53"} },
        { type: "node", id: "489", labels: ["energeticFormulation"], properties: {name :"hexolite"} },
        { type: "node", id: "490", labels: ["energeticFormulation"], properties: {name :"hexotol"} },
        { type: "node", id: "491", labels: ["formulationIngredient"], properties: {name :"hexogen"} },
        { type: "node", id: "492", labels: ["percent"], properties: {name :"60:40"} },
        { type: "node", id: "493", labels: ["document_object"], properties: {name :"doc_54"} },
        { type: "node", id: "494", labels: ["energeticFormulation"], properties: {name :"nanoenergetics-based comp b"} },
        { type: "node", id: "495", labels: ["energeticFormulation"], properties: {name :"n-comp b"} },
        { type: "node", id: "496", labels: ["document_object"], properties: {name :"doc_55"} },
        { type: "node", id: "497", labels: ["document_object"], properties: {name :"doc_56"} },
        { type: "node", id: "498", labels: ["quality"], properties: {name :"holtston lot hol85g461-004"} },
        { type: "node", id: "499", labels: ["percent"], properties: {name :"60 weight %"} },
        { type: "node", id: "500", labels: ["percent"], properties: {name :"40 weight %"} },
        { type: "node", id: "501", labels: ["document_object"], properties: {name :"doc_57"} },
        { type: "node", id: "502", labels: ["percent"], properties: {name :"95/5 weight%"} },
        { type: "node", id: "503", labels: ["formulationIngredient"], properties: {name :"nitro-plasticized estane binder"} },
        { type: "node", id: "504", labels: ["document_object"], properties: {name :"doc_58"} },
        { type: "node", id: "505", labels: ["energeticFormulation"], properties: {name :"lx-10"} },
        { type: "node", id: "506", labels: ["formulationIngredient"], properties: {name :"viton"} },
        { type: "node", id: "507", labels: ["document_object"], properties: {name :"doc_59"} },
        { type: "node", id: "508", labels: ["formulationIngredient"], properties: {name :"chloroethyl phosphate"} },
        { type: "node", id: "509", labels: ["document_object"], properties: {name :"doc_60"} },
        { type: "node", id: "510", labels: ["energeticFormulation"], properties: {name :"pbxn-5"} },
        { type: "node", id: "511", labels: ["property"], properties: {name :"lot number"} },
        { type: "node", id: "512", labels: ["quality"], properties: {name :"bae12d045-068"} },
        { type: "node", id: "513", labels: ["document_object"], properties: {name :"doc_61"} },
        { type: "node", id: "514", labels: ["quality"], properties: {name :"class 5"} },
        { type: "node", id: "515", labels: ["formulationIngredient"], properties: {name :"viton a"} },
        { type: "node", id: "516", labels: ["document_object"], properties: {name :"doc_62"} },
        { type: "node", id: "517", labels: ["percent"], properties: {name :"94/3/3"} },
        { type: "node", id: "518", labels: ["energeticFormulation"], properties: {name :"composition-b"} },
        { type: "node", id: "519", labels: ["percent"], properties: {name :"63/36/1"} },
        { type: "node", id: "520", labels: ["formulationIngredient"], properties: {name :"ultrafine tatb"} },
        { type: "node", id: "521", labels: ["CEM"], properties: {name :"micronized tatb"} },
        { type: "node", id: "522", labels: ["percent"], properties: {name :"92.5/7.5"} },
        { type: "node", id: "523", labels: ["formulationIngredient"], properties: {name :"fk-800"} },
        { type: "node", id: "524", labels: ["document_object"], properties: {name :"doc_63"} },
        { type: "node", id: "525", labels: ["energeticFormulation"], properties: {name :"pbx-9407"} },
        { type: "node", id: "526", labels: ["formulationIngredient"], properties: {name :"vctfe"} },
        { type: "node", id: "527", labels: ["document_object"], properties: {name :"doc_64"} },
        { type: "node", id: "528", labels: ["document_object"], properties: {name :"doc_65"} },
        { type: "node", id: "529", labels: ["document_object"], properties: {name :"doc_66"} },
        { type: "node", id: "530", labels: ["document_object"], properties: {name :"doc_67"} },
        { type: "node", id: "531", labels: ["formulationIngredient"], properties: {name :"1, 2, 3-triamino-2, 4, 6-trinitrobenzene"} },
        { type: "node", id: "532", labels: ["document_object"], properties: {name :"doc_68"} },
        { type: "node", id: "533", labels: ["document_object"], properties: {name :"doc_69"} },
        { type: "node", id: "534", labels: ["energeticFormulation"], properties: {name :"pbxn-109"} },
        { type: "node", id: "535", labels: ["percent"], properties: {name :"64%"} },
        { type: "node", id: "536", labels: ["formulationIngredient"], properties: {name :"aluminium"} },
        { type: "node", id: "537", labels: ["percent"], properties: {name :"16%"} },
        { type: "node", id: "538", labels: ["document_object"], properties: {name :"doc_70"} },
        { type: "node", id: "539", labels: ["document_object"], properties: {name :"doc_71"} },
        { type: "node", id: "540", labels: ["energeticFormulation"], properties: {name :"pbx- 9502"} },
        { type: "node", id: "541", labels: ["document_object"], properties: {name :"doc_72"} },
        { type: "node", id: "542", labels: ["document_object"], properties: {name :"doc_73"} },
        { type: "node", id: "543", labels: ["percent"], properties: {name :"about 7.5%"} },
        { type: "node", id: "544", labels: ["document_object"], properties: {name :"doc_74"} },
        { type: "node", id: "545", labels: ["energeticFormulation"], properties: {name :"lx17"} },
        { type: "node", id: "546", labels: ["document_object"], properties: {name :"doc_75"} },
        { type: "node", id: "547", labels: ["document_object"], properties: {name :"doc_76"} },
        { type: "node", id: "548", labels: ["document_object"], properties: {name :"doc_77"} },
        { type: "node", id: "549", labels: ["document_object"], properties: {name :"doc_78"} },
        { type: "node", id: "550", labels: ["formulationIngredient"], properties: {name :"polychlorotrifluoroethylene"} },
        { type: "node", id: "551", labels: ["document_object"], properties: {name :"doc_79"} },
        { type: "node", id: "552", labels: ["percent"], properties: {name :"approximately 95 wt%"} },
        { type: "node", id: "553", labels: ["percent"], properties: {name :"nearly 5 wt%"} },
        { type: "node", id: "554", labels: ["formulationIngredient"], properties: {name :"binder and nitro plasticizer"} },
        { type: "node", id: "555", labels: ["percent"], properties: {name :"approximately 0.1 wt%"} },
        { type: "node", id: "556", labels: ["formulationIngredient"], properties: {name :"stabilizers"} },
        { type: "node", id: "557", labels: ["document_object"], properties: {name :"doc_80"} },
        { type: "node", id: "558", labels: ["formulationIngredient"], properties: {name :"polymeric binder"} },
        { type: "node", id: "559", labels: ["formulationIngredient"], properties: {name :"cyclotetramethylene tetranitramine"} },
    ],
    links: [
        { id: "0", type: "relationship", label: "MENTIONED_IN", start: { id: "326", labels: ["document_object"]}, end: { id: "327", labels: ["energeticFormulation"]} },
        { id: "1", type: "relationship", label: "MENTIONED_IN", start: { id: "326", labels: ["document_object"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "2", type: "relationship", label: "MENTIONED_IN", start: { id: "326", labels: ["document_object"]}, end: { id: "329", labels: ["formulationIngredient"]} },
        { id: "3", type: "relationship", label: "MENTIONED_IN", start: { id: "326", labels: ["document_object"]}, end: { id: "330", labels: ["formulationIngredient"]} },
        { id: "4", type: "relationship", label: "MENTIONED_IN", start: { id: "326", labels: ["document_object"]}, end: { id: "331", labels: ["formulationIngredient"]} },
        { id: "5", type: "relationship", label: "MENTIONED_IN", start: { id: "332", labels: ["document_object"]}, end: { id: "333", labels: ["energeticFormulation"]} },
        { id: "6", type: "relationship", label: "MENTIONED_IN", start: { id: "332", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "7", type: "relationship", label: "MENTIONED_IN", start: { id: "332", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "8", type: "relationship", label: "MENTIONED_IN", start: { id: "332", labels: ["document_object"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "9", type: "relationship", label: "MENTIONED_IN", start: { id: "341", labels: ["document_object"]}, end: { id: "342", labels: ["energeticFormulation"]} },
        { id: "10", type: "relationship", label: "MENTIONED_IN", start: { id: "341", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "11", type: "relationship", label: "MENTIONED_IN", start: { id: "341", labels: ["document_object"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "12", type: "relationship", label: "MENTIONED_IN", start: { id: "341", labels: ["document_object"]}, end: { id: "345", labels: ["formulationIngredient"]} },
        { id: "13", type: "relationship", label: "MENTIONED_IN", start: { id: "347", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "14", type: "relationship", label: "MENTIONED_IN", start: { id: "347", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "15", type: "relationship", label: "MENTIONED_IN", start: { id: "347", labels: ["document_object"]}, end: { id: "352", labels: ["formulationIngredient"]} },
        { id: "16", type: "relationship", label: "MENTIONED_IN", start: { id: "347", labels: ["document_object"]}, end: { id: "353", labels: ["formulationIngredient"]} },
        { id: "17", type: "relationship", label: "MENTIONED_IN", start: { id: "354", labels: ["document_object"]}, end: { id: "355", labels: ["energeticFormulation"]} },
        { id: "18", type: "relationship", label: "MENTIONED_IN", start: { id: "354", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "19", type: "relationship", label: "MENTIONED_IN", start: { id: "354", labels: ["document_object"]}, end: { id: "358", labels: ["formulationIngredient"]} },
        { id: "20", type: "relationship", label: "MENTIONED_IN", start: { id: "359", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "21", type: "relationship", label: "MENTIONED_IN", start: { id: "359", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "22", type: "relationship", label: "MENTIONED_IN", start: { id: "359", labels: ["document_object"]}, end: { id: "361", labels: ["formulationIngredient"]} },
        { id: "23", type: "relationship", label: "MENTIONED_IN", start: { id: "359", labels: ["document_object"]}, end: { id: "362", labels: ["formulationIngredient"]} },
        { id: "24", type: "relationship", label: "MENTIONED_IN", start: { id: "359", labels: ["document_object"]}, end: { id: "363", labels: ["formulationIngredient"]} },
        { id: "25", type: "relationship", label: "MENTIONED_IN", start: { id: "364", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "26", type: "relationship", label: "MENTIONED_IN", start: { id: "364", labels: ["document_object"]}, end: { id: "367", labels: ["formulationIngredient"]} },
        { id: "27", type: "relationship", label: "MENTIONED_IN", start: { id: "364", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "28", type: "relationship", label: "MENTIONED_IN", start: { id: "364", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "29", type: "relationship", label: "MENTIONED_IN", start: { id: "370", labels: ["document_object"]}, end: { id: "371", labels: ["energeticFormulation"]} },
        { id: "30", type: "relationship", label: "MENTIONED_IN", start: { id: "370", labels: ["document_object"]}, end: { id: "373", labels: ["formulationIngredient"]} },
        { id: "31", type: "relationship", label: "MENTIONED_IN", start: { id: "370", labels: ["document_object"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "32", type: "relationship", label: "MENTIONED_IN", start: { id: "374", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "33", type: "relationship", label: "MENTIONED_IN", start: { id: "374", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "34", type: "relationship", label: "MENTIONED_IN", start: { id: "374", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "35", type: "relationship", label: "MENTIONED_IN", start: { id: "377", labels: ["document_object"]}, end: { id: "378", labels: ["energeticFormulation"]} },
        { id: "36", type: "relationship", label: "MENTIONED_IN", start: { id: "377", labels: ["document_object"]}, end: { id: "380", labels: ["formulationIngredient"]} },
        { id: "37", type: "relationship", label: "MENTIONED_IN", start: { id: "377", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "38", type: "relationship", label: "MENTIONED_IN", start: { id: "377", labels: ["document_object"]}, end: { id: "381", labels: ["formulationIngredient"]} },
        { id: "39", type: "relationship", label: "MENTIONED_IN", start: { id: "377", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "40", type: "relationship", label: "MENTIONED_IN", start: { id: "382", labels: ["document_object"]}, end: { id: "378", labels: ["energeticFormulation"]} },
        { id: "41", type: "relationship", label: "MENTIONED_IN", start: { id: "382", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "42", type: "relationship", label: "MENTIONED_IN", start: { id: "382", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "43", type: "relationship", label: "MENTIONED_IN", start: { id: "383", labels: ["document_object"]}, end: { id: "384", labels: ["energeticFormulation"]} },
        { id: "44", type: "relationship", label: "MENTIONED_IN", start: { id: "383", labels: ["document_object"]}, end: { id: "385", labels: ["formulationIngredient"]} },
        { id: "45", type: "relationship", label: "MENTIONED_IN", start: { id: "383", labels: ["document_object"]}, end: { id: "386", labels: ["formulationIngredient"]} },
        { id: "46", type: "relationship", label: "MENTIONED_IN", start: { id: "387", labels: ["document_object"]}, end: { id: "388", labels: ["energeticFormulation"]} },
        { id: "47", type: "relationship", label: "MENTIONED_IN", start: { id: "387", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "48", type: "relationship", label: "MENTIONED_IN", start: { id: "387", labels: ["document_object"]}, end: { id: "390", labels: ["formulationIngredient"]} },
        { id: "49", type: "relationship", label: "MENTIONED_IN", start: { id: "387", labels: ["document_object"]}, end: { id: "391", labels: ["formulationIngredient"]} },
        { id: "50", type: "relationship", label: "MENTIONED_IN", start: { id: "392", labels: ["document_object"]}, end: { id: "378", labels: ["energeticFormulation"]} },
        { id: "51", type: "relationship", label: "MENTIONED_IN", start: { id: "392", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "52", type: "relationship", label: "MENTIONED_IN", start: { id: "392", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "53", type: "relationship", label: "MENTIONED_IN", start: { id: "392", labels: ["document_object"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "54", type: "relationship", label: "MENTIONED_IN", start: { id: "395", labels: ["document_object"]}, end: { id: "396", labels: ["energeticFormulation"]} },
        { id: "55", type: "relationship", label: "MENTIONED_IN", start: { id: "395", labels: ["document_object"]}, end: { id: "385", labels: ["formulationIngredient"]} },
        { id: "56", type: "relationship", label: "MENTIONED_IN", start: { id: "395", labels: ["document_object"]}, end: { id: "397", labels: ["formulationIngredient"]} },
        { id: "57", type: "relationship", label: "MENTIONED_IN", start: { id: "398", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "58", type: "relationship", label: "MENTIONED_IN", start: { id: "398", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "59", type: "relationship", label: "MENTIONED_IN", start: { id: "398", labels: ["document_object"]}, end: { id: "399", labels: ["formulationIngredient"]} },
        { id: "60", type: "relationship", label: "MENTIONED_IN", start: { id: "398", labels: ["document_object"]}, end: { id: "400", labels: ["formulationIngredient"]} },
        { id: "61", type: "relationship", label: "MENTIONED_IN", start: { id: "401", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "62", type: "relationship", label: "MENTIONED_IN", start: { id: "401", labels: ["document_object"]}, end: { id: "403", labels: ["formulationIngredient"]} },
        { id: "63", type: "relationship", label: "MENTIONED_IN", start: { id: "401", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "64", type: "relationship", label: "MENTIONED_IN", start: { id: "401", labels: ["document_object"]}, end: { id: "405", labels: ["formulationIngredient"]} },
        { id: "65", type: "relationship", label: "MENTIONED_IN", start: { id: "406", labels: ["document_object"]}, end: { id: "407", labels: ["energeticFormulation"]} },
        { id: "66", type: "relationship", label: "MENTIONED_IN", start: { id: "406", labels: ["document_object"]}, end: { id: "409", labels: ["formulationIngredient"]} },
        { id: "67", type: "relationship", label: "MENTIONED_IN", start: { id: "406", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "68", type: "relationship", label: "MENTIONED_IN", start: { id: "410", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "69", type: "relationship", label: "MENTIONED_IN", start: { id: "410", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "70", type: "relationship", label: "MENTIONED_IN", start: { id: "410", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "71", type: "relationship", label: "MENTIONED_IN", start: { id: "411", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "72", type: "relationship", label: "MENTIONED_IN", start: { id: "411", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "73", type: "relationship", label: "MENTIONED_IN", start: { id: "411", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "74", type: "relationship", label: "MENTIONED_IN", start: { id: "412", labels: ["document_object"]}, end: { id: "413", labels: ["energeticFormulation"]} },
        { id: "75", type: "relationship", label: "MENTIONED_IN", start: { id: "412", labels: ["document_object"]}, end: { id: "414", labels: ["energeticFormulation"]} },
        { id: "76", type: "relationship", label: "MENTIONED_IN", start: { id: "415", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "77", type: "relationship", label: "MENTIONED_IN", start: { id: "415", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "78", type: "relationship", label: "MENTIONED_IN", start: { id: "415", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "79", type: "relationship", label: "MENTIONED_IN", start: { id: "418", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "80", type: "relationship", label: "MENTIONED_IN", start: { id: "418", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "81", type: "relationship", label: "MENTIONED_IN", start: { id: "418", labels: ["document_object"]}, end: { id: "420", labels: ["formulationIngredient"]} },
        { id: "82", type: "relationship", label: "MENTIONED_IN", start: { id: "421", labels: ["document_object"]}, end: { id: "355", labels: ["energeticFormulation"]} },
        { id: "83", type: "relationship", label: "MENTIONED_IN", start: { id: "421", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "84", type: "relationship", label: "MENTIONED_IN", start: { id: "421", labels: ["document_object"]}, end: { id: "422", labels: ["formulationIngredient"]} },
        { id: "85", type: "relationship", label: "MENTIONED_IN", start: { id: "423", labels: ["document_object"]}, end: { id: "355", labels: ["energeticFormulation"]} },
        { id: "86", type: "relationship", label: "MENTIONED_IN", start: { id: "423", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "87", type: "relationship", label: "MENTIONED_IN", start: { id: "423", labels: ["document_object"]}, end: { id: "425", labels: ["formulationIngredient"]} },
        { id: "88", type: "relationship", label: "MENTIONED_IN", start: { id: "426", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "89", type: "relationship", label: "MENTIONED_IN", start: { id: "426", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "90", type: "relationship", label: "MENTIONED_IN", start: { id: "426", labels: ["document_object"]}, end: { id: "427", labels: ["formulationIngredient"]} },
        { id: "91", type: "relationship", label: "MENTIONED_IN", start: { id: "428", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "92", type: "relationship", label: "MENTIONED_IN", start: { id: "428", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "93", type: "relationship", label: "MENTIONED_IN", start: { id: "429", labels: ["document_object"]}, end: { id: "378", labels: ["energeticFormulation"]} },
        { id: "94", type: "relationship", label: "MENTIONED_IN", start: { id: "429", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "95", type: "relationship", label: "MENTIONED_IN", start: { id: "429", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "96", type: "relationship", label: "MENTIONED_IN", start: { id: "429", labels: ["document_object"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "97", type: "relationship", label: "MENTIONED_IN", start: { id: "430", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "98", type: "relationship", label: "MENTIONED_IN", start: { id: "430", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "99", type: "relationship", label: "MENTIONED_IN", start: { id: "430", labels: ["document_object"]}, end: { id: "431", labels: ["formulationIngredient"]} },
        { id: "100", type: "relationship", label: "MENTIONED_IN", start: { id: "432", labels: ["document_object"]}, end: { id: "388", labels: ["energeticFormulation"]} },
        { id: "101", type: "relationship", label: "MENTIONED_IN", start: { id: "432", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "102", type: "relationship", label: "MENTIONED_IN", start: { id: "432", labels: ["document_object"]}, end: { id: "433", labels: ["formulationIngredient"]} },
        { id: "103", type: "relationship", label: "MENTIONED_IN", start: { id: "432", labels: ["document_object"]}, end: { id: "434", labels: ["formulationIngredient"]} },
        { id: "104", type: "relationship", label: "MENTIONED_IN", start: { id: "435", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "105", type: "relationship", label: "MENTIONED_IN", start: { id: "435", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "106", type: "relationship", label: "MENTIONED_IN", start: { id: "435", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "107", type: "relationship", label: "MENTIONED_IN", start: { id: "436", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "108", type: "relationship", label: "MENTIONED_IN", start: { id: "436", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "109", type: "relationship", label: "MENTIONED_IN", start: { id: "436", labels: ["document_object"]}, end: { id: "437", labels: ["formulationIngredient"]} },
        { id: "110", type: "relationship", label: "MENTIONED_IN", start: { id: "436", labels: ["document_object"]}, end: { id: "438", labels: ["formulationIngredient"]} },
        { id: "111", type: "relationship", label: "MENTIONED_IN", start: { id: "439", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "112", type: "relationship", label: "MENTIONED_IN", start: { id: "439", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "113", type: "relationship", label: "MENTIONED_IN", start: { id: "439", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "114", type: "relationship", label: "MENTIONED_IN", start: { id: "441", labels: ["document_object"]}, end: { id: "396", labels: ["energeticFormulation"]} },
        { id: "115", type: "relationship", label: "MENTIONED_IN", start: { id: "441", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "116", type: "relationship", label: "MENTIONED_IN", start: { id: "441", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "117", type: "relationship", label: "MENTIONED_IN", start: { id: "441", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "118", type: "relationship", label: "MENTIONED_IN", start: { id: "442", labels: ["document_object"]}, end: { id: "396", labels: ["energeticFormulation"]} },
        { id: "119", type: "relationship", label: "MENTIONED_IN", start: { id: "442", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "120", type: "relationship", label: "MENTIONED_IN", start: { id: "442", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "121", type: "relationship", label: "MENTIONED_IN", start: { id: "442", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "122", type: "relationship", label: "MENTIONED_IN", start: { id: "443", labels: ["document_object"]}, end: { id: "444", labels: ["formulationIngredient"]} },
        { id: "123", type: "relationship", label: "MENTIONED_IN", start: { id: "443", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "124", type: "relationship", label: "MENTIONED_IN", start: { id: "443", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "125", type: "relationship", label: "MENTIONED_IN", start: { id: "443", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "126", type: "relationship", label: "MENTIONED_IN", start: { id: "445", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "127", type: "relationship", label: "MENTIONED_IN", start: { id: "445", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "128", type: "relationship", label: "MENTIONED_IN", start: { id: "445", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "129", type: "relationship", label: "MENTIONED_IN", start: { id: "446", labels: ["document_object"]}, end: { id: "447", labels: ["energeticFormulation"]} },
        { id: "130", type: "relationship", label: "MENTIONED_IN", start: { id: "446", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "131", type: "relationship", label: "MENTIONED_IN", start: { id: "448", labels: ["document_object"]}, end: { id: "333", labels: ["energeticFormulation"]} },
        { id: "132", type: "relationship", label: "MENTIONED_IN", start: { id: "448", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "133", type: "relationship", label: "MENTIONED_IN", start: { id: "448", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "134", type: "relationship", label: "MENTIONED_IN", start: { id: "448", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "135", type: "relationship", label: "MENTIONED_IN", start: { id: "448", labels: ["document_object"]}, end: { id: "449", labels: ["energeticFormulation"]} },
        { id: "136", type: "relationship", label: "MENTIONED_IN", start: { id: "451", labels: ["document_object"]}, end: { id: "447", labels: ["energeticFormulation"]} },
        { id: "137", type: "relationship", label: "MENTIONED_IN", start: { id: "451", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "138", type: "relationship", label: "MENTIONED_IN", start: { id: "451", labels: ["document_object"]}, end: { id: "452", labels: ["formulationIngredient"]} },
        { id: "139", type: "relationship", label: "MENTIONED_IN", start: { id: "453", labels: ["document_object"]}, end: { id: "454", labels: ["energeticFormulation"]} },
        { id: "140", type: "relationship", label: "MENTIONED_IN", start: { id: "453", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "141", type: "relationship", label: "MENTIONED_IN", start: { id: "453", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "142", type: "relationship", label: "MENTIONED_IN", start: { id: "455", labels: ["document_object"]}, end: { id: "456", labels: ["energeticFormulation"]} },
        { id: "143", type: "relationship", label: "MENTIONED_IN", start: { id: "455", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "144", type: "relationship", label: "MENTIONED_IN", start: { id: "455", labels: ["document_object"]}, end: { id: "438", labels: ["formulationIngredient"]} },
        { id: "145", type: "relationship", label: "MENTIONED_IN", start: { id: "455", labels: ["document_object"]}, end: { id: "353", labels: ["formulationIngredient"]} },
        { id: "146", type: "relationship", label: "MENTIONED_IN", start: { id: "457", labels: ["document_object"]}, end: { id: "458", labels: ["energeticFormulation"]} },
        { id: "147", type: "relationship", label: "MENTIONED_IN", start: { id: "457", labels: ["document_object"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "148", type: "relationship", label: "MENTIONED_IN", start: { id: "457", labels: ["document_object"]}, end: { id: "459", labels: ["formulationIngredient"]} },
        { id: "149", type: "relationship", label: "MENTIONED_IN", start: { id: "461", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "150", type: "relationship", label: "MENTIONED_IN", start: { id: "461", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "151", type: "relationship", label: "MENTIONED_IN", start: { id: "461", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "152", type: "relationship", label: "MENTIONED_IN", start: { id: "462", labels: ["document_object"]}, end: { id: "463", labels: ["energeticFormulation"]} },
        { id: "153", type: "relationship", label: "MENTIONED_IN", start: { id: "462", labels: ["document_object"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "154", type: "relationship", label: "MENTIONED_IN", start: { id: "467", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "155", type: "relationship", label: "MENTIONED_IN", start: { id: "467", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "156", type: "relationship", label: "MENTIONED_IN", start: { id: "467", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "157", type: "relationship", label: "MENTIONED_IN", start: { id: "468", labels: ["document_object"]}, end: { id: "469", labels: ["energeticFormulation"]} },
        { id: "158", type: "relationship", label: "MENTIONED_IN", start: { id: "468", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "159", type: "relationship", label: "MENTIONED_IN", start: { id: "471", labels: ["document_object"]}, end: { id: "472", labels: ["energeticFormulation"]} },
        { id: "160", type: "relationship", label: "MENTIONED_IN", start: { id: "471", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "161", type: "relationship", label: "MENTIONED_IN", start: { id: "471", labels: ["document_object"]}, end: { id: "473", labels: ["formulationIngredient"]} },
        { id: "162", type: "relationship", label: "MENTIONED_IN", start: { id: "471", labels: ["document_object"]}, end: { id: "474", labels: ["formulationIngredient"]} },
        { id: "163", type: "relationship", label: "MENTIONED_IN", start: { id: "475", labels: ["document_object"]}, end: { id: "458", labels: ["energeticFormulation"]} },
        { id: "164", type: "relationship", label: "MENTIONED_IN", start: { id: "475", labels: ["document_object"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "165", type: "relationship", label: "MENTIONED_IN", start: { id: "475", labels: ["document_object"]}, end: { id: "478", labels: ["formulationIngredient"]} },
        { id: "166", type: "relationship", label: "MENTIONED_IN", start: { id: "475", labels: ["document_object"]}, end: { id: "479", labels: ["formulationIngredient"]} },
        { id: "167", type: "relationship", label: "MENTIONED_IN", start: { id: "480", labels: ["document_object"]}, end: { id: "469", labels: ["energeticFormulation"]} },
        { id: "168", type: "relationship", label: "MENTIONED_IN", start: { id: "480", labels: ["document_object"]}, end: { id: "481", labels: ["energeticFormulation"]} },
        { id: "169", type: "relationship", label: "MENTIONED_IN", start: { id: "482", labels: ["document_object"]}, end: { id: "371", labels: ["energeticFormulation"]} },
        { id: "170", type: "relationship", label: "MENTIONED_IN", start: { id: "482", labels: ["document_object"]}, end: { id: "483", labels: ["energeticFormulation"]} },
        { id: "171", type: "relationship", label: "MENTIONED_IN", start: { id: "484", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "172", type: "relationship", label: "MENTIONED_IN", start: { id: "484", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "173", type: "relationship", label: "MENTIONED_IN", start: { id: "484", labels: ["document_object"]}, end: { id: "405", labels: ["formulationIngredient"]} },
        { id: "174", type: "relationship", label: "MENTIONED_IN", start: { id: "485", labels: ["document_object"]}, end: { id: "486", labels: ["formulationIngredient"]} },
        { id: "175", type: "relationship", label: "MENTIONED_IN", start: { id: "485", labels: ["document_object"]}, end: { id: "487", labels: ["energeticFormulation"]} },
        { id: "176", type: "relationship", label: "MENTIONED_IN", start: { id: "488", labels: ["document_object"]}, end: { id: "489", labels: ["energeticFormulation"]} },
        { id: "177", type: "relationship", label: "MENTIONED_IN", start: { id: "488", labels: ["document_object"]}, end: { id: "490", labels: ["energeticFormulation"]} },
        { id: "178", type: "relationship", label: "MENTIONED_IN", start: { id: "488", labels: ["document_object"]}, end: { id: "491", labels: ["formulationIngredient"]} },
        { id: "179", type: "relationship", label: "MENTIONED_IN", start: { id: "488", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "180", type: "relationship", label: "MENTIONED_IN", start: { id: "488", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "181", type: "relationship", label: "MENTIONED_IN", start: { id: "493", labels: ["document_object"]}, end: { id: "494", labels: ["energeticFormulation"]} },
        { id: "182", type: "relationship", label: "MENTIONED_IN", start: { id: "493", labels: ["document_object"]}, end: { id: "495", labels: ["energeticFormulation"]} },
        { id: "183", type: "relationship", label: "MENTIONED_IN", start: { id: "493", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "184", type: "relationship", label: "MENTIONED_IN", start: { id: "493", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "185", type: "relationship", label: "MENTIONED_IN", start: { id: "496", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "186", type: "relationship", label: "MENTIONED_IN", start: { id: "496", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "187", type: "relationship", label: "MENTIONED_IN", start: { id: "497", labels: ["document_object"]}, end: { id: "469", labels: ["energeticFormulation"]} },
        { id: "188", type: "relationship", label: "MENTIONED_IN", start: { id: "497", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "189", type: "relationship", label: "MENTIONED_IN", start: { id: "497", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "190", type: "relationship", label: "MENTIONED_IN", start: { id: "501", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "191", type: "relationship", label: "MENTIONED_IN", start: { id: "501", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "192", type: "relationship", label: "MENTIONED_IN", start: { id: "501", labels: ["document_object"]}, end: { id: "503", labels: ["formulationIngredient"]} },
        { id: "193", type: "relationship", label: "MENTIONED_IN", start: { id: "504", labels: ["document_object"]}, end: { id: "505", labels: ["energeticFormulation"]} },
        { id: "194", type: "relationship", label: "MENTIONED_IN", start: { id: "504", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "195", type: "relationship", label: "MENTIONED_IN", start: { id: "504", labels: ["document_object"]}, end: { id: "506", labels: ["formulationIngredient"]} },
        { id: "196", type: "relationship", label: "MENTIONED_IN", start: { id: "507", labels: ["document_object"]}, end: { id: "487", labels: ["energeticFormulation"]} },
        { id: "197", type: "relationship", label: "MENTIONED_IN", start: { id: "507", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "198", type: "relationship", label: "MENTIONED_IN", start: { id: "507", labels: ["document_object"]}, end: { id: "390", labels: ["formulationIngredient"]} },
        { id: "199", type: "relationship", label: "MENTIONED_IN", start: { id: "507", labels: ["document_object"]}, end: { id: "508", labels: ["formulationIngredient"]} },
        { id: "200", type: "relationship", label: "MENTIONED_IN", start: { id: "509", labels: ["document_object"]}, end: { id: "510", labels: ["energeticFormulation"]} },
        { id: "201", type: "relationship", label: "MENTIONED_IN", start: { id: "509", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "202", type: "relationship", label: "MENTIONED_IN", start: { id: "509", labels: ["document_object"]}, end: { id: "506", labels: ["formulationIngredient"]} },
        { id: "203", type: "relationship", label: "MENTIONED_IN", start: { id: "513", labels: ["document_object"]}, end: { id: "510", labels: ["energeticFormulation"]} },
        { id: "204", type: "relationship", label: "MENTIONED_IN", start: { id: "513", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "205", type: "relationship", label: "MENTIONED_IN", start: { id: "513", labels: ["document_object"]}, end: { id: "515", labels: ["formulationIngredient"]} },
        { id: "206", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "388", labels: ["energeticFormulation"]} },
        { id: "207", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "208", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "434", labels: ["formulationIngredient"]} },
        { id: "209", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "390", labels: ["formulationIngredient"]} },
        { id: "210", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "518", labels: ["energeticFormulation"]} },
        { id: "211", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "212", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "213", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "214", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "520", labels: ["formulationIngredient"]} },
        { id: "215", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "521", labels: ["CEM"]} },
        { id: "216", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "217", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "218", type: "relationship", label: "MENTIONED_IN", start: { id: "516", labels: ["document_object"]}, end: { id: "523", labels: ["formulationIngredient"]} },
        { id: "219", type: "relationship", label: "MENTIONED_IN", start: { id: "524", labels: ["document_object"]}, end: { id: "525", labels: ["energeticFormulation"]} },
        { id: "220", type: "relationship", label: "MENTIONED_IN", start: { id: "524", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "221", type: "relationship", label: "MENTIONED_IN", start: { id: "524", labels: ["document_object"]}, end: { id: "526", labels: ["formulationIngredient"]} },
        { id: "222", type: "relationship", label: "MENTIONED_IN", start: { id: "527", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "223", type: "relationship", label: "MENTIONED_IN", start: { id: "527", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "224", type: "relationship", label: "MENTIONED_IN", start: { id: "527", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "225", type: "relationship", label: "MENTIONED_IN", start: { id: "528", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "226", type: "relationship", label: "MENTIONED_IN", start: { id: "528", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "227", type: "relationship", label: "MENTIONED_IN", start: { id: "528", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "228", type: "relationship", label: "MENTIONED_IN", start: { id: "529", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "229", type: "relationship", label: "MENTIONED_IN", start: { id: "529", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "230", type: "relationship", label: "MENTIONED_IN", start: { id: "530", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "231", type: "relationship", label: "MENTIONED_IN", start: { id: "530", labels: ["document_object"]}, end: { id: "531", labels: ["formulationIngredient"]} },
        { id: "232", type: "relationship", label: "MENTIONED_IN", start: { id: "530", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "233", type: "relationship", label: "MENTIONED_IN", start: { id: "530", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "234", type: "relationship", label: "MENTIONED_IN", start: { id: "532", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "235", type: "relationship", label: "MENTIONED_IN", start: { id: "532", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "236", type: "relationship", label: "MENTIONED_IN", start: { id: "532", labels: ["document_object"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "237", type: "relationship", label: "MENTIONED_IN", start: { id: "533", labels: ["document_object"]}, end: { id: "534", labels: ["energeticFormulation"]} },
        { id: "238", type: "relationship", label: "MENTIONED_IN", start: { id: "533", labels: ["document_object"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "239", type: "relationship", label: "MENTIONED_IN", start: { id: "533", labels: ["document_object"]}, end: { id: "536", labels: ["formulationIngredient"]} },
        { id: "240", type: "relationship", label: "MENTIONED_IN", start: { id: "533", labels: ["document_object"]}, end: { id: "405", labels: ["formulationIngredient"]} },
        { id: "241", type: "relationship", label: "MENTIONED_IN", start: { id: "538", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "242", type: "relationship", label: "MENTIONED_IN", start: { id: "538", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "243", type: "relationship", label: "MENTIONED_IN", start: { id: "538", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "244", type: "relationship", label: "MENTIONED_IN", start: { id: "539", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "245", type: "relationship", label: "MENTIONED_IN", start: { id: "539", labels: ["document_object"]}, end: { id: "540", labels: ["energeticFormulation"]} },
        { id: "246", type: "relationship", label: "MENTIONED_IN", start: { id: "539", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "247", type: "relationship", label: "MENTIONED_IN", start: { id: "541", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "248", type: "relationship", label: "MENTIONED_IN", start: { id: "541", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "249", type: "relationship", label: "MENTIONED_IN", start: { id: "541", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "250", type: "relationship", label: "MENTIONED_IN", start: { id: "542", labels: ["document_object"]}, end: { id: "402", labels: ["energeticFormulation"]} },
        { id: "251", type: "relationship", label: "MENTIONED_IN", start: { id: "542", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "252", type: "relationship", label: "MENTIONED_IN", start: { id: "544", labels: ["document_object"]}, end: { id: "545", labels: ["energeticFormulation"]} },
        { id: "253", type: "relationship", label: "MENTIONED_IN", start: { id: "544", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "254", type: "relationship", label: "MENTIONED_IN", start: { id: "544", labels: ["document_object"]}, end: { id: "431", labels: ["formulationIngredient"]} },
        { id: "255", type: "relationship", label: "MENTIONED_IN", start: { id: "546", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "256", type: "relationship", label: "MENTIONED_IN", start: { id: "546", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "257", type: "relationship", label: "MENTIONED_IN", start: { id: "546", labels: ["document_object"]}, end: { id: "438", labels: ["formulationIngredient"]} },
        { id: "258", type: "relationship", label: "MENTIONED_IN", start: { id: "546", labels: ["document_object"]}, end: { id: "437", labels: ["formulationIngredient"]} },
        { id: "259", type: "relationship", label: "MENTIONED_IN", start: { id: "547", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "260", type: "relationship", label: "MENTIONED_IN", start: { id: "547", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "261", type: "relationship", label: "MENTIONED_IN", start: { id: "547", labels: ["document_object"]}, end: { id: "431", labels: ["formulationIngredient"]} },
        { id: "262", type: "relationship", label: "MENTIONED_IN", start: { id: "548", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "263", type: "relationship", label: "MENTIONED_IN", start: { id: "548", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "264", type: "relationship", label: "MENTIONED_IN", start: { id: "548", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "265", type: "relationship", label: "MENTIONED_IN", start: { id: "549", labels: ["document_object"]}, end: { id: "365", labels: ["energeticFormulation"]} },
        { id: "266", type: "relationship", label: "MENTIONED_IN", start: { id: "549", labels: ["document_object"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "267", type: "relationship", label: "MENTIONED_IN", start: { id: "549", labels: ["document_object"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "268", type: "relationship", label: "MENTIONED_IN", start: { id: "549", labels: ["document_object"]}, end: { id: "550", labels: ["formulationIngredient"]} },
        { id: "269", type: "relationship", label: "MENTIONED_IN", start: { id: "551", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "270", type: "relationship", label: "MENTIONED_IN", start: { id: "551", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "271", type: "relationship", label: "MENTIONED_IN", start: { id: "551", labels: ["document_object"]}, end: { id: "554", labels: ["formulationIngredient"]} },
        { id: "272", type: "relationship", label: "MENTIONED_IN", start: { id: "551", labels: ["document_object"]}, end: { id: "556", labels: ["formulationIngredient"]} },
        { id: "273", type: "relationship", label: "MENTIONED_IN", start: { id: "557", labels: ["document_object"]}, end: { id: "348", labels: ["energeticFormulation"]} },
        { id: "274", type: "relationship", label: "MENTIONED_IN", start: { id: "557", labels: ["document_object"]}, end: { id: "558", labels: ["formulationIngredient"]} },
        { id: "275", type: "relationship", label: "MENTIONED_IN", start: { id: "557", labels: ["document_object"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "276", type: "relationship", label: "MENTIONED_IN", start: { id: "557", labels: ["document_object"]}, end: { id: "559", labels: ["formulationIngredient"]} },
        { id: "277", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_0"}, start: { id: "327", labels: ["energeticFormulation"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "278", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_0"}, start: { id: "327", labels: ["energeticFormulation"]}, end: { id: "329", labels: ["formulationIngredient"]} },
        { id: "279", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_0"}, start: { id: "327", labels: ["energeticFormulation"]}, end: { id: "330", labels: ["formulationIngredient"]} },
        { id: "280", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_0"}, start: { id: "327", labels: ["energeticFormulation"]}, end: { id: "331", labels: ["formulationIngredient"]} },
        { id: "281", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_38"}, start: { id: "333", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "282", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_38"}, start: { id: "333", labels: ["energeticFormulation"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "283", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_1"}, start: { id: "333", labels: ["energeticFormulation"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "284", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_2"}, start: { id: "342", labels: ["energeticFormulation"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "285", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_2"}, start: { id: "342", labels: ["energeticFormulation"]}, end: { id: "345", labels: ["formulationIngredient"]} },
        { id: "286", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_2"}, start: { id: "342", labels: ["energeticFormulation"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "287", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_80"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "288", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_3"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "352", labels: ["formulationIngredient"]} },
        { id: "289", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_3"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "353", labels: ["formulationIngredient"]} },
        { id: "290", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_24"}, start: { id: "355", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "291", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_4"}, start: { id: "355", labels: ["energeticFormulation"]}, end: { id: "358", labels: ["formulationIngredient"]} },
        { id: "292", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_6"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "367", labels: ["formulationIngredient"]} },
        { id: "293", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_78"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "294", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_78"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "295", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_7"}, start: { id: "371", labels: ["energeticFormulation"]}, end: { id: "373", labels: ["formulationIngredient"]} },
        { id: "296", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_7"}, start: { id: "371", labels: ["energeticFormulation"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "297", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_30"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "298", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_9"}, start: { id: "378", labels: ["energeticFormulation"]}, end: { id: "380", labels: ["formulationIngredient"]} },
        { id: "299", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_9"}, start: { id: "378", labels: ["energeticFormulation"]}, end: { id: "381", labels: ["formulationIngredient"]} },
        { id: "300", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_27"}, start: { id: "378", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "301", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_27"}, start: { id: "378", labels: ["energeticFormulation"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "302", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_11"}, start: { id: "384", labels: ["energeticFormulation"]}, end: { id: "385", labels: ["formulationIngredient"]} },
        { id: "303", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_11"}, start: { id: "384", labels: ["energeticFormulation"]}, end: { id: "386", labels: ["formulationIngredient"]} },
        { id: "304", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "388", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "305", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "388", labels: ["energeticFormulation"]}, end: { id: "390", labels: ["formulationIngredient"]} },
        { id: "306", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_12"}, start: { id: "388", labels: ["energeticFormulation"]}, end: { id: "391", labels: ["formulationIngredient"]} },
        { id: "307", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_27"}, start: { id: "378", labels: ["energeticFormulation"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "308", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_14"}, start: { id: "396", labels: ["energeticFormulation"]}, end: { id: "385", labels: ["formulationIngredient"]} },
        { id: "309", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_14"}, start: { id: "396", labels: ["energeticFormulation"]}, end: { id: "397", labels: ["formulationIngredient"]} },
        { id: "310", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_15"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "399", labels: ["formulationIngredient"]} },
        { id: "311", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_15"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "400", labels: ["formulationIngredient"]} },
        { id: "312", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_16"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "403", labels: ["formulationIngredient"]} },
        { id: "313", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_73"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "405", labels: ["formulationIngredient"]} },
        { id: "314", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_17"}, start: { id: "407", labels: ["energeticFormulation"]}, end: { id: "409", labels: ["formulationIngredient"]} },
        { id: "315", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_17"}, start: { id: "407", labels: ["energeticFormulation"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "316", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_73"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "317", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_68"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "318", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_20"}, start: { id: "413", labels: ["energeticFormulation"]}, end: { id: "414", labels: ["energeticFormulation"]} },
        { id: "319", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_22"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "420", labels: ["formulationIngredient"]} },
        { id: "320", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_23"}, start: { id: "355", labels: ["energeticFormulation"]}, end: { id: "422", labels: ["formulationIngredient"]} },
        { id: "321", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_24"}, start: { id: "355", labels: ["energeticFormulation"]}, end: { id: "425", labels: ["formulationIngredient"]} },
        { id: "322", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_25"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "427", labels: ["formulationIngredient"]} },
        { id: "323", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_76"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "431", labels: ["formulationIngredient"]} },
        { id: "324", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_29"}, start: { id: "388", labels: ["energeticFormulation"]}, end: { id: "433", labels: ["formulationIngredient"]} },
        { id: "325", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "388", labels: ["energeticFormulation"]}, end: { id: "434", labels: ["formulationIngredient"]} },
        { id: "326", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_75"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "437", labels: ["formulationIngredient"]} },
        { id: "327", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_75"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "438", labels: ["formulationIngredient"]} },
        { id: "328", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_72"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "329", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_35"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "444", labels: ["formulationIngredient"]} },
        { id: "330", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_39"}, start: { id: "447", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "331", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_38"}, start: { id: "449", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "332", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_39"}, start: { id: "447", labels: ["energeticFormulation"]}, end: { id: "452", labels: ["formulationIngredient"]} },
        { id: "333", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_40"}, start: { id: "454", labels: ["energeticFormulation"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "334", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_40"}, start: { id: "454", labels: ["energeticFormulation"]}, end: { id: "376", labels: ["formulationIngredient"]} },
        { id: "335", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_41"}, start: { id: "456", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "336", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_41"}, start: { id: "456", labels: ["energeticFormulation"]}, end: { id: "438", labels: ["formulationIngredient"]} },
        { id: "337", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_41"}, start: { id: "456", labels: ["energeticFormulation"]}, end: { id: "353", labels: ["formulationIngredient"]} },
        { id: "338", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_48"}, start: { id: "458", labels: ["energeticFormulation"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "339", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_42"}, start: { id: "458", labels: ["energeticFormulation"]}, end: { id: "459", labels: ["formulationIngredient"]} },
        { id: "340", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_44"}, start: { id: "463", labels: ["energeticFormulation"]}, end: { id: "328", labels: ["formulationIngredient"]} },
        { id: "341", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_46"}, start: { id: "469", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "342", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_47"}, start: { id: "472", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "343", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_47"}, start: { id: "472", labels: ["energeticFormulation"]}, end: { id: "473", labels: ["formulationIngredient"]} },
        { id: "344", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_48"}, start: { id: "458", labels: ["energeticFormulation"]}, end: { id: "478", labels: ["formulationIngredient"]} },
        { id: "345", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_49"}, start: { id: "469", labels: ["energeticFormulation"]}, end: { id: "481", labels: ["energeticFormulation"]} },
        { id: "346", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_50"}, start: { id: "371", labels: ["energeticFormulation"]}, end: { id: "483", labels: ["energeticFormulation"]} },
        { id: "347", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_51"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "405", labels: ["formulationIngredient"]} },
        { id: "348", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_52"}, start: { id: "487", labels: ["energeticFormulation"]}, end: { id: "486", labels: ["formulationIngredient"]} },
        { id: "349", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_53"}, start: { id: "490", labels: ["energeticFormulation"]}, end: { id: "491", labels: ["formulationIngredient"]} },
        { id: "350", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_53"}, start: { id: "490", labels: ["energeticFormulation"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "351", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_54"}, start: { id: "494", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "352", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_54"}, start: { id: "494", labels: ["energeticFormulation"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "353", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_56"}, start: { id: "469", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "354", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_56"}, start: { id: "469", labels: ["energeticFormulation"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "355", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_57"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "503", labels: ["formulationIngredient"]} },
        { id: "356", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_58"}, start: { id: "505", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "357", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_58"}, start: { id: "505", labels: ["energeticFormulation"]}, end: { id: "506", labels: ["formulationIngredient"]} },
        { id: "358", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_59"}, start: { id: "487", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "359", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_59"}, start: { id: "487", labels: ["energeticFormulation"]}, end: { id: "390", labels: ["formulationIngredient"]} },
        { id: "360", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_59"}, start: { id: "487", labels: ["energeticFormulation"]}, end: { id: "508", labels: ["formulationIngredient"]} },
        { id: "361", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_61"}, start: { id: "510", labels: ["energeticFormulation"]}, end: { id: "350", labels: ["formulationIngredient"]} },
        { id: "362", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_60"}, start: { id: "510", labels: ["energeticFormulation"]}, end: { id: "506", labels: ["formulationIngredient"]} },
        { id: "363", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_61"}, start: { id: "510", labels: ["energeticFormulation"]}, end: { id: "515", labels: ["formulationIngredient"]} },
        { id: "364", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "518", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "365", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "518", labels: ["energeticFormulation"]}, end: { id: "338", labels: ["formulationIngredient"]} },
        { id: "366", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "518", labels: ["energeticFormulation"]}, end: { id: "340", labels: ["formulationIngredient"]} },
        { id: "367", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_62"}, start: { id: "402", labels: ["energeticFormulation"]}, end: { id: "523", labels: ["formulationIngredient"]} },
        { id: "368", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_63"}, start: { id: "525", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "369", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_63"}, start: { id: "525", labels: ["energeticFormulation"]}, end: { id: "526", labels: ["formulationIngredient"]} },
        { id: "370", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_67"}, start: { id: "365", labels: ["energeticFormulation"]}, end: { id: "531", labels: ["formulationIngredient"]} },
        { id: "371", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_69"}, start: { id: "534", labels: ["energeticFormulation"]}, end: { id: "335", labels: ["formulationIngredient"]} },
        { id: "372", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_69"}, start: { id: "534", labels: ["energeticFormulation"]}, end: { id: "536", labels: ["formulationIngredient"]} },
        { id: "373", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_69"}, start: { id: "534", labels: ["energeticFormulation"]}, end: { id: "405", labels: ["formulationIngredient"]} },
        { id: "374", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_71"}, start: { id: "540", labels: ["energeticFormulation"]}, end: { id: "369", labels: ["formulationIngredient"]} },
        { id: "375", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_74"}, start: { id: "545", labels: ["energeticFormulation"]}, end: { id: "344", labels: ["formulationIngredient"]} },
        { id: "376", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_74"}, start: { id: "545", labels: ["energeticFormulation"]}, end: { id: "431", labels: ["formulationIngredient"]} },
        { id: "377", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_79"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "554", labels: ["formulationIngredient"]} },
        { id: "378", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_79"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "556", labels: ["formulationIngredient"]} },
        { id: "379", type: "relationship", label: "HAS_INGREDIENT", properties: {"doc_id":"doc_80"}, start: { id: "348", labels: ["energeticFormulation"]}, end: { id: "558", labels: ["formulationIngredient"]} }
    ]
}

export default data;
