import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  root: {
    flexGrow: "1",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  infoButton: {
    marginBottom: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
  right: {
    marginLeft: "auto",
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&.MuiDivider-light": {
      borderTop: "dotted 1px",
    },
  },
  whiteSpace: {
    whiteSpace: "pre-line",
  },
}));

export default useStyles;
