import React from "react";
import Grid from "@material-ui/core/Grid";

import useStyles from "./styles";
import Account from "./account";
import Bio from "./bio";
import Skills from "./skills";
import Interests from "./interests";
import Notifications from "./notifications";

export default function Profile() {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={4}>
        <Account />
      </Grid>
      <Grid item xs={8} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item>
            <Bio />
          </Grid>
          {/* <Grid item>
            <Skills />
          </Grid> */}
          <Grid item>
            <Interests />
          </Grid>
          <Grid item>
            <Notifications />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
