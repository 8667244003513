import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";

import { useAxios } from "hooks";

import FormikTextField from "components/shared/formik/FormikTextField";
import FormikLabMultiple from "components/shared/formik/FormikLabMultiple";
import FormikLabDropdown from "components/shared/formik/FormikLabDropdown";
import MaxAttachmentWarning from "components/shared/MaxAttachmentWarning";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "65%",
  },
  infoButton: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
}));

const parseTopic = (topic) => {
  switch (topic) {
    case "Modeling":
      return "M";
    case "Synthesis":
      return "S";
    case "Characterization":
      return "C";
    case "Processing":
      return "P";
    default:
      return "O";
  }
};

const CREATE_NOTE = /* GraphQL */ `
  mutation CreateNote(
    $title: String!
    $whyItMatters: String!
    $topic: String!
    $interests: JSONString!
    $details: String!
    $url: String
    $references: String
    $files: [Upload]
  ) {
    createNote(
      title: $title
      whyItMatters: $whyItMatters
      topic: $topic
      interests: $interests
      details: $details
      url: $url
      references: $references
      files: $files
    ) {
      success
    }
  }
`;

const UPDATE_NOTE = /* GraphQL */ `
  mutation UpdateNote(
    $id: ID
    $title: String!
    $whyItMatters: String!
    $topic: String!
    $interests: JSONString!
    $details: String!
    $url: String
    $references: String
    $files: [Upload]
    $existingFiles: JSONString
  ) {
    createNote(
      id: $id
      title: $title
      whyItMatters: $whyItMatters
      topic: $topic
      interests: $interests
      details: $details
      url: $url
      references: $references
      files: $files
      existingFiles: $existingFiles
    ) {
      success
    }
  }
`;

const interestsOptions = [];

const topicOptions = [
  "Modeling",
  "Synthesis",
  "Characterization",
  "Processing",
  "Other",
];

export default function NoteForm({ note }) {
  const api = useAxios();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [editRedirect, setEditRedirect] = useState(false);

  let initialValues = {
    title: "",
    whyItMatters: "",
    topic: "",
    interests: [],
    details: "",
    url: "",
    references: "",
    files: [],
  };

  let cardTitle = "Create a Note";

  if (note !== undefined) {
    initialValues = {
      id: note.id,
      title: note.title || "",
      whyItMatters: note.whyItMatters || "",
      topic: note.topic || "",
      interests: JSON.parse(note.interests) || [],
      details: note.details || "",
      url: note.url || "",
      references: note.references || "",
      files: [],
      existingFiles: note.files || [],
    };

    cardTitle = "Edit Note";
  }

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const {
      id,
      title,
      whyItMatters,
      topic,
      interests,
      details,
      url,
      references,
      files,
      existingFiles,
    } = values;

    const formData = new FormData();
    const map = {};

    files.forEach((f, index) => {
      if (f instanceof File) {
        map[index] = [`variables.files.${index}`];
        formData.append(`${index}`, f);
      }
    });

    if (Object.entries(map).length !== 0) {
      formData.append("map", JSON.stringify(map));
    }

    formData.append(
      "operations",
      JSON.stringify({
        query: id ? UPDATE_NOTE : CREATE_NOTE,
        variables: {
          id,
          title,
          whyItMatters,
          topic: parseTopic(topic),
          interests: JSON.stringify(interests),
          details,
          url,
          references,
          files: new Array(files ? Object.entries(files).length : 0).fill(null),
          existingFiles: JSON.stringify(existingFiles),
        },
      })
    );

    api
      .post("/graphql", formData)
      .then((res) => {
        console.log(res);
        if (res.data.data.createNote.success) {
          enqueueSnackbar(id ? "Note edited!" : "Note created!", {
            variant: "success",
          });

          if (id) {
            setEditRedirect(true);
          }

          resetForm();
        } else {
          enqueueSnackbar("An error occured, please try again", {
            variant: "error",
          });
        }

        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("An error occured, please try again", {
          variant: "error",
        });
        setSubmitting(false);
      });
  };

  if (editRedirect === true) {
    return (
      <Redirect
        to={{
          pathname: `/notes/${note.id}`,
        }}
      />
    );
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .max(200, "Max 200 characters")
            .required("Title is required"),
          whyItMatters: Yup.string().required("Why it matters is required"),
          topic: Yup.string().required("Select a topic"),
          interests: Yup.array()
            .of(Yup.string())
            .required()
            .min(1, "Enter at least one interest"),
          details: Yup.string().required("Details are required"),
          url: Yup.string()
            .max(200, "Max 200 characters")
            .url("Please enter a valid URL"),
          references: Yup.string(),
          files: Yup.mixed(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Card className={classes.card}>
            <Form>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid container item alignItems="center">
                    <Grid item>
                      <Typography variant="h6" color="textPrimary">
                        {cardTitle}
                      </Typography>
                    </Grid>
                  </Grid>
                  <FormikTextField
                    label="Title"
                    name="title"
                    variant="outlined"
                    className={classes.formControl}
                    helperText
                  />
                  <FormikTextField
                    label="Why it matters?"
                    name="whyItMatters"
                    variant="outlined"
                    className={classes.formControl}
                    helperText
                  />
                  <FormikLabDropdown
                    id="topic"
                    label="Topic"
                    name="topic"
                    variant="outlined"
                    className={classes.formControl}
                    options={topicOptions}
                    helperText
                  />
                  <FormikLabMultiple
                    name="interests"
                    label="Interests"
                    className={classes.formControl}
                    options={interestsOptions}
                    helperText
                  />
                  <FormikTextField
                    label="Details"
                    name="details"
                    variant="outlined"
                    multiline
                    rows={10}
                    className={classes.formControl}
                    helperText
                  />
                  <FormikTextField
                    label="URL"
                    name="url"
                    variant="outlined"
                    className={classes.formControl}
                    helperText
                  />
                  <FormikTextField
                    label="References"
                    name="references"
                    variant="outlined"
                    multiline
                    rows={3}
                    className={classes.formControl}
                    helperText
                  />
                  <input
                    id="note-files"
                    className={classes.fileInput}
                    type="file"
                    name="files"
                    multiple
                    onChange={(event) => {
                      setFieldValue("files", [
                        ...values.files,
                        ...event.currentTarget.files,
                      ]);
                    }}
                  />
                  <label htmlFor="note-files">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className={classes.formControl}
                      endIcon={<AddIcon />}
                    >
                      Add File
                    </Button>
                  </label>
                  <List>
                    {values.existingFiles?.map((f, index) => (
                      <ListItem key={`existing-file-${index}`}>
                        <ListItemIcon>
                          <AttachFileIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`File - ${f.file.replace(/^.*[\\\/]/, "")}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label={`delete-existing-file-${index}`}
                            onClick={() => {
                              values.existingFiles.splice(index, 1);
                              setFieldValue(
                                `existingFiles`,
                                values.existingFiles
                              );
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    {values.files.map((f, index) => (
                      <ListItem key={`file-${index}`}>
                        <ListItemIcon>
                          <AttachFileIcon />
                        </ListItemIcon>
                        <ListItemText primary={`File - ${f.name}`} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label={`delete-file-${index}`}
                            onClick={() => {
                              values.files.splice(index, 1);
                              setFieldValue(`files`, values.files);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  <MaxAttachmentWarning
                    className={classes.formControl}
                    files={values.files}
                  />
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.formControl}
                >
                  {isSubmitting ? <CircularProgress size={24.5} /> : "Submit"}
                </Button>
              </CardActions>
            </Form>
          </Card>
        )}
      </Formik>
    </div>
  );
}
