import { useState } from "react";
import TwoFactorAuthentication from "../two-factor-auth";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Container } from "../shared";
import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme) => ({
  notificationText: {
    display: "flex",
    width: "65%",
    marginTop: "30px",
    margin: "auto",
    textAlign: "center",
    border: "1px",
    borderStyle: "solid",
    borderColor: "rgb(198, 198, 198)",
    borderRadius: "4px",
    paddingTop: "5px",
    paddingBottom: "5px",
    background: "rgb(255, 255, 255)",
  },
}));

export default function Login() {
  const classes = useStyles();

  const [twoFactorOpen, setTwoFactorOpen] = useState(false);

  const openTwoFactor = () => {
    setTwoFactorOpen(true);
  };

  const closeTwoFactor = () => {
    setTwoFactorOpen(false);
  };

  return (
    <div>
      <Container>
        <LoginForm handleLoggedIn={openTwoFactor} />
        <TwoFactorAuthentication
          open={twoFactorOpen}
          handleClose={closeTwoFactor}
        />
      </Container>
      <Typography className={classes.notificationText}>
        This system contains Energetics Technology Center proprietary
        information is approved for the storage of Controlled Unclassified
        Information (CUI). <br />
        <br /> All individuals accessing or handling information on this system
        are required to protect it from unauthorized disclosure. Handling,
        storage, reproduction, and disposition of document(s) and records must
        be in accordance with applicable policies, directives, and export
        control laws. Access to and dissemination of CUI shall be allowed as
        necessary and permissible to any individual(s), organization(s), or
        grouping(s) of users, provided such access or dissemination is
        consistent with or in furtherance of Lawful Government Purpose and in a
        manner consistent with applicable laws, regulations, and Government-wide
        policies.
      </Typography>
    </div>
  );
}
