import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useAxios } from "hooks";

const UPLOAD_FILES = /* GraphQL */ `
  mutation UploadFiles($files: [Upload!]) {
    uploadPublication(files: $files) {
      success
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function PublicationUpload() {
  const api = useAxios();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFileUpload = () => {
    setSubmitting(true);

    const formData = new FormData();
    const map = {};

    files.forEach((f, index) => {
      if (f instanceof File) {
        map[index] = [`variables.files.${index}`];
        formData.append(`${index}`, f);
      }
    });

    if (Object.entries(map).length !== 0) {
      formData.append("map", JSON.stringify(map));
    }

    formData.append(
      "operations",
      JSON.stringify({
        query: UPLOAD_FILES,
        variables: {
          files: new Array(files ? Object.entries(files).length : 0).fill(null),
        },
      })
    );

    api
      .post("/graphql", formData)
      .then((res) => {
        console.log(res);
        if (res.data.data.uploadPublication.success) {
          enqueueSnackbar(`${files.length} files uploaded!`, {
            variant: "success",
          });
          setFiles([]);
        } else {
          enqueueSnackbar("An error occured, please try again", {
            variant: "error",
          });
        }

        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("An error occured, please try again", {
          variant: "error",
        });

        setSubmitting(false);
      });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Typography variant="h5">Publication Upload</Typography>
      <Grid item>
        <Card>
          <CardContent>
            <Typography variant="body1" component="p">
              This page allows you to upload PDFs for automatic parsing and
              classification by the sciencebeam pipeline. It is currently
              restricted to staff members, and exists mostly as a testing tool.
              The publications that are successfully parsed will be searchable
              on the Search page with metadata included.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Input
          type="file"
          onChange={(e) => {
            setFiles([...e.currentTarget.files]);
          }}
          inputProps={{ multiple: true }}
          className={classes.formControl}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onFileUpload()}
          disabled={files.length === 0 || isSubmitting}
          className={classes.formControl}
        >
          {isSubmitting ? <CircularProgress size={24.5} /> : "Upload"}
        </Button>
      </Grid>
    </Grid>
  );
}
