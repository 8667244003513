import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import EwareCard from "../shared/EwareCard";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
  },
  publishedTextStyle: {
    paddingTop: "10px",
    fontWeight: "bold",
  },
}));

export default function ArticleCard({ article }) {
  const classes = useStyles();

  const cardContent = {
    title: article.name || "Title not found",
    type: "article",
    targetId: null,
  };

  return (
    <EwareCard content={cardContent} showCommentButton={false}>
      <Grid container spacing={2}>
        {" "}
        {/* entire card internals grid */}
        <Grid item sm container spacing={1}>
          {" "}
          {/* left side internals grid */}
          <Grid item sm container spacing={1}>
            {" "}
            {/* left side internals grid */}
            <Grid item>
              <Typography component="span" color="textSecondary">
                Source:{" "}
              </Typography>
              <Typography component="span">
                <Link href={article.sourceUrl}>{article.source}</Link>
              </Typography>
            </Grid>
            {article.author && (
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Author:{" "}
                </Typography>
                <Typography component="span">{article.author}</Typography>
              </Grid>
            )}
            {article.abstract && (
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Abstract:{" "}
                </Typography>
                <Typography component="span">{article.abstract}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignContent="space-around"
        >
          {/* right side internals grid */}
          <Grid item xs={6}>
            <Grid container>
              {article.pubDate && (
                <Grid item>
                  <Typography component="span" color="textSecondary">
                    Published on:{" "}
                  </Typography>
                  <Typography component="span">
                    {moment(article.pubDate).format("MMM Do, YYYY")}
                  </Typography>
                </Grid>
              )}
              {article.timestamp && (
                <Grid item>
                  <Typography component="span" color="textSecondary">
                    Collected on:{" "}
                  </Typography>
                  <Typography component="span">
                    {moment(article.timestamp).format("MMM Do, YYYY")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EwareCard>
  );
}
