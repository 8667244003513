import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Calendar from "./Calendar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Events() {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.root} spacing={2}>
      <Grid item>
        <Typography variant="h5">Calendar</Typography>
      </Grid>
      <Calendar />
    </Grid>
  );
}
