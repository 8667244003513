import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container as MuiContainer } from "@material-ui/core/";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import logo from "../../../images/etc_logo2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  logo: {
    margin: theme.spacing(2),
    height: 128,
  },
}));

export default function Container({ children }) {
  const classes = useStyles();

  return (
    <MuiContainer className={classes.root} maxWidth="sm">
      <Paper>
        <Grid container direction="column" alignItems="center">
          <img src={logo} alt="EWARE Logo" className={classes.logo} />
        </Grid>
        {children}
      </Paper>
    </MuiContainer>
  );
}
