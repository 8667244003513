import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { useAxios } from "hooks";
import Comments from "components/comments";
import EwaveCard from "./EwaveCard.js";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

//Note: impact is not consistent between ewaveById and allEwaveDocuments
const GET_EWAVE = /* GraphQL */ `
  query($id: String!) {
    ewaveById(id: $id) {
      id
      title
      body
      impact
      interests
      pubDate
      author {
        email
        organization
        firstName
        lastName
      }
      comments {
        content
        createdOn
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default function Ewave() {
  let { id } = useParams();
  const api = useAxios();
  const classes = useStyles();
  const [ewave, setEwave] = useState();
  const [comments, setComments] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", {
        query: GET_EWAVE,
        variables: { id },
      })
      .then((res) => {
        const data = res.data.data;

        setEwave(data.ewaveById);
        setComments(data.ewaveById?.comments);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (ewave === null) {
    return <div>No eWave with that ID exists</div>;
  }

  return (
    <>
      <EwaveCard
        key={ewave.id}
        ewave={ewave}
        showCommentButton={false}
        viewButtons={false}
      ></EwaveCard>
      <Comments contentType="EWAVE" objectId={ewave.id} comments={comments} />
    </>
  );
}
