import { createContext, useContext } from "react";

const value = {
  state: {},
  actions: {},
};

const NotifyContext = createContext(value);

export function useNotifyState() {
  const context = useContext(NotifyContext);
  if (context === undefined) {
    throw new Error("useNotifyState must be used within a NotifyProvider");
  }

  return context;
}

export { NotifyContext };
