import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import TranscriptCard from "./TranscriptCard";

//TODO remove data, only a temporary measure
import data from "./data.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  outercard: {
    "& .MuiPaper-root": {
      borderRadius: 0,
    },
  },
}));

export default function Transcripts() {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {data.transcripts.map((transcript, index) => (
        <Grid className={classes.outercard} item xs={12} xl={6}>
          <TranscriptCard
            key={transcript.id}
            transcript={transcript}
            viewButton={true}
            viewMore={false}
          />
        </Grid>
      ))}
    </Grid>
  );
}
