import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import Grow from "@material-ui/core/Grow";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";

import { AuthProvider } from "./context";
import ClippedDrawer from "components/ClippedDrawer";
import {
  Login,
  Registration,
  PasswordReset,
  EmailVerification,
  TwoFactorAuthentication,
} from "components/unauthenticated";
import PrivateRoute from "components/routing/PrivateRoute";
import Onboarding from "components/onboarding/Onboarding";
import AuthVerify from "components/AuthVerify";

import NotifyProvider from "./notifications/provider.js";

function App() {
  // TODO do we need to have close button on snackbars?
  // const notistackRef = React.createRef();
  // const handleSnackClose = (key) => () => {
  //   notistackRef.current.closeSnackbar(key);
  // };

  const theme = createTheme({
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            fontSize: "1rem",
          },
        },
      },
    },
  });

  return (
    <AuthProvider>
      <SnackbarProvider
        // ref={notistackRef}
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        TransitionComponent={Grow}
        // TODO come back to this action button and styling
        // do we need this?
        // action={(key) => (
        //   <IconButton onClick={handleSnackClose(key)}>
        //     <ClearIcon />
        //   </IconButton>
        // )}
      >
        <Router>
          <ThemeProvider theme={theme}>
            <CssBaseline />
          </ThemeProvider>

          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/registration">
              <Registration />
            </Route>
            <Route path="/password-reset">
              <PasswordReset />
            </Route>
            <Route path="/activate">
              <EmailVerification />
            </Route>
            {/* <Route path="/two-factor">
              <TwoFactorAuthentication />
            </Route> */}
            <PrivateRoute path="/">
              <NotifyProvider>
                <AuthVerify />
                <ClippedDrawer />
                <Onboarding />
              </NotifyProvider>
            </PrivateRoute>
          </Switch>
        </Router>
      </SnackbarProvider>
    </AuthProvider>
  );
}

export default App;
