import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  annotation: {},
}));

function renderAnnotations(data, annotationKeys, x, y) {
  return annotationKeys.map((d, i) => {
    if (d in data) {
      return (
        <text
          key={i}
          dx="7"
          dy="0.35em"
          x={x(data[d][0])}
          y={y(data[d][1])}
          fill="none"
          stroke="#000"
          strokeWidth="1"
        >
          {d}
        </text>
      );
    }
  });
}

function Annotations({ data, annotationKeys, x, y }) {
  const classes = useStyles();
  const [annotations, setAnnotations] = useState(null);
  // const annotations = renderAnnotations(data, annotationKeys, x, y);

  useEffect(() => {
    setAnnotations(renderAnnotations(data, annotationKeys, x, y));
  }, [data, annotationKeys, x, y]);

  return (
    <g
      fontFamily="sans-serif"
      fontSize={10}
      strokeLinejoin="round"
      strokeLinecap="round"
    >
      {annotations}
    </g>
  );
}

Annotations.defaultProps = {
  annotationKeys: [
    "shock",
    "cl20",
    "underwater",
    "isentrope",
    "rarefaction",
    "hugoniot",
    "hydrocode",
    "isotherm",
    "conservation",
    "octahydro1357tetranitro1357tetrazocine",
    "hexahydro135trinitro135triazine",
    "propellant",
    "explosion",
    "deflagration",
    "h2o",
    "cfd",
    "polybutadiene",
    "detonate",
    "chapman-jouguet",
  ],
};

Annotations.propTypes = {
  annotationKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Annotations;
