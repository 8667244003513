import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import FormikTextField from "../../shared/formik/FormikTextField";
import { API } from "api";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
    maxWidth: 260,
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
}));

const SEND_PASSWORD_RESET_EMAIL = /* GraphQL */ `
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export default function SendEmailForm() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);

  const handleSnackVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleSubmit = async (values, setSubmitting) => {
    const { email } = values;
    API.post("/graphql", {
      query: SEND_PASSWORD_RESET_EMAIL,
      variables: { email },
    })
      .then((res) => {
        if (res.data.data.sendPasswordResetEmail.success) {
          handleSnackVariant(`Email sent to ${email}`, "success");
          setSendEmailSuccess(true);
        } else {
          handleSnackVariant(
            "Something went wrong, please try again...",
            "error"
          );
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        handleSnackVariant(
          "Something went wrong, please try again...",
          "error"
        );
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string("Enter email")
          .email("Must be a valid email address.")
          .required("Email is required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container direction="column" alignItems="center">
            {sendEmailSuccess === true && (
              <Grid item>
                <Alert className={classes.alert}>
                  Email sent to {values.email}. If you did not receive anything
                  please ensure that you are entering the correct email below
                  and try again.
                </Alert>
              </Grid>
            )}
            <Grid item>
              <FormikTextField
                label="Email"
                name="email"
                className={classes.formControl}
                variant="outlined"
                helperText
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.formControl}
                type="submit"
              >
                {isSubmitting ? (
                  <CircularProgress size={24.5} />
                ) : (
                  "Send Reset Email"
                )}
              </Button>
            </Grid>

            <Grid item>
              <Button
                to="/login"
                component={RouterLink}
                color="primary"
                className={classes.formControl}
              >
                Back to Login
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
