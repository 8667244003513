import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

import EwareCard from "../shared/EwareCard.js";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(0.5),
  },
  right: {
    marginLeft: "auto",
  },
}));

export default function TranscriptCard({ transcript, viewButton, viewMore }) {
  const classes = useStyles();

  const cardContent = {
    title: transcript.doc.name,
    type: "transcript",
    targetId: transcript.id,
    buttons: [],
  };

  if (viewButton) {
    cardContent.buttons.push({ type: "view", show: true });
  }

  cardContent.buttons.push({
    type: "media",
    datatype: "transcript",
    show: true,
    title: transcript.doc.name,
    file: transcript.doc.file,
  });

  return (
    <EwareCard
      content={cardContent}
      idx={transcript.id}
      showCommentButton={false}
    >
      <Grid container>
        {/* entire card internals grid */}
        <Grid item xs={6} container direction="column">
          {/* left side internals grid */}
          <Grid item>
            <Typography component="span" color="textSecondary">
              Keywords:{" "}
            </Typography>
            {transcript.keywords.map(
              (k, idx) =>
                idx < 3 && <Chip className={classes.chip} key={idx} label={k} />
            )}
            {transcript.keywords.length > 3 && (
              <Chip
                className={classes.chip}
                label={"+" + (transcript.keywords.length - 3) + " more"}
              />
            )}
          </Grid>
          {viewMore && (
            <div>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Metadata:{" "}
                </Typography>
                <Typography component="span">Under Development</Typography>
              </Grid>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Document Type:{" "}
                </Typography>
                <Typography component="span">
                  {transcript.doc.docType}
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Key Highlights, Discussion, and Questions:{" "}
                </Typography>
                <Typography component="span">Under Development</Typography>
              </Grid>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Summary/Abstract:{" "}
                </Typography>
                <Typography component="span">Under Development</Typography>
              </Grid>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Character / Word / Page count:{" "}
                </Typography>
                <Typography component="span">
                  1000 Characters / 100 Words / 1 Page (Under Develoment)
                </Typography>
              </Grid>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignContent="space-around"
        >
          {/* right side internals grid */}
          <Grid item>
            <Typography component="span" color="textSecondary">
              Date:{" "}
            </Typography>
            <Typography component="span">
              {moment(transcript.doc.date).format("MMM Do, YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" color="textSecondary">
              Participants {viewMore && " and Engagement"}:{" "}
            </Typography>
          </Grid>
          {transcript.participants.map((p, idx) => (
            <Grid item>
              <Typography component="span">
                {p.name} {viewMore && " - " + p.engagement}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </EwareCard>
  );
}
