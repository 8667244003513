import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";

import { useAxios } from "hooks";

import FormikTextField from "components/shared/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

const CREATE_COMMENT = /* GraphQL */ `
  mutation CreateComment(
    $contentType: ContentType!
    $objectId: Int!
    $content: String!
  ) {
    createComment(
      contentType: $contentType
      objectId: $objectId
      content: $content
    ) {
      success
    }
  }
`;

export default function CommentForm(props) {
  const api = useAxios();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const { contentType, objectId, content } = values;

    api
      .post("/graphql", {
        query: CREATE_COMMENT,
        variables: { contentType, objectId, content },
      })
      .then((res) => {
        if (res.data.data.createComment.success) {
          handleSnackVariant("Comment posted!", "success");
          props.setUpdateComments(true);
          resetForm();
        } else {
          handleSnackVariant(
            "Something went wrong, please try again...",
            "error"
          );
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        handleSnackVariant(
          "Something went wrong, please try again...",
          "error"
        );
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          contentType: props.contentType,
          objectId: props.objectId,
          content: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Card>
              <CardContent>
                <FormikTextField
                  label="Add a Comment"
                  name="content"
                  variant="outlined"
                  multiline
                  style={{ width: "50%" }}
                  rows={6}
                  className={classes.formControl}
                  helperText
                />
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.formControl}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24.5} />
                  ) : (
                    "Post Comment"
                  )}
                </Button>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}
