import { useField } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function FormikFreesoloDropdown({ ...props }) {
  const [field, meta, helpers] = useField(props);
  const { helperText, ...rest } = props;
  return (
    <Autocomplete
      id={props.id}
      {...rest}
      {...field}
      freeSolo
      getOptionLabel={(option) => option}
      onChange={(e, value) => {
        helpers.setValue(value);
      }}
      renderOption={(option) => option}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={props.label}
            name={props.name}
            onChange={(e) => {
              helpers.setValue(e.target.value);
            }}
            error={meta.touched && Boolean(meta.error)}
            helperText={props.helperText && meta.touched && meta.error}
          />
        );
      }}
    />
  );
}
