import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Link as RouterLink } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";

import { API } from "api";
import FormikTextField from "../../shared/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
    maxWidth: 260,
  },
}));

const REGISTER = /* GraphQL */ `
  mutation Register(
    $email: String!
    $firstName: String!
    $lastName: String!
    $organization: String!
    $password1: String!
    $password2: String!
    $requestReason: String!
  ) {
    register(
      email: $email
      username: $email
      firstName: $firstName
      lastName: $lastName
      organization: $organization
      password1: $password1
      password2: $password2
      requestReason: $requestReason
    ) {
      success
      errors
    }
  }
`;

export default function Registration() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [registration, setRegistration] = useState({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSnackVariant = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleRegister = (values, setSubmitting) => {
    const {
      email,
      firstName,
      lastName,
      organization,
      password1,
      password2,
    } = values;

    API.post("/graphql", {
      query: REGISTER,
      variables: {
        email,
        firstName,
        lastName,
        organization,
        password1,
        password2,
        requestReason: "",
      },
    })
      .then((res) => {
        const data = res.data.data.register;
        if (data.success === true) {
          setRegistration(res.data.data.register);
        } else {
          handleSnackVariant(
            "Something went wrong, please try again...",
            "error"
          );
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        handleSnackVariant(
          "Something went wrong, please try again...",
          "error"
        );
        setSubmitting(false);
      });
  };

  if (registration.success === true) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { registrationSuccess: true },
        }}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password1: "",
        password2: "",
        organization: "",
        // requestReason: "",
      }}
      validationSchema={Yup.object({
        firstName: Yup.string("Enter first name").required(
          "First name is required"
        ),
        lastName: Yup.string("Enter last name").required(
          "Last name is required"
        ),
        email: Yup.string("Enter email")
          .email("Must be a valid email address.")
          .required("Email is required"),
        password1: Yup.string("Enter password")
          .required("Password is required")
          .min(8, "Password should be 8 characters minimum.")
          .required("Password is required"),
        password2: Yup.string().oneOf(
          [Yup.ref("password1"), null],
          "Passwords must match"
        ),
        organization: Yup.string("Enter organization").required(
          "Organization is required"
        ),
        // requestReason: Yup.string(
        //   "Enter the reason for requesting an account"
        // ).required("Reason for request is required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleRegister(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container direction="column" alignItems="center">
            <FormikTextField
              name="firstName"
              label="First name"
              className={classes.formControl}
              variant="outlined"
              helperText
            />
            <FormikTextField
              name="lastName"
              label="Last name"
              className={classes.formControl}
              variant="outlined"
              helperText
            />
            <FormikTextField
              name="email"
              label="Email"
              className={classes.formControl}
              variant="outlined"
              helperText
            />
            <FormikTextField
              name="password1"
              label="Password"
              type={showPassword ? "text" : "password"}
              className={classes.formControl}
              variant="outlined"
              helperText
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormikTextField
              name="password2"
              label="Confirm password"
              type={showPassword ? "text" : "password"}
              className={classes.formControl}
              variant="outlined"
              helperText
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormikTextField
              name="organization"
              label="Organization"
              className={classes.formControl}
              variant="outlined"
              helperText
            />
            {/* <FormikTextField
                    name="requestReason"
                    label="Reason for request"
                    className={classes.formControl}
                    variant="outlined"
                    helperText
                  /> */}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              className={classes.formControl}
              type="submit"
            >
              {isSubmitting ? (
                <CircularProgress size={24.5} />
              ) : (
                "Request Account"
              )}
            </Button>
            <Button
              component={RouterLink}
              to="/login"
              color="primary"
              className={classes.formControl}
            >
              Already have an account? Sign in
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
