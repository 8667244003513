import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import { useAuthState } from "context/auth";
import EwareCard from "../shared/EwareCard";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  whiteSpace: {
    whiteSpace: "pre-line",
  },
}));

export default function NoteCard({ note, showCommentButton, viewButton }) {
  const classes = useStyles();
  const commentCount = note.comments.length;
  const {
    user: { email },
  } = useAuthState();

  if (!note.id && !note.pk) {
    console.log("NO KEY!!!!");
  }

  const nameCheck = (enteredBy) => {
    let tmpName = enteredBy.firstName + " " + enteredBy.lastName;
    return tmpName.trim().length < 1 ? "EWARE Internal" : tmpName;
  };

  const cardContent = {
    title: note.title,
    type: "note",
    targetId: note.pk || note.id,
    numComments: commentCount,
    buttons: viewButton && [
      {
        type: "view",
        show: true,
      },
      {
        type: "edit",
        show: note.enteredBy.email === email,
      },
    ],
  };

  return (
    <EwareCard
      content={cardContent}
      idx={note.id || note.pk}
      showCommentButton={showCommentButton}
    >
      <Grid container>
        {/* entire card internals grid */}
        <Grid item xs={6} container direction="column">
          {/* left side internals grid */}
          <Grid item>
            <Typography component="span" color="textSecondary">
              Topic:{" "}
            </Typography>
            <Typography component="span">{note.topic}</Typography>
          </Grid>
          <Grid item>
            <Typography component="span" color="textSecondary">
              Why it matters?{" "}
            </Typography>
            <Typography component="span">{note.whyItMatters}</Typography>
          </Grid>
          <Grid item>
            <Typography component="span" color="textSecondary">
              Details:{" "}
            </Typography>
            <Typography component="span" className={classes.whiteSpace}>
              {note.details}
            </Typography>
          </Grid>
          {note.references && (
            <Grid item>
              <Typography component="span" color="textSecondary">
                References:{" "}
              </Typography>
              <Typography component="span" className={classes.whiteSpace}>
                {note.references}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignContent="space-around"
        >
          {/* right side internals grid */}
          <Grid item>
            <Typography component="span" color="textSecondary">
              Date:{" "}
            </Typography>
            <Typography component="span">
              {moment(note.entryDate).format("MMM Do, YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" color="textSecondary">
              Entered by:{" "}
            </Typography>
            <Typography component="span">
              {nameCheck(note.enteredBy)}
            </Typography>
          </Grid>
          {note.url && (
            <Grid item>
              <Typography component="span" color="textSecondary">
                URL:{" "}
              </Typography>
              <Typography component="span">
                <Link
                  href={note.url}
                  variant="inherit"
                  target="_blank"
                  rel="noopener"
                >
                  {note.url.length > 17
                    ? note.url.substring(0, 16) + "..."
                    : note.url}
                </Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </EwareCard>
  );
}
