import { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import data from "./data";

function PrototypeKnowledgeGraph(
  {
    nodes, // an iterable of node objects (typically [{id}, …])
    links, // an iterable of link objects (typically [{source, target}, …])
  },
  {
    nodeLabel = (d) => d.label,
    nodeId = (d) => d.id, // given d in nodes, returns a unique identifier (string)
    nodeGroup = (d) => d.labels[0], // given d in nodes, returns an (ordinal) value for color
    nodeGroups, // an array of ordinal values representing the node groups
    nodeTitle, // given d in nodes, a title string
    nodeFill = "currentColor", // node stroke fill (if not using a group color encoding)
    nodeStroke = "#fff", // node stroke color
    touchedNodeStroke = "#000",
    nodeStrokeWidth = 1.5, // node stroke width, in pixels
    nodeStrokeOpacity = 1, // node stroke opacity
    nodeRadius = 8, // node radius, in pixels
    nodeStrength,
    linkSource = ({ start }) => start.id, // given d in links, returns a node identifier string
    linkTarget = ({ end }) => end.id, // given d in links, returns a node identifier string
    linkStroke = "#999", // link stroke color
    linkStrokeOpacity = 0.5, // link stroke opacity
    linkStrokeWidth = 1.5, // given d in links, returns a stroke width in pixels
    touchedStrokeOpacity = 1.0,
    touchedLinkStrokeWidth = 2.0,
    linkStrokeLinecap = "round", // link stroke linecap
    linkStrength,
    colors = d3.schemeTableau10, // an array of color strings, for the node groups
    width = 640, // outer width, in pixels
    height = 400, // outer height, in pixels
    invalidation, // when this promise resolves, stop the simulation
    d3Ref,
  } = {}
) {
  // Compute values.
  const N = d3.map(nodes, nodeId).map(intern);
  const LS = d3.map(links, linkSource).map(intern);
  const LT = d3.map(links, linkTarget).map(intern);
  if (nodeTitle === undefined) nodeTitle = (_, i) => N[i];
  const T = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
  const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);
  const W =
    typeof linkStrokeWidth !== "function"
      ? null
      : d3.map(links, linkStrokeWidth);
  const L = typeof linkStroke !== "function" ? null : d3.map(links, linkStroke);

  // Replace the input nodes and links with mutable objects for the simulation.
  nodes = d3.map(nodes, (_, i) => ({
    id: N[i],
    label:
      nodes[i].labels[0] === "document_object" &&
      nodes[i].properties.hasOwnProperty("title")
        ? nodes[i].properties.title
        : nodes[i].properties.name,
    group: nodes[i].labels[0],
  }));
  links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i] }));

  // Compute default domains.
  if (G && nodeGroups === undefined) nodeGroups = d3.sort(G);

  // Construct the scales.
  const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);
  //const color = "gray";

  // Construct the forces.
  const forceNode = d3.forceManyBody();
  const forceLink = d3.forceLink(links).id(({ index: i }) => N[i]);
  if (nodeStrength !== undefined) forceNode.strength(nodeStrength);
  if (linkStrength !== undefined) forceLink.strength(linkStrength);

  const simulation = d3
    .forceSimulation(nodes)
    .force("link", forceLink)
    .force("charge", forceNode)
    .force("center", d3.forceCenter())
    .on("tick", ticked);

  const svg = d3
    .select(d3Ref.current)
    .append("svg")
    .attr("width", "100%")
    .attr("viewBox", [-width / 2, -height / 2, width, height])
    .attr("style", "border: 1px solid #7986cb; border-radius: 5px");

  const link = svg
    .append("g")
    .attr("stroke", typeof linkStroke !== "function" ? linkStroke : null)
    .attr("stroke-opacity", linkStrokeOpacity)
    .attr(
      "stroke-width",
      typeof linkStrokeWidth !== "function" ? linkStrokeWidth : null
    )
    .attr("stroke-linecap", linkStrokeLinecap)
    .selectAll("line")
    .data(links)
    .join("line");

  const node = svg
    .append("g")
    .attr("fill", nodeFill)
    .attr("stroke", nodeStroke)
    .attr("stroke-opacity", nodeStrokeOpacity)
    .attr("stroke-width", nodeStrokeWidth)
    .selectAll("circle")
    .data(nodes)
    .join("circle")
    .attr("r", nodeRadius)
    .call(drag(simulation))
    .on("mouseenter", (evt, d) => {
      link
        .attr("display", "none")
        .filter((l) => l.source.id === d.id || l.target.id === d.id)
        .attr("display", "block")
        .attr("stroke-opacity", touchedStrokeOpacity)
        .attr("stroke-width", touchedLinkStrokeWidth);
      node.filter((nd) => nd.id === d.id).attr("stroke", touchedNodeStroke);
      node
        .filter((nd) => getNodeIDsLinkedTo(d).includes(nd.id))
        .attr("stroke", touchedNodeStroke);
      mouseoverLabelSetter(d);
    })
    .on("mouseleave", (evt, d) => {
      link
        .attr("display", "block")
        .filter((l) => l.source.id === d.id || l.target.id === d.id)
        .attr("display", "block")
        .attr("stroke-opacity", linkStrokeOpacity)
        .attr("stroke-width", linkStrokeWidth);
      node.filter((n) => n.id === d.id).attr("stroke", nodeStroke);
      node
        .filter((nd) => getNodeIDsLinkedTo(d).includes(nd.id))
        .attr("stroke", nodeStroke);
      defaultLabelSetter();
    });

  const labels = svg
    .selectAll("text.label")
    .data(nodes)
    .enter()
    .append("text")
    .attr("class", "label")
    .attr("fill", "black")
    .attr("dx", 0)
    .attr("dy", -10)
    .text(nodeLabel)
    .style("pointer-events", "none")
    .attr("opacity", 0);

  defaultLabelSetter();

  var zoom = d3.zoom().scaleExtent([0.1, 8]).on("zoom", zoomed);

  function zoomed(event) {
    node.attr("transform", event.transform);
    link.attr("transform", event.transform);
    labels.attr("transform", event.transform);
  }

  svg.call(zoom);

  if (W) link.attr("stroke-width", ({ index: i }) => W[i]);
  if (L) link.attr("stroke", ({ index: i }) => L[i]);
  if (G) node.attr("fill", ({ index: i }) => color(G[i]));
  if (T) node.append("title").text(({ index: i }) => T[i]);
  if (invalidation != null) invalidation.then(() => simulation.stop());

  function intern(value) {
    return value !== null && typeof value === "object"
      ? value.valueOf()
      : value;
  }

  function blankAllLabels() {
    svg.selectAll("text.label").attr("opacity", 0);

    return null;
  }

  function defaultLabelSetter() {
    blankAllLabels();

    svg
      .selectAll("text.label")
      .filter((d) => d.group === "document_object")
      .attr("opacity", 1);

    return null;
  }

  function mouseoverLabelSetter(thisNode) {
    blankAllLabels();

    svg
      .selectAll("text.label")
      .filter((d) => getNodeIDsLinkedTo(thisNode).includes(d.id))
      .attr("opacity", 1);

    return null;
  }

  function getNodeIDsLinkedTo(thisNode) {
    var linksToNode = link.filter(
      (curLink) =>
        curLink.source.id === thisNode.id || curLink.target.id === thisNode.id
    ); //Get all links that point from or to the given node
    var linkedNodeIDs = [];
    linksToNode.each((curLink) => linkedNodeIDs.push(curLink.source.id));
    linksToNode.each((curLink) => linkedNodeIDs.push(curLink.target.id));

    return linkedNodeIDs;
  }

  function ticked() {
    link
      .attr("x1", (d) => d.source.x)
      .attr("y1", (d) => d.source.y)
      .attr("x2", (d) => d.target.x)
      .attr("y2", (d) => d.target.y);

    node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

    labels.attr("x", (d) => d.x).attr("y", (d) => d.y);
  }

  function drag(simulation) {
    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }

  return Object.assign(svg.node(), { scales: { color } });
}

export default function PrototypeKnowledge() {
  const d3Ref = useRef(null);

  useEffect(() => {
    PrototypeKnowledgeGraph(data, {
      nodeId: (d) => d.id,
      nodeGroup: (d) => d.labels[0],
      nodeLabel: (d) => d.label,
      nodeTitle: (d) => d.id,
      linkStrokeWidth: (l) => Math.sqrt(l.value),
      width: 640,
      height: 365,
      d3Ref,
    });
  }, []);

  return (
    <div
      ref={d3Ref}
      style={{
        marginTop: "10px",
      }}
    ></div>
  );
}
