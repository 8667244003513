import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { useNotifyState } from "notifications/context";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import LinkIcon from "@material-ui/icons/Link";
import MarkunreadIcon from "@material-ui/icons/Markunread";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  unread: {
    fontWeight: 700,
  },
  header: {
    paddingBottom: 20,
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const {
    notificationsList,
    markNotifyRead,
    markNotifyUnread,
    markNotifyDeleted,
  } = useNotifyState();

  const buildLink = (type, c_id) => {
    let rtn = null;

    switch (type) {
      case "note":
        rtn = "/notes/" + c_id;
        break;
      case "ewave":
        rtn = "/notes/ewaves/" + c_id;
        break;
      case "eventreport":
        rtn = "/events/" + c_id;
        break;
      default:
        console.log("error, unknown notification source type");
        return null;
    }

    return rtn;
  };

  return (
    <Grid>
      <Grid item container>
        <Typography variant="h5" className={classes.header}>
          All Notifications
        </Typography>
      </Grid>
      <Card>
        <List dense={true}>
          {notificationsList.map((n, key) => {
            if (!n.categoryEnabled || n.deleted) {
              return null;
            }

            return (
              <div>
                <ListItem className={classes.row} key={key}>
                  <ListItemText
                    primary={n.notificationCategory.defaultMessage}
                    classes={n.read ? null : { primary: classes.unread }}
                    secondary={moment(n.createdOn).fromNow()}
                  />
                  <Tooltip title="View">
                    <Link
                      to={buildLink(
                        n.notificationCategory.contentType.model,
                        n.sourceId
                      )}
                      onClick={() => markNotifyRead(n.id)}
                    >
                      <ListItemIcon>
                        <LinkIcon />
                      </ListItemIcon>
                    </Link>
                  </Tooltip>
                  {n.read && (
                    <Tooltip title="Unread">
                      <ListItemIcon
                        onClick={() => {
                          markNotifyUnread(n.id);
                          n.read = false;
                        }}
                      >
                        <DraftsOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {!n.read && (
                    <Tooltip title="Read">
                      <ListItemIcon onClick={() => markNotifyRead(n.id)}>
                        <MarkunreadIcon />
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  <Tooltip title="Delete">
                    <ListItemIcon onClick={() => markNotifyDeleted(n.id)}>
                      <DeleteIcon />
                    </ListItemIcon>
                  </Tooltip>
                </ListItem>
                <Divider variant="middle" />
              </div>
            );
          })}
        </List>
      </Card>
    </Grid>
  );
}
