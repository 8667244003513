import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { Container } from "../shared";
import SendEmailForm from "./SendEmailForm";
import PasswordResetForm from "./PasswordResetForm";

export default function PasswordReset() {
  let match = useRouteMatch();

  return (
    <Container>
      <Switch>
        <Route path={`${match.url}/:token`} children={<PasswordResetForm />} />
        <Route path={match.url} children={<SendEmailForm />} />
      </Switch>
    </Container>
  );
}
