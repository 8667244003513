let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : "";
let token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : "";
let refreshToken = localStorage.getItem("refreshToken")
  ? JSON.parse(localStorage.getItem("refreshToken"))
  : "";
let sessionExpirationDate = localStorage.getItem("sessionExpirationDate")
  ? new Date(JSON.parse(localStorage.getItem("sessionExpirationDate")))
  : null;
let otpDeviceId = localStorage.getItem("otpDeviceId")
  ? JSON.parse(localStorage.getItem("otpDeviceId"))
  : "";

const initialState = {
  user: "" || user,
  token: "" || token,
  refreshToken: "" || refreshToken,
  otpDeviceId: "" || otpDeviceId,
  loading: false,
  errorMessage: null,
  sessionExpirationDate: null || sessionExpirationDate,
  expirationMessage: null,
};

export default initialState;
