export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN_SUCCESS": {
      let newState = {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        loading: false,
        sessionExpirationDate: action.payload.sessionExpirationDate,
      };
      if (process.env.REACT_APP_2FA_ENABLED === "false") {
        newState["otpDeviceId"] = "development_device_id";
      }
      return newState;
    }
    case "LOGOUT":
      return {
        ...state,
        user: "",
        token: "",
        refreshToken: "",
        otpDeviceId: "",
        loading: false,
        errorMessage: null,
        sessionExpirationDate: null,
        expirationMessage: null,
      };
    case "EXPIRE_SESSION":
      return {
        ...state,
        user: "",
        token: "",
        refreshToken: "",
        otpDeviceId: "",
        loading: false,
        errorMessage: null,
        sessionExpirationDate: null,
        expirationMessage: "Your session has expired.",
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        errorMessage: action.errors,
      };
    case "REQUEST_UPDATE_ACCOUNT":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case "UPDATE_ACCOUNT_ERROR":
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
      };
    case "SET_NEW_TOKEN":
      let newState = {
        ...state,
        token: action.payload.token,
        otpDeviceId: action.payload.otpDeviceId,
      };
      if (process.env.REACT_APP_2FA_ENABLED === "false") {
        newState["otpDeviceId"] = "development_device_id";
      }
      return newState;
    case "REQUEST_OTP_VERIFICATION":
      return {
        ...state,
        loading: true,
      };
    case "OTP_VERIFICATION_SUCCESS":
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        otpDeviceId: action.payload.otpDeviceId,
      };
    case "OTP_VERIFICATION_ERROR":
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
