import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useAxios } from "hooks";

import PublicationCard from "./PublicationCard";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

// Add backend query to get publication by id/pk instead of doing this
const MATCH_ALL = /* GraphQL */ `
  {
    allPublicationDocuments(filter: { extractSuccessful: { value: true } }) {
      edges {
        node {
          pk
          id
          title
          abstract
          authors {
            givenName
            surname
          }
          classifications {
            topic
            rank
          }
          keywords
          extractSuccessful
        }
      }
    }
  }
`;

export default function Publication() {
  let { id } = useParams();
  const api = useAxios();
  const classes = useStyles();
  const [publication, setPublication] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", {
        query: MATCH_ALL,
      })
      .then((res) => {
        const data = res.data.data.allPublicationDocuments.edges;

        data.forEach((pub) => {
          if (pub.node.pk === Number(id)) {
            //idk
            setPublication(pub.node);
          }
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (id === null) {
    return <div>No publication with that ID exists</div>;
  }

  //This isnt consistent with the others, should be passing an object, but leaving it for now
  return (
    <PublicationCard
      pk={publication.pk}
      key={publication.id}
      title={publication.title}
      abstract={publication.abstract}
      authors={publication.authors}
      classifications={publication.classifications}
      keywords={publication.keywords}
      highlight={publication.highlight}
      viewButton={false}
      viewDetails={true}
    />
  );
}
