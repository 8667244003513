import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikTextField from "components/shared/formik/FormikTextField";
import FormikLabMultiple from "components/shared/formik/FormikLabMultiple";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Redirect } from "react-router-dom";

import { useAuthState, useAuthDispatch, updateAccount } from "../../context";
import { Overview } from "../overview";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflowY: "scroll",
  },
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    width: "80%",
    flexDirection: "column",
    paddingTop: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    minWidth: "80%",
  },
  instructions: {
    margin: theme.spacing(1),
  },
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardContent: {
    minHeight: "210px",
  },
  stepper: {
    borderRadius: "5px",
  },
  alert: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
    minWidth: "80%",
    "& .MuiAlert-message": {
      paddingTop: theme.spacing(1),
      width: "85%",
    },
  },
  interestsExample: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    minWidth: "80%",
  },
}));

const arrToObj = (arr) => {
  const obj = {};

  arr.map((e, i) => (obj[i] = e));

  return JSON.stringify(obj);
};

const UPDATE_ACCOUNT = /* GraphQL */ `
  mutation UpdateAccount(
    $firstName: String
    $lastName: String
    $organization: String
    $requestReason: String
    $bio: String
    $interests: JSONString
    $onboardingComplete: Boolean
  ) {
    updateAccount(
      firstName: $firstName
      lastName: $lastName
      organization: $organization
      requestReason: $requestReason
      bio: $bio
      interests: $interests
      onboardingComplete: $onboardingComplete
    ) {
      success
      errors
    }
  }
`;

// const skillsOptions = ["Programming", "Debugging"];
const interestsOptions = []; //["Hiking", "Kayaking"];

function getSteps() {
  return ["Welcome", "Bio", "Interests", "Overview"];
}

const noopSchema0 = Yup.object({
  val: Yup.string("no op"),
});

const bioSchema = Yup.object({
  bio: Yup.string("Enter bio").required("Bio is required"),
});

// const skillsSchema = Yup.object({
//   skills: Yup.array()
//     .of(Yup.string())
//     .required()
//     .min(1, "Enter at least one skill"),
// });

const interestsSchema = Yup.object({
  interests: Yup.array()
    .of(Yup.string())
    .required()
    .min(1, "Enter at least one interest"),
});

const noopSchema4 = Yup.object({
  val: Yup.string("no op"),
});

const schemaArray = [noopSchema0, bioSchema, interestsSchema, noopSchema4];

function FormikStepper({ children, handleRedirect }) {
  const classes = useStyles();
  const { token, refreshToken, user } = useAuthState();
  const dispatch = useAuthDispatch();
  const childrenArray = React.Children.toArray(children);
  const [activeStep, setActiveStep] = useState(0);
  const currentChild = childrenArray[activeStep];
  //const [hasError, setHasError] = useState(false); // TODO figure out where to put an error message

  const steps = getSteps();

  const handleNext = (isValid, submitForm, validateForm, setTouched) => {
    submitForm().then(() => {
      if (isValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        validateForm();
        setTouched({});
      }
    });
  };

  const handleBack = (validateForm, setTouched) => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const { bio, interests } = values;
    const arrInterests = arrToObj(interests);

    try {
      let response = await updateAccount(
        dispatch,
        { token, refreshToken },
        {
          query: UPDATE_ACCOUNT,
          variables: {
            ...user,
            bio,
            interests: arrInterests,
            onboardingComplete: true,
          },
        }
      );
      if (response.success) {
        handleRedirect();
      }
    } catch (error) {
      console.log(error);
      // setHasError(error);
    }
  };

  function isLastStep() {
    return activeStep === childrenArray.length - 1;
  }

  return (
    <Formik
      initialValues={{
        bio: "",
        interests: [],
      }}
      validationSchema={schemaArray[activeStep]}
      onSubmit={(values, { setSubmitting }) => {
        isLastStep()
          ? handleSubmit(values, setSubmitting)
          : setSubmitting(false);
      }}
      enableReinitialize
    >
      {({ isSubmitting, isValid, submitForm, validateForm, setTouched }) => (
        <Form>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {currentChild}
            </CardContent>

            <CardActions>
              <Button
                disabled={activeStep === 0 || isSubmitting}
                onClick={() => handleBack(validateForm, setTouched)}
                className={classes.button}
              >
                Back
              </Button>

              {isLastStep() && (
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24.5} />
                  ) : (
                    "Try Named Entity Recognition"
                  )}
                </Button>
              )}
              {!isLastStep() && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleNext(isValid, submitForm, validateForm, setTouched)
                  }
                  className={classes.button}
                >
                  Next
                </Button>
              )}
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default function Onboarding() {
  const classes = useStyles();
  const { user } = useAuthState();
  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    setRedirect(true);
  };

  if (redirect === true) {
    // setRedirect(false);
    return (
      <Redirect
        to={{
          pathname: "/tools",
          state: { onboardingRedirect: true },
        }}
      />
    );
  }

  return (
    <Modal open={!user.onboardingComplete} className={classes.modal}>
      <div>
        <Container className={classes.root}>
          <FormikStepper handleRedirect={handleRedirect}>
            <Typography className={classes.instructions}>
              Welcome to EWARE! This is an application under development to
              compile energetics resources and provide them to our users. The
              underlying system is built to integrate cutting edge AI/ML tools
              for test and validation for the larger energetics community.
              <br />
              <br />
              Please engage with the application and provide feedback on
              usability and requested functionality.
            </Typography>
            <div>
              <Typography className={classes.instructions}>
                Please tell us about yourself in a short biographical
                statements. This will be used to develop our energetics
                knowledge recommendation system and understand our community
                better.{" "}
              </Typography>
              <div display="flex">
                <FormikTextField
                  name="bio"
                  variant="outlined"
                  label="Enter Bio"
                  multiline
                  rows={4}
                  onKeyPress={(e) => {
                    e.which === 13 && e.preventDefault();
                  }}
                  className={classes.formControl}
                  helperText
                />
              </div>
            </div>
            {/* <div>
              <Typography className={classes.instructions}>
                Skills - Please share a few of your skills in the box below.
                This will help us improve the recommendations in the tool and
                provide you with the most relevant info! Try to keep the skills
                relatively short...a few examples are "mechanical engineering -
                thermodynamics" or "test engineering - underwater explosives" or
                "software developer - explosive simulation test development."
              </Typography>
              <FormikLabMultiple
                id="skills"
                name="skills"
                label="Enter skills"
                className={classes.formControl}
                options={skillsOptions}
                helperText
              />
            </div> */}
            <div>
              <Typography className={classes.instructions}>
                Please tell us some of your energetics interests. This will be
                used to keyword match while the underlying AI/ML tools are in
                development.
                <br />
                <br />
                Please hit "Enter" after each interest as some interests may
                contain a comma.
              </Typography>
              <Alert className={classes.alert}>
                Correct:
                <Autocomplete
                  multiple
                  freeSolo
                  disabled
                  fullWidth
                  className={classes.interestsExample}
                  id="interests-example-correct"
                  value={[
                    "RDX",
                    "New Energetic Materials",
                    "CL-20",
                    "Torpedo Nitration",
                    "1,3,5-Trinitro-1,3,5-triazinane",
                  ]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      disabled
                      label="Enter interests"
                    />
                  )}
                />
              </Alert>
              <Alert severity="error" className={classes.alert}>
                Incorrect:
                <TextField
                  id="interests-example-incorrect"
                  disabled
                  fullWidth
                  className={classes.interestsExample}
                  label="Enter interests"
                  value="RDX, New Energetic Materials, CL-20, Torpedo Nitration, 1,3,5-Trinitro-1,3,5-triazinane"
                  variant="outlined"
                />
              </Alert>
              <FormikLabMultiple
                id="interests"
                name="interests"
                label="Enter interests"
                className={classes.formControl}
                options={interestsOptions}
                helperText
              />
            </div>
            <>
              <Typography className={classes.instructions} gutterBottom>
                Below is a description of the currently implemented features as
                they appear in the application's sidebar.
              </Typography>
              <Typography className={classes.instructions}>
                These descriptions are also available to review by pressing the
                help button located at the top right of the toolbar.
              </Typography>
              <Overview />
            </>
          </FormikStepper>
        </Container>
      </div>
    </Modal>
  );
}
