import React, { useState, Fragment } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

import useStyles from "../styles";
import { UPDATE_ACCOUNT } from "../gql";
import { useAuthState, useAuthDispatch, updateAccount } from "../../../context";
import Display from "./Display";
import FormElements from "./FormElements";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// TODO pull this into a util
const arrToObj = (arr) => {
  const obj = {};

  arr.map((e, i) => (obj[i] = e));

  return JSON.stringify(obj);
};

// TODO pull this into a util
const objToArr = (obj) => {
  const keys = Object.keys(obj);
  const res = [];
  for (let i = 0; i < keys.length; i++) {
    res.push(obj[keys[i]]);
  }
  return res;
};

export default function Interests() {
  const classes = useStyles();
  const { token, refreshToken, user } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [hasError, setHasError] = useState(false);
  const interests = objToArr(JSON.parse(user.interests));

  const handleCancel = (resetForm) => {
    resetForm();
    setIsEditing(false);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const { interests } = values;
    const arrInterests = arrToObj(interests);

    try {
      let response = await updateAccount(
        dispatch,
        { token, refreshToken },
        {
          query: UPDATE_ACCOUNT,
          variables: {
            ...user,
            interests: arrInterests,
          },
        }
      );
      console.log(response);
      setOpenAlert(true);
      setSubmitting(false);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
      setHasError(true);
      setOpenAlert(true);
      setSubmitting(false);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setHasError(false);
    setOpenAlert(false);
  };

  return (
    <Card className={classes.card}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        {hasError ? (
          <Alert onClose={handleCloseAlert} severity="error">
            An error occured, please try again
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success">
            Profile updated!
          </Alert>
        )}
      </Snackbar>
      <Formik
        initialValues={{
          interests: interests,
        }}
        validationSchema={Yup.object({
          interests: Yup.array()
            .of(Yup.string())
            .required()
            .min(1, "Enter at least one interest"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        enableReinitialize
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            {isEditing ? (
              <Fragment>
                <CardActions className={classes.cardActions}>
                  {isSubmitting ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Fragment>
                      <IconButton
                        aria-label="cancel-edit-interests"
                        size="small"
                        onClick={() => handleCancel(resetForm)}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        aria-label="submit-edit-interests"
                        size="small"
                        type="submit"
                      >
                        <CheckIcon />
                      </IconButton>
                    </Fragment>
                  )}
                </CardActions>
                <CardContent className={classes.cardContent}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Interests
                  </Typography>

                  <FormElements />
                </CardContent>
              </Fragment>
            ) : (
              <Fragment>
                <CardActions className={classes.cardActions}>
                  <IconButton
                    aria-label="edit-interests"
                    size="small"
                    onClick={() => setIsEditing(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </CardActions>
                <CardContent className={classes.cardContent}>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Interests
                  </Typography>
                  <Display interests={interests} />
                </CardContent>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Card>
  );
}
