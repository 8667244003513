import React, { useState, useMemo, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import QRCode from "react-qr-code";

import FormikTextField from "components/shared/formik/FormikTextField";
import { useAxios } from "hooks";
import { useAuthDispatch, useAuthState, otpVerify } from "context";

import * as DEVICES from "./constants/devices";

const GET_USER_TOTP_DEVICE = /* GraphQL */ `
  {
    userTotpDevice {
      confirmed
      url
    }
  }
`;

const GET_USER_EMAIL_OTP_DEVICE = /* GraphQL */ `
  {
    userEmailOtpDevice {
      confirmed
    }
  }
`;

const OTP_VERIFY = /* GraphQL */ `
  mutation OtpVerify($otp: String!, $deviceType: OTPDeviceEnum) {
    otpVerify(otp: $otp, deviceType: $deviceType) {
      success
      errors
      token
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    borderStyle: "solid",
    borderRadius: "5px",
    borderWidth: "1px",
    backgroundColor: "transparent",
    borderColor: theme.palette.primary.light,
    marginBottom: theme.spacing(1),
    textAlign: "center",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      width: "100%",
      textAlign: "center",
      padding: theme.spacing(1.125),
      margin: 0,
    },
  },
  rootChecked: {
    backgroundColor: "rgba(121, 134, 203, 0.3)",
    borderColor: theme.palette.primary.main,
    borderWidth: "2px",
  },
  radioGroup: {
    marginBottom: theme.spacing(2),
  },
  info: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  workflowField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  resendButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function getSteps() {
  return {
    [DEVICES.TYPES.TOTPDEVICE]: ["Register", "Device Verification"],
    [DEVICES.TYPES.EMAILDEVICE]: ["Email Verification"],
  };
}

const selectedWorkflowSchema = Yup.object({
  selectedWorkflow: Yup.string().required(),
});

const schemaArray = [selectedWorkflowSchema];

const noopSchema = Yup.object({
  val: Yup.string("no op"),
});

const verificationCodeSchema = Yup.object({
  verificationCode: Yup.string()
    .required("Enter the 6-digit verification code found on your device")
    .matches(
      /^[0-9]+$/,
      "Your verification code will only contain numerical values"
    )
    .min(6, "Your verification code will have exactly 6 digits")
    .max(6, "Your verification code will have exactly 6 digits"),
});

const totpWorkflowSchemaArray = [noopSchema, verificationCodeSchema];

const emailWorkflowSchemaArray = [verificationCodeSchema];

function getWorkflowSchemas() {
  return {
    [DEVICES.TYPES.TOTPDEVICE]: totpWorkflowSchemaArray,
    [DEVICES.TYPES.EMAILDEVICE]: emailWorkflowSchemaArray,
  };
}

function Skipper() {
  return <></>;
}

function FormikStepper({ open, handleClose, setSelectedWorkflow, children }) {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const [hasError, setHasError] = useState(false);

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const currentChild = childrenArray[activeStep];
  const [schema, setSchema] = useState(schemaArray);

  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const { state: previousLocation } = useLocation();

  const renderErrorMessages = (errorMessage) => {
    let alert = null;
    if (errorMessage.code === "invalid_otp") {
      alert = (
        <Alert severity="error" className={classes.alert}>
          Invalid verification code, please try again.
        </Alert>
      );
    } else {
      alert = (
        <Alert severity="error" className={classes.alert}>
          An error occured, please try again.
        </Alert>
      );
    }
    return alert;
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return !isFirstStep() && activeStep === childrenArray.length - 1;
  };

  const steps = getSteps();

  const handleNext = (
    selectedWorkflow,
    isValid,
    submitForm,
    validateForm,
    setTouched,
    verificationCode
  ) => {
    submitForm().then(() => {
      if (isValid) {
        if (isFirstStep()) {
          setSelectedWorkflow(selectedWorkflow);
          setSchema((prevSchema) =>
            prevSchema.concat(getWorkflowSchemas()[selectedWorkflow])
          );
        }
        if (isLastStep()) {
          const payload = {
            query: OTP_VERIFY,
            variables: { otp: verificationCode, deviceType: selectedWorkflow },
          };

          otpVerify(dispatch, state, payload).then((data) => {
            if (data.success) {
              setIsOtpVerified(true);
            } else {
              setHasError(true);
            }
          });
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        validateForm();
        setTouched({});
      }
    });
  };

  const handleBack = (validateForm, setTouched) => {
    setHasError(false);
    if (activeStep === 1) {
      setSchema(schemaArray);
    }
    if (childrenArray[1].type === Skipper) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    validateForm();
    setTouched({});
  };

  if (isOtpVerified === true) {
    return <Redirect to={previousLocation?.from || "/"} />;
  }

  if (currentChild.type === Skipper) {
    setActiveStep(childrenArray.length - 1);
  }

  return (
    <Formik
      initialValues={{
        selectedWorkflow: DEVICES.TYPES.TOTPDEVICE,
        verificationCode: "",
      }}
      validationSchema={schema[activeStep]}
      onSubmit={(values, { setSubmitting }) => {
        !isLastStep() && setSubmitting(false);
      }}
      enableReinitialize
    >
      {({
        values,
        isSubmitting,
        isValid,
        submitForm,
        validateForm,
        setTouched,
      }) => (
        <Form>
          <Dialog open={open} fullWidth>
            <DialogTitle id="two-factor-dialog-title" disableTypography>
              <Typography variant="h6">Two-factor authentication</Typography>
              {activeStep > 0 && (
                <Stepper activeStep={activeStep - 1}>
                  {steps[values.selectedWorkflow].map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </DialogTitle>
            <DialogContent dividers>
              {hasError &&
                state.errorMessage &&
                renderErrorMessages(state.errorMessage)}
              {currentChild}
              {activeStep === 0 && (
                <>
                  <Divider variant="middle" />
                  <Typography align="center" className={classes.info}>
                    {values.selectedWorkflow === DEVICES.TYPES.TOTPDEVICE
                      ? "Use the 6-digit verification code on your device"
                      : "You will receive a 6-digit verification code by email."}
                  </Typography>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {isFirstStep() ? (
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
              ) : (
                <Button
                  onClick={() => handleBack(validateForm, setTouched)}
                  color="primary"
                >
                  Back
                </Button>
              )}

              <Button
                onClick={() => {
                  handleNext(
                    values.selectedWorkflow,
                    isValid,
                    submitForm,
                    validateForm,
                    setTouched,
                    values.verificationCode
                  );
                }}
                color="primary"
              >
                {isLastStep() ? "Submit" : "Next"}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}

function QRCode_(props) {
  const classes = useStyles();
  const { value } = props;
  const qrCode = useMemo(() => <QRCode value={value} />, [value]);
  const secret = useMemo(() => {
    let params = value.split("?")[1];
    let queryString = new URLSearchParams(params);

    return queryString.get("secret").replace(/.{4}/g, "$& "); // split into chunks of 4 for readability
  }, [value]);

  return (
    <>
      <Typography align="center" className={classes.info}>
        Open your authenticator app and scan this QR code.
      </Typography>
      <div className={classes.workflowField}>{qrCode}</div>
      <Typography align="center">
        Or enter the following code manually:
      </Typography>
      <Typography align="center">
        <b>{secret}</b>
      </Typography>
      <Typography align="center" className={classes.info}>
        Once EWARE is registered, you will start seeing 6-digit verification
        codes in the app.
      </Typography>
    </>
  );
}

function VerificationCodeField(props) {
  const api = useAxios();
  const classes = useStyles();

  const [resendDisabled, setResendDisabled] = useState(false);

  const test = () => {
    api.post("/graphql", {
      query: GET_USER_EMAIL_OTP_DEVICE,
    });

    setResendDisabled(true);
    setTimeout(() => setResendDisabled(false), 30000); // 30 seconds
  };

  return (
    <>
      <Typography align="center" className={classes.info}>
        {props.prompt}
      </Typography>
      <div className={classes.workflowField}>
        <FormikTextField
          key="1"
          name="verificationCode"
          label="6-digit code"
          variant="outlined"
          helperText
        />
      </div>
      {props.allowResend && (
        <div className={classes.resendButton}>
          <Button
            variant="outlined"
            color="primary"
            onClick={test}
            disabled={resendDisabled}
          >
            Resend Email
          </Button>
        </div>
      )}
    </>
  );
}

export default function TwoFactorAuthForm(props) {
  const api = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [workflow, setWorkflow] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    switch (selectedWorkflow) {
      case DEVICES.TYPES.TOTPDEVICE:
        setIsLoading(true);
        api
          .post("/graphql", {
            query: GET_USER_TOTP_DEVICE,
          })
          .then((res) => {
            const data = res.data.data;

            // If the user's device is confirmed
            // we skip the device registration step.
            if (data.userTotpDevice.confirmed) {
              setWorkflow([
                <Skipper key="0" />,
                <VerificationCodeField
                  key="1"
                  prompt={"Enter the code from your authenticator app."}
                />,
              ]);
            } else {
              setWorkflow([
                <QRCode_ key="0" value={data.userTotpDevice.url} />,
                <VerificationCodeField
                  key="1"
                  prompt={"Enter the code from your authenticator app."}
                />,
              ]);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });

        break;
      case DEVICES.TYPES.EMAILDEVICE:
        setIsLoading(true);
        api
          .post("/graphql", {
            query: GET_USER_EMAIL_OTP_DEVICE,
          })
          .then((res) => {
            const data = res.data.data;

            setWorkflow([
              <VerificationCodeField
                key="0"
                prompt={"Check your inbox to retrieve your verification code."}
                allowResend={true}
              />,
            ]);

            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
        break;
      default:
        break;
    }
  }, [selectedWorkflow]);

  return (
    <FormikStepper {...props} setSelectedWorkflow={setSelectedWorkflow}>
      <Field name="selectedWorkflow">
        {({ field }) => (
          <>
            <Typography variant="h5" align="center" gutterBottom>
              Choose Verification Method
            </Typography>
            <RadioGroup
              {...field}
              aria-label="otp-type-radio-group"
              className={classes.radioGroup}
            >
              <FormControlLabel
                value={DEVICES.TYPES.TOTPDEVICE}
                align="center"
                control={<Radio />}
                textAlign="center"
                className={clsx(classes.root, {
                  [classes.rootChecked]:
                    field.value === DEVICES.TYPES.TOTPDEVICE,
                })}
                label="Device Authentication (recommended)"
              />
              <FormControlLabel
                value={DEVICES.TYPES.EMAILDEVICE}
                control={<Radio />}
                className={clsx(classes.root, {
                  [classes.rootChecked]:
                    field.value === DEVICES.TYPES.EMAILDEVICE,
                })}
                label="Email Authentication"
              />
            </RadioGroup>
          </>
        )}
      </Field>
      {isLoading ? <p>Loading...</p> : workflow}
    </FormikStepper>
  );
}
