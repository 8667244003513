import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link as RouterLink, useParams, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import FormikTextField from "../../shared/formik/FormikTextField";
import { API } from "api";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
    maxWidth: 260,
  },
}));

const PASSWORD_RESET = /* GraphQL */ `
  mutation PasswordReset(
    $token: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    passwordReset(
      token: $token
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;

export default function PasswordResetForm() {
  const classes = useStyles();
  let { token } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordReset, setPasswordReset] = useState({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const { token, password, passwordConfirmation } = values;
    API.post("/graphql", {
      query: PASSWORD_RESET,
      variables: {
        token,
        newPassword1: password,
        newPassword2: passwordConfirmation,
      },
    })
      .then((res) => {
        console.log(res);
        setPasswordReset(res.data.data.passwordReset);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  if (passwordReset.success === true) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { passwordResetSuccess: true },
        }}
      />
    );
  }

  return (
    <Formik
      initialValues={{
        token: token,
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(8, "Password should be 8 characters minimum.")
          .required("Password is required"),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Passwords must match"
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container direction="column" alignItems="center">
            <FormikTextField
              label="New password"
              name="password"
              type={showPassword ? "text" : "password"}
              className={classes.formControl}
              variant="outlined"
              helperText
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormikTextField
              label="Confirm password"
              name="passwordConfirmation"
              type={showPassword ? "text" : "password"}
              className={classes.formControl}
              variant="outlined"
              helperText
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              className={classes.formControl}
              type="submit"
            >
              {isSubmitting ? (
                <CircularProgress size={24.5} />
              ) : (
                "Reset Password"
              )}
            </Button>

            <Grid item>
              <Button
                to="/login"
                component={RouterLink}
                color="primary"
                className={classes.formControl}
              >
                Back to Login
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
