import Ruth from "../../static/transcripts/ruth.pdf";
import Mike from "../../static/transcripts/mike.pdf";
import Henric from "../../static/transcripts/henric.pdf";
import Jimmie from "../../static/transcripts/jimmie.pdf";
import Phil from "../../static/transcripts/phil.pdf";
import Somnath from "../../static/transcripts/somnath.pdf";
import Al from "../../static/transcripts/al.pdf";
import Bryce from "../../static/transcripts/bryce.pdf";
import Craig from "../../static/transcripts/craig.pdf";
import Clifford from "../../static/transcripts/clifford.pdf";

const data = {
  transcripts: [
    {
      id: 0,
      doc: {
        file: Ruth,
        name: "Ruth Doherty Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "05/09/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "37%",
        },
        {
          name: "Ruth Doherty",
          engagement: "43%",
        },
        {
          name: "Peter Chung",
          engagement: "20%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 1,
      doc: {
        file: Mike,
        name: "Dr. Mike Kramer Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "12/20/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "20%",
        },
        {
          name: "Mike Kramer",
          engagement: "55%",
        },
        {
          name: "Ruth Doherty",
          engagement: "25%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 2,
      doc: {
        file: Henric,
        name: "Henric Östmark Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "07/17/2018",
      },
      participants: [
        {
          name: "Ruth Doherty",
          engagement: "39%",
        },
        {
          name: "Henric Östmark",
          engagement: "50%",
        },
        {
          name: "Bill Wilson",
          engagement: "11%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 3,
      doc: {
        file: Jimmie,
        name: "Jimmie Oxley Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "07/18/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "25%",
        },
        {
          name: "Jimmie Oxley",
          engagement: "25%",
        },
        {
          name: "Jerry Forbes",
          engagement: "25%",
        },
        {
          name: "Ruth Doherty",
          engagement: "25%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 4,
      doc: {
        file: Phil,
        name: "Phil Pagoria Interview Transcript",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "01/09/2019",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "33%",
        },
        {
          name: "Phil Pagoria",
          engagement: "33%",
        },
        {
          name: "Jerry Forbes",
          engagement: "33%",
        },
        {
          name: "Ruth Doherty",
          engagement: "1%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 5,
      doc: {
        file: Somnath,
        name: "Dr. Somnath Sengupta Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "07/11/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "45%",
        },
        {
          name: "Somnath Sengupta",
          engagement: "45%",
        },
        {
          name: "Jerry Forbes",
          engagement: "5%",
        },
        {
          name: "Peter Chung",
          engagement: "5%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 6,
      doc: {
        file: Al,
        name: "Dr. Al Stern Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "06/25/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "50%",
        },
        {
          name: "Al Stern",
          engagement: "50%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 7,
      doc: {
        file: Bryce,
        name: "Bryce Tappan Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "07/18/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "25%",
        },
        {
          name: "Bryce Tappan",
          engagement: "50%",
        },
        {
          name: "Ruth Doherty",
          engagement: "15%",
        },
        {
          name: "Jerry Forbes",
          engagement: "10%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 8,
      doc: {
        file: Craig,
        name: "Craig Tarver Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "07/17/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "50%",
        },
        {
          name: "Craig Tarver",
          engagement: "50%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
    {
      id: 9,
      doc: {
        file: Clifford,
        name: "Dr. Clifford Bedford Interview",
        docType: ".docx",
        characters: "31459",
        words: "2718",
        pages: "42",
        date: "12/11/2018",
      },
      participants: [
        {
          name: "Bill Wilson",
          engagement: "15%",
        },
        {
          name: "Clifford Bedford",
          engagement: "80%",
        },
        {
          name: "Jerry Forbes",
          engagement: "2.5%",
        },
        {
          name: "Ruth Doherty",
          engagement: "2.5%",
        },
      ],
      keyHighlights: "Example",
      summary: "Example",
      keywords: [
        "Unmanned",
        "Chemistry",
        "Army SBIR",
        "test1",
        "test2",
        "test3",
      ],
    },
  ],
};

export default data;
