import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { useAxios } from "hooks";

import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

const FETCH_NOTE_COMMENTS = /* GraphQL */ `
  query($id: String!) {
    noteById(id: $id) {
      comments {
        content
        createdOn
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

const FETCH_EWAVE_COMMENTS = /* GraphQL */ `
  query($id: String!) {
    ewaveById(id: $id) {
      comments {
        content
        createdOn
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

const FETCH_EVENT_REPORT_COMMENTS = /* GraphQL */ `
  query($id: String!) {
    eventReportById(id: $id) {
      comments {
        content
        createdOn
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0.5),
  },
}));

export default function Comments(props) {
  const api = useAxios();
  const classes = useStyles();
  const { contentType, objectId } = props;
  const [comments, setComments] = useState(props.comments);
  const [updateComments, setUpdateComments] = useState(false);

  useEffect(() => {
    // TODO refactor
    function selectCommentQuery(contentType) {
      switch (contentType) {
        case "NOTE":
          return FETCH_NOTE_COMMENTS;
        case "EWAVE":
          return FETCH_EWAVE_COMMENTS;
        case "EVENT_REPORT":
          return FETCH_EVENT_REPORT_COMMENTS;
      }
    }

    // TODO refactor
    function selectResultSet(contentType) {
      switch (contentType) {
        case "NOTE":
          return "noteById";
        case "EWAVE":
          return "ewaveById";
        case "EVENT_REPORT":
          return "eventReportById";
      }
    }

    if (updateComments) {
      api
        .post("/graphql", {
          query: selectCommentQuery(contentType),
          variables: {
            id: objectId,
          },
        })
        .then((res) => {
          const data = res.data.data;

          setComments(data[selectResultSet(contentType)].comments);
          setUpdateComments(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [updateComments]);

  return (
    <Grid direction="column" container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        <CommentForm
          contentType={contentType}
          objectId={objectId}
          setUpdateComments={setUpdateComments}
        />
      </Grid>
      <Grid item xs={12}>
        <CommentList comments={comments} />
      </Grid>
    </Grid>
  );
}
