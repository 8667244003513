import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchResultList from "./PublicationSearchResultList";

import { useAxios } from "hooks";

const GET_PUBLICATIONS = /* GraphQL */ `
  {
    allPublications {
      id
      title
    }
  }
`;

const MATCH_ALL = /* GraphQL */ `
  {
    allPublicationDocuments(filter: { extractSuccessful: { value: true } }) {
      edges {
        node {
          pk
          id
          title
          abstract
          authors {
            givenName
            surname
          }
          classifications {
            topic
            rank
          }
          keywords
          extractSuccessful
        }
      }
    }
  }
`;

const SEARCH_PUBLICATIONS = /* GraphQL */ `
  query($query: String) {
    allPublicationDocuments(
      filter: { extractSuccessful: { value: true } }
      search: { query: $query }
      highlight: [title]
    ) {
      edges {
        node {
          pk
          id
          title
          abstract
          authors {
            givenName
            surname
          }
          classifications {
            topic
            rank
          }
          keywords
          highlight
          extractSuccessful
        }
      }
    }
  }
`;

export default function Search() {
  const api = useAxios();
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const [hits, setHits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let match = useRouteMatch();

  useEffect(() => {
    api
      .post("/graphql", {
        query: GET_PUBLICATIONS,
      })
      .then((res) => {
        const data = res.data.data;
        setSuggestions(data.allPublications);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (query) {
      api
        .post("/graphql", {
          query: SEARCH_PUBLICATIONS,
          variables: { query },
        })
        .then((res) => {
          const data = res.data.data;

          setHits(data.allPublicationDocuments.edges);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      api
        .post("/graphql", {
          query: MATCH_ALL,
        })
        .then((res) => {
          const data = res.data.data;

          setHits(data.allPublicationDocuments.edges);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div style={{ width: 600 }}>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            freeSolo
            options={suggestions.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search input"
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: "search" }}
                onChange={(e) => setQuery(e.target.value)}
              />
            )}
          />
        </form>
      </div>
      <SearchResultList hits={hits} />
    </>
  );
}
