import React from "react";
import { Route, Switch } from "react-router";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import EventReportForm from "./EventReportForm";
import EventReportView from "./EventReportDetails";
import AllEventReports from "./AllEventReports";

const useStyles = makeStyles((theme) => ({}));

export default function EventReports() {
  const classes = useStyles();
  let match = useRouteMatch();

  return (
    <>
      <Typography variant="h5">Event Reports</Typography>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={match.url}
          >
            New Event
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`${match.url}/all`}
          >
            All Events
          </Button>
        </Grid>
      </Grid>
      <Switch>
        <Route path={`${match.path}/all`}>
          <AllEventReports />
        </Route>
        <Route path={`${match.path}/:id`}>
          <EventReportView />
        </Route>
        <Route path={match.path}>
          <EventReportForm />
        </Route>
      </Switch>
    </>
  );
}
