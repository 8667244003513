import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { useAxios } from "hooks";

import NoteCard from "./NoteCard";

const GET_NOTES = /* GraphQL */ `
  {
    allNoteDocuments(ordering: { entryDate: DESC }) {
      edges {
        node {
          id
          pk
          title
          whyItMatters
          topic
          interests
          details
          url
          references
          entryDate
          enteredBy {
            email
            firstName
            lastName
          }
          comments {
            createdOn
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  outercard: {
    "& .MuiPaper-root": {
      borderRadius: 0,
    },
  },
}));

export default function AllNotes() {
  const api = useAxios();
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    api
      .post("/graphql", { query: GET_NOTES })
      .then((res) => {
        const data = res.data.data;

        setNotes(data.allNoteDocuments.edges);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (notes.length === 0) {
    return <div>Unable to load notes...</div>;
  }

  return (
    <Grid container spacing={3}>
      {notes.map(({ node: note }, index) => (
        <Grid className={classes.outercard} item xs={12} xl={6}>
          <NoteCard
            key={note.id}
            note={note}
            showCommentButton={true}
            viewButton={true}
          />
        </Grid>
      ))}
    </Grid>
  );
}
