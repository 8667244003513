import React from "react";
import useStyles from "../styles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

export default function Display({ interests }) {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid item className={classes.chipArray}>
        {interests.map((interest, key) => {
          return (
            <Chip
              key={key}
              label={interest}
              onClick={() => console.log("clicked")}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
