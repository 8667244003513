import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import PublicationCard from "./PublicationCard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SearchResultList(props) {
  const classes = useStyles();

  const { hits } = props;

  console.log(hits);

  return (
    <Grid container spacing={3}>
      {hits.map(({ node: hit }, i) => (
        <Grid item xs={12}>
          <PublicationCard
            pk={hit.pk}
            key={hit.id}
            title={hit.title}
            abstract={hit.abstract}
            authors={hit.authors}
            classifications={hit.classifications}
            keywords={hit.keywords}
            divider={hits.length !== i + 1}
            highlight={hit.highlight}
            viewButtons={true}
          />
        </Grid>
      ))}
    </Grid>
  );
}
