import Typography from "@material-ui/core/Typography";

const MAX_ATTACHMENT_SIZE = 25000000;

export default function MaxAttachmentWarning(props) {
  const { files, warning, ...rest } = props;

  const limitExceeded = Array.isArray(files)
    ? files.reduce((prev, curr) => prev + curr.size, 0) > MAX_ATTACHMENT_SIZE
    : files?.size > MAX_ATTACHMENT_SIZE;

  if (limitExceeded) {
    return (
      <Typography {...rest} variant="subtitle2" color="error">
        {warning}
      </Typography>
    );
  }

  return null;
}

MaxAttachmentWarning.defaultProps = {
  warning:
    "The files you have selected exceed the 25MB email limit. Attachments will be saved, but will not be included in email notifications.",
};
