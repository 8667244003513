import { Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";

import TranscriptView from "./TranscriptDetails";
import AllTranscripts from "./AllTranscripts";

export default function Search() {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <TranscriptView />
      </Route>
      <Route path={match.path}>
        <AllTranscripts />
      </Route>
    </Switch>
  );
}
