import { useState } from "react";
import { FieldArray } from "formik";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import useStyles from "./styles";
import FormikTextField from "components/shared/formik/FormikTextField";
import FormikLabDropdown from "components/shared/formik/FormikLabDropdown";

const additionalDetailTypeOptions = ["Observation", "Way ahead", "Other"];

export default function AdditionalDetailsForm({ ...props }) {
  const classes = useStyles();
  const [editingLabel, setEditingLabel] = useState(false);

  const resetDetailLabel = (index, setFieldValue) => {
    setFieldValue(`additionalDetails.${index}.label`, "");
    setEditingLabel(false);
  };

  return (
    <FieldArray
      name="additionalDetails"
      render={(arrayHelpers) => (
        <>
          {props.values.additionalDetails.map((detail, index) => (
            <Card key={index} className={classes.card}>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item container>
                    {editingLabel ? (
                      <>
                        <FormikTextField
                          label="Detail Label"
                          name={`additionalDetails.${index}.label`}
                          variant="outlined"
                          helperText
                        />
                        <IconButton
                          aria-label="edit-detail-name"
                          size="small"
                          onClick={() =>
                            resetDetailLabel(index, props.setFieldValue)
                          }
                          style={{ paddingLeft: "10px" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          gutterRight
                        >
                          <em>Unlabeled Detail</em>
                        </Typography>
                        <IconButton
                          aria-label="edit-detail-name"
                          size="small"
                          onClick={() => setEditingLabel(true)}
                          style={{ paddingLeft: "10px" }}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    )}

                    <IconButton
                      aria-label="remove-detail"
                      size="small"
                      className={classes.right}
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>

                  <FormikLabDropdown
                    label="Detail Type"
                    name={`additionalDetails.${index}.type`}
                    variant="outlined"
                    className={classes.formControl}
                    options={additionalDetailTypeOptions}
                    helperText
                  />
                  <FormikTextField
                    label="Detail"
                    name={`additionalDetails.${index}.detail`}
                    variant="outlined"
                    multiline
                    rows={4}
                    className={classes.formControl}
                    helperText
                  />
                </Grid>
              </CardContent>
            </Card>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="default"
              aria-label="add a detail"
              endIcon={<AddIcon />}
              className={classes.button}
              onClick={() =>
                arrayHelpers.push({
                  label: "",
                  type: "",
                  detail: "",
                })
              }
            >
              Add Additional Details
            </Button>
          </Grid>
        </>
      )}
    />
  );
}
