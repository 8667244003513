import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, Tooltip } from "@material-ui/core";

import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import NoteIcon from "@material-ui/icons/Note";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FlightIcon from "@material-ui/icons/Flight";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import BookIcon from "@material-ui/icons/Book";

import DetailsDialog from "../shared/DetailsDialog";
import PdfViewerModal from "./PdfViewerModal";

// Like buttons
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import ThumbDownIcon from "@material-ui/icons/ThumbDown";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: "#707070",
  },
  iconStyle: {
    maxWidth: "2.5rem",
    fill: "rgba(112, 112, 112, 0.8)",
    justifyContent: "space-between",
    alignItems: "right",
  },
  contentPad: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  buttonBox: {
    paddingTop: " 10px",
    display: "flex",
    justifyContent: "space-between",
  },
  spanPad: {
    padding: "0px 5px 0px 5px",
    fontWeight: "bold",
  },
  likeButtonPad: {
    transform: "translateY(0.3rem)",
    color: "rgb(112, 112, 112)",
  },
  upLikeColor: {
    color: "rgb(0, 166, 0)",
  },
  downLikeColor: {
    color: "rgb(255, 52, 39)",
  },
  genButtonPad: {
    marginLeft: "2px",
    marginRight: "2px",
  },
  commentButtonPad: {
    color: "rgb(112, 112, 112)",
    marginRight: "4px",
  },
}));

export default function EwaveCard({
  children,
  content,
  idx,
  showCommentButton,
}) {
  const classes = useStyles();

  const iconResolver = (itemStr) => {
    let rtnComponent = <></>;

    switch (itemStr) {
      case "note":
        rtnComponent = <NoteIcon className={classes.iconStyle} />;
        break;
      case "ewave":
        rtnComponent = <NoteIcon className={classes.iconStyle} />;
        break;
      case "publication":
        rtnComponent = <BookIcon className={classes.iconStyle} />;
        break;
      case "event report":
        rtnComponent = <FlightIcon className={classes.iconStyle} />;
        break;
      case "transcript":
        rtnComponent = <AssignmentIcon className={classes.iconStyle} />;
        break;
      case "article":
        rtnComponent = <LibraryBooksIcon className={classes.iconStyle} />;
        break;
      case "knowledge":
        rtnComponent = <AccountBalanceIcon className={classes.iconStyle} />;
        break;
      default:
        break;
    }

    return (
      <Tooltip title={itemStr[0].toUpperCase() + itemStr.slice(1)}>
        {rtnComponent}
      </Tooltip>
    );
  };

  //probably not necessary might remove later
  const linkPath = (type) => {
    switch (type) {
      case "note":
        return "notes";
      case "ewave":
        return "notes/ewaves";
      case "event report":
        return "events";
      case "publication":
        return "search";
      case "transcript":
        return "transcripts";
      default:
        return null;
    }
  };

  // For like buttons - these don't really do anything though
  // const upClick = (e) => {
  //   console.log("(mock up) upliked")
  //   e.currentTarget.style.color = 'rgb(88, 114, 255)';
  // }

  // const downClick = (e, value) => {
  //   console.log("(mock up) downliked")
  //   e.currentTarget.style.color = 'rgb(88, 114, 255)';
  // }
  // const exampleLikes = {upLikes: 3, downLikes: 1};

  const buttonGenerator = (btnData, type, targetId) => {
    //Could maybe simplify this?
    switch (btnData.type) {
      case "edit":
        return (
          <Button
            variant="outlined"
            color="secondary"
            className={classes.genButtonPad}
            component={RouterLink}
            to={`/${linkPath(type)}/${targetId}/edit`}
          >
            Edit
          </Button>
        );
      case "view":
        return (
          <Button
            variant="outlined"
            color="primary"
            className={classes.genButtonPad}
            component={RouterLink}
            to={`/${linkPath(type)}/${targetId}`}
          >
            View
          </Button>
        );
      case "details":
        return (
          <DetailsDialog
            title={btnData.title}
            abstract={btnData.abstract}
            authors={btnData.authors}
            classifications={btnData.classifications}
            keywords={btnData.keywords}
          />
        );
      case "media":
        switch (btnData.datatype) {
          case "pdf":
            return <PdfViewerModal title={btnData.title} file={btnData.file} />;
          case "transcript":
            return (
              <PdfViewerModal
                title={btnData.title}
                file={btnData.file}
                buttonLabel="View Transcript"
              />
            );
          case "presentation":
            return (
              <PdfViewerModal
                title={btnData.title}
                file={btnData.file}
                buttonLabel="View Presentation"
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  return (
    <Card key={idx}>
      <CardContent>
        <Box className={classes.buttonBox}>
          <Typography variant="h5">{content.title}</Typography>
          <div>{iconResolver(content.type)}</div>
        </Box>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {content.subtitle}
        </Typography>
        <div className={classes.contentPad}>{children}</div>
        {((Array.isArray(content.buttons) && content.buttons.length > 0) ||
          showCommentButton) && ( //Only show bottom section if this data has buttons, comments (or likes, add that in if uncommented below)
          <>
            <Divider />
            <Box className={classes.buttonBox}>
              <div>
                {content.buttons?.map((btn) => {
                  if (btn.show) {
                    return buttonGenerator(btn, content.type, content.targetId);
                  }
                  return null;
                })}
              </div>
              {
                <div>
                  {showCommentButton && ( //If this type has a comment section
                    <Tooltip title="Comments">
                      <Button
                        component={RouterLink}
                        to={
                          "/" + linkPath(content.type) + "/" + content.targetId
                        }
                      >
                        <ChatBubbleOutlineIcon
                          className={classes.commentButtonPad}
                        />
                        <strong>{content.numComments}</strong>
                      </Button>
                    </Tooltip>
                  )}
                  {/* <Tooltip title="Like"> //This can be done better maybe with a grid
                <ThumbUpIcon
                  className={classes.likeButtonPad}
                  data-value={false}
                  onClick={upClick}
                />
              </Tooltip>
              <span className={classes.spanPad}>
                <span className={classes.upLikeColor}>
                  +{exampleLikes.upLikes}
                </span>{" "}
                /{" "}
                <span className={classes.downLikeColor}>
                  -{exampleLikes.downLikes}
                </span>
              </span>
              <Tooltip title="Dislike">
                <ThumbDownIcon
                  className={classes.likeButtonPad}
                  value={false}
                  onClick={downClick}
                />
              </Tooltip> */}
                </div>
              }
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
}
