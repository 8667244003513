import { useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import EntityTable from "./EntityTable";
import EntityVisualizer from "./EntityVisualizer";
import NamedEntityRecognitionForm from "./NamedEntityRecognitionForm";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(-2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
}));

const nerModelDefault = "eNER_0722";

const nerModelOptions = [nerModelDefault];

export default function NamedEntityRecognition() {
  const classes = useStyles();
  const { state } = useLocation();
  const [ner, setNer] = useState({ text: "", entities: [] });
  const [nerModel, setNerModel] = useState(nerModelDefault);
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <Grid container direction="column" spacing={3}>
      {ner.entities.length === 0 && ner.text.length > 0 && (
        <Grid item>
          <Alert severity="info" className={classes.alert}>
            No entities were identified for the supplied text.
          </Alert>
        </Grid>
      )}
      <Grid item>
        <Autocomplete
          disabled
          value={nerModel}
          autoHighlight
          disableClearable
          id="nerModel"
          className={classes.formControl}
          options={nerModelOptions}
          getOptionLabel={(option) => option}
          onChange={(e, value) => {
            setNerModel(value);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                label="Select NER Model"
                name="nerModel"
              />
            );
          }}
        />
      </Grid>
      <Grid item>
        <NamedEntityRecognitionForm
          setNer={setNer}
          onboardingRedirect={state?.onboardingRedirect}
        />
      </Grid>
      {!!ner.entities.length && (
        <>
          <Grid item>
            <EntityVisualizer ner={ner} />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Switch checked={collapsed} onChange={handleCollapse} />}
              label="Show Entity Data"
              //className={classes.right}
            />
            <Collapse in={collapsed}>
              <EntityTable entities={ner.entities} />{" "}
            </Collapse>
          </Grid>
        </>
      )}
    </Grid>
  );
}
