import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import React, { useState, Component } from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

import PdfViewerModal from "../shared/PdfViewerModal";

const useStyles = makeStyles((theme) => ({
  chip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(0.5),
  },
  pdf: {
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  description: {
    whiteSpace: "pre-line",
  },
  descriptionClamped: {
    whiteSpace: "pre-line",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function VideoPlayer(props) {
  const classes = useStyles();
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const toggleClamp = () => {
    setDetailsExpanded(!detailsExpanded);
  };

  const { name, src, description, component, pdf, pdf2, tags } = props;

  return (
    <Card>
      <CardMedia controls component={component} src={src} />

      {/* <CardActions disableSpacing>
        <Tooltip title="Like">
          <IconButton style={{ color: green[500] }}>
            <ThumbUpIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Dislike">
          <IconButton color="secondary">
            <ThumbDownIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Tag">
          <IconButton className={classes.right}>
            <AccountBalanceIcon />
          </IconButton>
        </Tooltip>
        
        <Tooltip title="Favorite">
          <IconButton>
            <StarOutlineIcon />
          </IconButton>
        </Tooltip>
      </CardActions>

       */}

      <CardActions disableSpacing>
        <div className={classes.pdf}>
          {pdf && (
            <PdfViewerModal
              className={classes.right}
              title={name}
              buttonLabel="View Transcript"
              file={pdf}
            />
          )}
          {pdf2 && (
            <PdfViewerModal
              title={name}
              buttonLabel="View Presentation"
              file={pdf2}
            />
          )}
        </div>
      </CardActions>

      <Divider variant="middle" light />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          Description
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={clsx({
            [classes.descriptionClamped]: !detailsExpanded,
            [classes.description]: detailsExpanded,
          })}
          gutterBottom
        >
          {description}
        </Typography>
        {detailsExpanded &&
          tags.map((tag) => <Chip className={classes.chip} label={tag} />)}
      </CardContent>

      <CardActions disableSpacing>
        <Button
          color="default"
          variant="outlined"
          size="small"
          onClick={toggleClamp}
        >
          {detailsExpanded ? "Show Less" : "Show More"}
        </Button>
      </CardActions>
    </Card>
  );
}
