import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import { Route, Switch } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import Search from "./search";
import UploadComponent from "./UploadComponent";
import NoteComponent from "./notes/NoteComponent";
import KnowledgePage from "./knowledge-base/KnowledgePage";
import Transcripts from "./transcripts";
import NewsPage from "./news/NewsPage";
import EventReports from "./event-reports";
import MenuAppBar from "./MenuAppBar";
import Dashboard from "./dashboard";
import Profile from "./profile";
import Tools from "./tools";
import Events from "./event-calendar";
import Notifications from "./notifications";

import ListRouter from "./routing/ListRouter";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#f6f8fa",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  menuButton: {
    marginRight: 32,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function ClippedDrawer() {
  const classes = useStyles();
  const [open, toggleOpen] = useState(true);

  return (
    <div className={classes.root}>
      <MenuAppBar>
        <IconButton
          color="inherit"
          aria-label="toggle drawer"
          onClick={() => toggleOpen(!open)}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
      </MenuAppBar>
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Toolbar />
        <ListRouter />
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Switch>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/notes">
            <NoteComponent />
          </Route>
          <Route path="/upload">
            <UploadComponent />
          </Route>
          <Route path="/calendar">
            <Events />
          </Route>
          <Route path="/events">
            <EventReports />
          </Route>
          <Route path="/transcripts">
            <Transcripts />
          </Route>
          <Route path="/knowledge">
            <KnowledgePage />
          </Route>
          <Route path="/news">
            <NewsPage />
          </Route>
          <Route path="/tools">
            <Tools />
          </Route>
          <Route path="/notifications">
            <Notifications />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
