import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  axis: {
    "& .tick": {
      "& line": {
        stroke: "#EBEBEB",
      },
    },
  },
}));

function renderAxis(axisRef, axis) {
  const node = d3.select(axisRef.current);
  node.call(axis).select(".domain").remove();
}

function Axis({ h, axis, axisType, axisLabel }) {
  const classes = useStyles();
  const axisRef = useRef(null);
  const translate = `translate(0, ${h})`;

  useEffect(() => {
    renderAxis(axisRef, axis);
  }, [axis]);

  return (
    <g
      ref={axisRef}
      className={classes.axis}
      transform={axisType === "x" ? translate : ""}
    />
  );
}

Axis.propTypes = {
  h: PropTypes.number,
  axis: PropTypes.func,
  axisType: PropTypes.oneOf(["x", "y"]),
};

export default Axis;
