import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";

import { useAxios } from "hooks";

import useStyles from "./styles";
import FormikLabDropdown from "components/shared/formik/FormikLabDropdown";
import FormikLabMultiple from "components/shared/formik/FormikLabMultiple";
import FormikTextField from "components/shared/formik/FormikTextField";
import PresentationForm from "./PresentationForm";
import ConversationForm from "./ConversationForm";
import AdditionalDetailsForm from "./AdditionalDetailsForm";
import FormikFreesoloDropdown from "components/shared/formik/FormikFreesoloDropdown";
import MaxAttachmentWarning from "components/shared/MaxAttachmentWarning";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GET_EVENTS = /* GraphQL */ `
  {
    allEventDocuments {
      edges {
        node {
          title
        }
      }
    }
  }
`;

const CREATE_EVENT_REPORT = /* GraphQL */ `
  mutation CreateEventReport(
    $eventName: String!
    $eventType: String!
    $startDate: Date!
    $endDate: Date!
    $eventDistribution: String!
    $reportDistribution: String!
    $mainTakeaway: String!
    $keywords: JSONString!
    $agenda: Upload
    $attendees: Upload
    $images: [Upload]
    $presentations: [PresentationInput]
    $conversations: [ConversationInput]
    $additionalDetails: [AdditionalDetailInput]
  ) {
    createEventReport(
      eventName: $eventName
      eventType: $eventType
      startDate: $startDate
      endDate: $endDate
      eventDistribution: $eventDistribution
      reportDistribution: $reportDistribution
      mainTakeaway: $mainTakeaway
      keywords: $keywords
      agenda: $agenda
      attendees: $attendees
      images: $images
      presentations: $presentations
      conversations: $conversations
      additionalDetails: $additionalDetails
    ) {
      success
    }
  }
`;

const parseEventType = (eventType) => {
  switch (eventType) {
    case "Conference":
      return "C";
    case "Presentation":
      return "P";
    case "Temporary Duty":
      return "T";
    case "Site Visit":
      return "S";
    default:
      return "O";
  }
};

const parseDetailType = (detailType) => {
  switch (detailType) {
    case "Observation":
      return "OB";
    case "Way ahead":
      return "WA";
    default:
      return "OT";
  }
};

const eventTypeOptions = [
  "Conference",
  "Presentation",
  "Temporary Duty",
  "Site Visit",
  "Other",
];

const eventTypeDefault = eventTypeOptions[0]; // Conference

const distributionDefault = "A";

const distributionOptions = ["A", "B", "C", "D"];

const keywordsOptions = [];

export default function EventReportForm() {
  const api = useAxios();
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", { query: GET_EVENTS })
      .then((res) => {
        const data = res.data.data;
        const nodes = data.allEventDocuments.edges.map(
          (edge) => edge.node.title
        );

        setEvents(nodes);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const [openAlert, setOpenAlert] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setHasError(false);
    setOpenAlert(false);
  };

  return (
    <Formik
      initialValues={{
        eventType: eventTypeDefault,
        eventName: "",
        startDate: "",
        endDate: "",
        eventDistribution: distributionDefault,
        reportDistribution: distributionDefault,
        mainTakeaway: "",
        keywords: [],
        eventAgendaFile: null,
        eventAttendeesFile: null,
        eventImageFiles: [],
        presentations: [],
        conversations: [],
        additionalDetails: [],
      }}
      validateOnChange={false}
      validationSchema={Yup.object({
        eventType: Yup.string("Enter event type").required(
          "Event type is required"
        ),
        eventName: Yup.string("Enter event name")
          .max(200, "Max 200 characters")
          .required("Event name is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .min(Yup.ref("startDate"), "End Date can't be before Start Date.")
          .required("End date is required"),
        eventDistribution: Yup.string().required(
          "Event distribution is required"
        ),
        reportDistribution: Yup.string().required(
          "Report distribution is required"
        ),
        mainTakeaway: Yup.string("Enter main event takeaway").required(
          "Main event takeaway is required"
        ),
        keywords: Yup.array()
          .of(Yup.string())
          .required()
          .min(1, "Enter at least one keyword"),
        eventAgendaFile: Yup.mixed(),
        eventAttendeesFile: Yup.mixed(),
        eventImageFiles: Yup.mixed(),
        presentations: Yup.array().of(
          Yup.object({
            presenterName: Yup.string("Enter the presenter's full name")
              .max(300, "Max 300 characters")
              .required("Presenter's name is required"),
            presenterOrg: Yup.string("Enter the presenter's organization")
              .max(200, "Max 200 characters")
              .required("Organization is required"),
            title: Yup.string("Enter the presentation title")
              .max(200, "Max 200 characters")
              .required("Presentation title is required"),
            takeaways: Yup.array().of(Yup.object({ takeaway: Yup.string() })),
            presentation: Yup.mixed(),
            image: Yup.mixed(),
          })
        ),
        conversations: Yup.array().of(
          Yup.object({
            participants: Yup.array()
              .of(
                Yup.object({
                  fullName: Yup.string("Enter the participant's full name")
                    .max(300, "Max 300 characters")
                    .required("Participant's name is required"),
                  organization: Yup.string(
                    "Enter the participant's organization"
                  )
                    .max(200, "Max 200 characters")
                    .required("Participant's organization is required"),
                  jobTitle: Yup.string("Enter the participant's job title")
                    .max(200, "Max 200 characters")
                    .required("Participant's job title is required"),
                })
              )
              .min(1, "Enter at least one participant"),
            discussion: Yup.string("Enter the discussion").required(
              "Discussion is required"
            ),
            file: Yup.mixed(),
          })
        ),
        additionalDetails: Yup.array().of(
          Yup.object({
            label: Yup.string("Enter a label for this detail").max(
              200,
              "Max 200 characters"
            ),
            type: Yup.string("Enter the detail type").required(
              "Detail type is required"
            ),
            detail: Yup.string("Enter additional detail").required(
              "Detail is required"
            ),
          })
        ),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const {
          eventName,
          eventType,
          startDate,
          endDate,
          eventDistribution,
          reportDistribution,
          mainTakeaway,
          keywords,
          eventAgendaFile,
          eventAttendeesFile,
          eventImageFiles,
          presentations,
          conversations,
          additionalDetails,
        } = values;

        const formData = new FormData();

        const map = {};
        if (eventAgendaFile !== null) {
          map[0] = ["variables.agenda"];

          formData.append("0", eventAgendaFile);
        }

        if (eventAttendeesFile !== null) {
          map[1] = ["variables.attendees"];

          formData.append("1", eventAttendeesFile);
        }

        eventImageFiles.forEach((f, index) => {
          let key = `ei${index}`;
          map[key] = [`variables.images.${index}`];
          formData.append(`${key}`, f);
        });

        let cleanPresentations = _.cloneDeep(presentations);

        cleanPresentations.forEach((p, index) => {
          if (p.presentation !== null) {
            const key = `pp${index}`;
            map[key] = [`variables.presentations.${index}.presentation`];

            formData.append(key, p.presentation);
            p.presentation = null;
          }

          p.images.forEach((f, idx) => {
            const key = `p-${index}-i-${idx}`;
            map[key] = [`variables.presentations.${index}.images.${idx}`];

            formData.append(key, f);
            p.images[idx] = null;
          });
        });

        let cleanConversations = _.cloneDeep(conversations);

        cleanConversations.forEach((c, index) => {
          c.files.forEach((f, idx) => {
            const key = `c-${index}-f-${idx}`;
            map[key] = [`variables.conversations.${index}.files.${idx}`];

            formData.append(key, f);
            c.files[idx] = null;
          });
        });

        let cleanDetails = _.cloneDeep(additionalDetails);

        cleanDetails.forEach((d) => {
          if (d.label === "") {
            d.label = "Unlabeled";
          }
          d.type = parseDetailType(d.type);
        });

        if (Object.entries(map).length !== 0) {
          formData.append("map", JSON.stringify(map));
        }

        formData.append(
          "operations",
          JSON.stringify({
            query: CREATE_EVENT_REPORT,
            variables: {
              eventName,
              eventType: parseEventType(eventType),
              startDate,
              endDate,
              eventDistribution,
              reportDistribution,
              mainTakeaway,
              keywords: JSON.stringify(keywords),
              agenda: null,
              attendees: null,
              images: new Array(
                eventImageFiles ? Object.entries(eventImageFiles).length : 0
              ).fill(null),
              presentations: cleanPresentations,
              conversations: cleanConversations,
              additionalDetails: cleanDetails,
            },
          })
        );

        api
          .post("/graphql", formData)
          .then((res) => {
            console.log(res);
            const data = res.data.data;
            if (data.createEventReport.success) {
              resetForm();
            } else {
              setHasError(true);
            }
            setOpenAlert(true);
            setSubmitting(false);
          })
          .catch((err) => {
            console.log(err);
            setHasError(true);
            setOpenAlert(true);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={openAlert}
            onClose={handleCloseAlert}
            autoHideDuration={6000}
          >
            {hasError ? (
              <Alert onClose={handleCloseAlert} severity="error">
                An error occured, please try again
              </Alert>
            ) : (
              <Alert onClose={handleCloseAlert} severity="success">
                Event report created!
              </Alert>
            )}
          </Snackbar>
          <Card className={classes.card}>
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    Event Details
                  </Typography>
                </Grid>
                <FormikLabDropdown
                  id="eventType"
                  label="Event Type"
                  name="eventType"
                  variant="outlined"
                  disabled
                  className={classes.formControl}
                  options={eventTypeOptions}
                  getOptionSelected={(option, value) => {
                    return option === value || value === "";
                  }}
                  helperText
                />
                <FormikFreesoloDropdown
                  id="eventName"
                  label="Event Name"
                  name="eventName"
                  variant="outlined"
                  className={classes.formControl}
                  options={events}
                  getOptionSelected={(option, value) => {
                    return option === value || value === "";
                  }}
                  helperText
                />
                <Grid item container spacing={2}>
                  <FormikTextField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    variant="outlined"
                    className={classes.formControl}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText
                  />
                  <FormikTextField
                    label="End Date"
                    name="endDate"
                    type="date"
                    variant="outlined"
                    className={classes.formControl}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText
                  />
                  <FormikLabDropdown
                    id="eventDistribution"
                    label="Event Distribution"
                    name="eventDistribution"
                    variant="outlined"
                    disabled
                    className={classes.formControl}
                    options={distributionOptions}
                    helperText
                    disableClearable
                  />
                  <FormikLabDropdown
                    id="reportDistribution"
                    label="Report Distribution"
                    name="reportDistribution"
                    variant="outlined"
                    disabled
                    className={classes.formControl}
                    options={distributionOptions}
                    helperText
                    disableClearable
                  />
                </Grid>

                <Grid item>
                  <InputLabel htmlFor="event-main-takeaway-input">
                    What was the most important takeaway from this event? This
                    should include any significant information or relevant
                    technologies.
                  </InputLabel>
                </Grid>
                <FormikTextField
                  id="event-main-takeaway-input"
                  placeholder="Main Event Takeaway"
                  name="mainTakeaway"
                  variant="outlined"
                  multiline
                  rows={10}
                  className={classes.formControl}
                  style={{ marginTop: "0px" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText
                />
                <FormikLabMultiple
                  name="keywords"
                  label="Event Keywords (people, topics, institutions)"
                  className={classes.formControl}
                  options={keywordsOptions}
                  getOptionSelected={(option, value) => {
                    console.log("option: " + option);
                    console.log("value: " + value);
                    return option === value;
                  }}
                  helperText
                />
                <Grid container item direction="row" spacing={2}>
                  <Grid item>
                    <input
                      id="event-agenda-file"
                      className={classes.fileInput}
                      type="file"
                      name="eventAgendaFile"
                      onChange={(event) => {
                        setFieldValue(
                          "eventAgendaFile",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <label htmlFor="event-agenda-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Upload Agenda
                      </Button>
                    </label>
                  </Grid>
                  <Grid item>
                    <input
                      id="event-attendees-file"
                      className={classes.fileInput}
                      type="file"
                      name="eventAttendeesFile"
                      onChange={(event) => {
                        setFieldValue(
                          "eventAttendeesFile",
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <label htmlFor="event-attendees-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Upload Attendees
                      </Button>
                    </label>
                  </Grid>
                  <Grid item>
                    <input
                      id="event-image-files"
                      accept="image/*"
                      className={classes.fileInput}
                      type="file"
                      name="eventImageFiles"
                      multiple
                      onChange={(event) => {
                        setFieldValue("eventImageFiles", [
                          ...event.currentTarget.files,
                        ]);
                      }}
                    />
                    <label htmlFor="event-image-files">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Upload Images
                      </Button>
                    </label>
                  </Grid>
                </Grid>
                <List dense={true}>
                  {values.eventAgendaFile && (
                    <ListItem>
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Agenda - ${values.eventAgendaFile.name}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete-agenda-file"
                          onClick={() => setFieldValue("eventAgendaFile", null)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {values.eventAttendeesFile && (
                    <ListItem>
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Attendees - ${values.eventAttendeesFile.name}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete-attendees-file"
                          onClick={() =>
                            setFieldValue("eventAttendeesFile", null)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {values.eventImageFiles.map((img, index) => (
                    <ListItem key={`img-${index}`}>
                      <ListItemIcon>
                        <AttachFileIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Image - ${img.name}`} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label={`delete-image-file-${index}`}
                          onClick={() => {
                            values.eventImageFiles.splice(index, 1);
                            setFieldValue(
                              `eventImageFiles`,
                              values.eventImageFiles
                            );
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <MaxAttachmentWarning
                  className={classes.formControl}
                  files={values.eventAgendaFile}
                />
              </Grid>
            </CardContent>
          </Card>
          <PresentationForm values={values} setFieldValue={setFieldValue} />
          <ConversationForm values={values} setFieldValue={setFieldValue} />
          <AdditionalDetailsForm
            values={values}
            setFieldValue={setFieldValue}
          />
          <Fab
            color="primary"
            variant="extended"
            type="submit"
            disabled={isSubmitting}
            className={classes.fab}
          >
            {isSubmitting ? <CircularProgress size={24.5} /> : "Submit Report"}
          </Fab>
        </Form>
      )}
    </Formik>
  );
}
