import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthState } from "context/auth";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import EwareCard from "../shared/EwareCard";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  whiteSpace: {
    whiteSpace: "pre-line",
  },
}));

export default function EwaveCard({ ewave, showCommentButton, viewButton }) {
  const classes = useStyles();
  const commentCount = ewave.comments.length;
  const {
    user: { email },
  } = useAuthState();

  if (!ewave.id && !ewave.pk) {
    console.log("NO KEY!!!!");
  }

  const nameCheck = (author) => {
    let tmpName = author.firstName + " " + author.lastName;
    return tmpName.trim().length < 1 ? "EWARE Internal" : tmpName;
  };

  //pulled from impact_to_string in backend - /ewave/models.py
  //this is set to a readable value in the all ewaves query but not in the ewave by id query, front end bandaid fix
  const impactToString = (impact) => {
    switch (impact) {
      case "A_1":
        return "1 - High Impact";
      case "A_2":
        return "2 - Medium Impact";
      case "A_3":
        return "3 - Low Impact";
      default:
        return impact;
    }
  };

  const cardContent = {
    title: ewave.title,
    type: "ewave",
    targetId: ewave.pk || ewave.id,
    numComments: commentCount,
    buttons: viewButton && [
      {
        type: "view",
        show: true,
      },
    ],
  };

  return (
    <EwareCard
      content={cardContent}
      idx={ewave.id || ewave.pk}
      showCommentButton={showCommentButton}
    >
      <Grid container>
        {" "}
        {/* entire card internals grid */}
        <Grid item sm container>
          {" "}
          {/* left side internals grid */}
          <Grid item sm container direction="column">
            {" "}
            {/* left side internals grid */}
            <Grid item>
              <Typography component="span" color="textSecondary">
                Impact:{" "}
              </Typography>
              <Typography component="span">
                {impactToString(ewave.impact)}
              </Typography>{" "}
            </Grid>
            <Grid item>
              <Typography component="span" className={classes.whiteSpace}>
                {ewave.body}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignContent="space-around"
        >
          {" "}
          {/* right side internals grid */}
          <Grid item>
            <Typography component="span" color="textSecondary">
              Date:{" "}
            </Typography>
            <Typography component="span">
              {moment(ewave.pubDate).format("MMM Do, YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" color="textSecondary">
              Entered by:{" "}
            </Typography>
            <Typography component="span">{nameCheck(ewave.author)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </EwareCard>
  );
}
