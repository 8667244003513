import React, { useState, Fragment } from "react";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

import useStyles from "../styles";
import { UPDATE_ACCOUNT } from "../gql";
import { useAuthState, useAuthDispatch, updateAccount } from "../../../context";
import Display from "./Display";
import FormElements from "./FormElements";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Account() {
  const classes = useStyles();
  const { token, refreshToken, user } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { firstName, lastName, organization } = user;

  const handleCancel = (resetForm) => {
    resetForm();
    setIsEditing(false);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const { firstName, lastName, organization } = values;

    try {
      let response = await updateAccount(
        dispatch,
        { token, refreshToken },
        {
          query: UPDATE_ACCOUNT,
          variables: {
            ...user,
            firstName,
            lastName,
            organization,
          },
        }
      );
      console.log(response);
      setOpenAlert(true);
      setSubmitting(false);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
      setHasError(true);
      setOpenAlert(true);
      setSubmitting(false);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setHasError(false);
    setOpenAlert(false);
  };

  return (
    <Card className={classes.card}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        {hasError ? (
          <Alert onClose={handleCloseAlert} severity="error">
            An error occured, please try again
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success">
            Profile updated!
          </Alert>
        )}
      </Snackbar>
      <Formik
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          organization: organization,
        }}
        validationSchema={Yup.object({
          firstName: Yup.string("Enter first name").required(
            "First name is required"
          ),
          lastName: Yup.string("Enter last name").required(
            "Last name is required"
          ),
          organization: Yup.string("Enter organization").required(
            "Organization is required"
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values, setSubmitting);
        }}
        enableReinitialize
      >
        {({ isSubmitting, resetForm }) => (
          <Form>
            {isEditing ? (
              <Fragment>
                <CardActions className={classes.cardActions}>
                  {isSubmitting ? (
                    <CircularProgress size={30} />
                  ) : (
                    <Fragment>
                      <IconButton
                        aria-label="cancel-edit-account"
                        size="small"
                        onClick={() => handleCancel(resetForm)}
                      >
                        <ClearIcon />
                      </IconButton>
                      <IconButton
                        aria-label="submit-edit-account"
                        size="small"
                        type="submit"
                      >
                        <CheckIcon />
                      </IconButton>
                    </Fragment>
                  )}
                </CardActions>
                <CardContent className={classes.cardContent}>
                  <FormElements />
                </CardContent>
              </Fragment>
            ) : (
              <Fragment>
                <CardActions className={classes.cardActions}>
                  <IconButton
                    aria-label="edit-account"
                    size="small"
                    onClick={() => setIsEditing(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </CardActions>
                <CardContent className={classes.cardContent}>
                  <Display
                    firstName={firstName}
                    lastName={lastName}
                    organization={organization}
                  />
                </CardContent>
              </Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Card>
  );
}
