import React from "react";

import useStyles from "../styles";
import FormikTextField from "components/shared/formik/FormikTextField";

export default function FormElements() {
  const classes = useStyles();
  return (
    <FormikTextField
      name="bio"
      variant="outlined"
      label="Enter Bio"
      multiline
      fullWidth
      rows={4}
      onKeyPress={(e) => {
        e.which === 13 && e.preventDefault();
      }}
      className={classes.formControl}
      helperText
    />
  );
}
