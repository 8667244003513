import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import reactStringReplace from "react-string-replace";

import EwareCard from "../shared/EwareCard";

const constructAuthors = (authors) => {
  return (
    <em>
      {authors.map((author, i) => {
        var fullName = author.givenName + " " + author.surname;
        if (fullName === " ") {
          return "";
        } else if (authors.length !== i + 1) {
          fullName += ", ";
        }
        return fullName;
      })}
    </em>
  );
};

const parseClassification = (classifications) => {
  return classifications.reduce((prev, cur) =>
    cur.rank > prev.rank ? cur : prev
  );
};

const stringReplace = (highlight, type) => {
  return reactStringReplace(highlight[type], /<\/?b>/g, (match, i) => (
    <Box
      key={i}
      style={{ backgroundColor: "#FFFF00" }}
      display="inline"
      component="span"
    >
      {match}
    </Box>
  ));
};

const generateHighlight = (highlight, text, type) => {
  let title = text;
  if (title.length < 1) title = "Untitled Publication";
  return highlight && highlight[type] ? stringReplace(highlight, type) : title;
};

const generateClassArray = (classifications) => {
  let tmpAry = [];
  classifications.forEach((c) => {
    tmpAry.push(Math.trunc(c.rank * 100) + "% " + c.topic);
  });
  console.log(tmpAry);
  return tmpAry;
};

export default function PublicationCard(props) {
  const authors = constructAuthors(props.authors);
  const classification = parseClassification(props.classifications);
  const title = generateHighlight(props.highlight, props.title, "title");
  const abstract = generateHighlight(
    props.highlight,
    props.abstract,
    "abstract"
  );
  const allClassifications = generateClassArray(props.classifications);

  const keywords = props.keywords;
  console.log(props.keywords);

  const cardContent = {
    title: title,
    type: "publication",
    targetId: props.pk,
    buttons: props.viewButtons && [
      {
        type: "view",
        show: true,
      },
    ],
  };

  return (
    <EwareCard content={cardContent} idx={props.pk} showCommentButton={false}>
      <Grid container>
        {/* entire card internals grid */}
        <Grid item container direction="column">
          {/* left side internals grid */}
          <Grid item>
            <Typography component="span" color="textSecondary">
              Abstract:{" "}
            </Typography>
            <Typography component="span">{abstract}</Typography>
          </Grid>
          <Grid item>
            <Typography component="span" color="textSecondary">
              Classification:{" "}
            </Typography>
            <Typography component="span">
              {Math.trunc(classification.rank * 100)}% {classification.topic}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignContent="space-around"
        >
          {/* right side internals grid */}
          {authors.length > 0 && (
            <Grid item>
              <Typography component="span" color="textSecondary">
                Author(s):{" "}
              </Typography>
              <Typography component="span">{authors}</Typography>
            </Grid>
          )}
          {props.viewDetails && (
            <Grid container>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  All Classifications:{" "}
                </Typography>
                {allClassifications.map((c, i) => (
                  <Grid item>
                    <Typography component="span" color="textSecondary">
                      {c}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <Typography component="span" color="textSecondary">
                  Keywords:{" "}
                </Typography>
                <Typography component="span">{keywords}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </EwareCard>
  );
}
