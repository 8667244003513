import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import { loginUser, useAuthDispatch, useAuthState } from "context";
import FormikTextField from "../../shared/formik/FormikTextField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
    maxWidth: 260,
  },
  alert: {
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

const LOGIN = /* GraphQL */ `
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      success
      errors
      token
      refreshToken
      user {
        firstName
        lastName
        email
        organization
        requestReason
        username
        onboardingComplete
        skills
        interests
        bio
        isStaff
      }
    }
  }
`;

export default function LoginForm(props) {
  const { handleLoggedIn } = props;

  const classes = useStyles();
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const dispatch = useAuthDispatch();
  const { errorMessage, expirationMessage } = useAuthState();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleErrorCode = (errorCode) => {
    switch (errorCode) {
      case "not_approved":
        return "Your account is not approved. You will receive an email when the approval process is complete.";
      case "not_verified":
        return (
          <Redirect
            to={{ pathname: "/activate", state: { notVerified: true } }}
          />
        );
      case "invalid_credentials":
      default:
        return "Incorrect username or password.";
    }
  };

  const renderErrorMessages = (errorMessage) => {
    if (errorMessage?.nonFieldErrors) {
      return errorMessage.nonFieldErrors.map((error) => (
        <Grid item>
          <Alert severity="error" className={classes.alert}>
            {handleErrorCode(error.code)}
          </Alert>
        </Grid>
      ));
    }
    if (errorMessage?.isAxiosError) {
      return (
        <Grid item>
          <Alert severity="error" className={classes.alert}>
            {handleErrorCode()}
          </Alert>
        </Grid>
      );
    }
    return <></>;
  };

  const handleLogin = async (values, setSubmitting) => {
    const { email, password } = values;
    try {
      let response = await loginUser(dispatch, {
        query: LOGIN,
        variables: {
          email,
          password,
        },
      });
      setSubmitting(false);
      if (!response.user) return;
      setLoggedIn(true);
      if (process.env.REACT_APP_2FA_ENABLED === "true") {
        console.log("opening 2fa window");
        handleLoggedIn();
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  if (isLoggedIn === true) {
    if (process.env.REACT_APP_2FA_ENABLED === "false") {
      return <Redirect to={state?.from || "/"} />;
    }
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string("Enter email"),
        password: Yup.string("Enter password"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleLogin(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container direction="column" alignItems="center">
            {renderErrorMessages(errorMessage)}
            {expirationMessage && (
              <Grid item>
                <Alert severity="warning" className={classes.alert}>
                  {expirationMessage}
                </Alert>
              </Grid>
            )}
            {!errorMessage && state?.registrationSuccess === true && (
              <Grid item>
                <Alert className={classes.alert}>
                  Registration successful, check your inbox for a verification
                  email.
                </Alert>
              </Grid>
            )}
            {!errorMessage && state?.passwordResetSuccess === true && (
              <Grid item>
                <Alert className={classes.alert}>
                  Password reset successful.
                </Alert>
              </Grid>
            )}
            {!errorMessage && state?.verifyAccountSuccess === true && (
              <Grid item>
                <Alert className={classes.alert}>
                  Your account has been verified. You will receive an email when
                  your account has been approved.
                </Alert>
              </Grid>
            )}
            <Grid item>
              <FormikTextField
                label="Email"
                name="email"
                className={classes.formControl}
                variant="outlined"
              />
            </Grid>

            <Grid item>
              <FormikTextField
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                className={classes.formControl}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.formControl}
                type="submit"
              >
                {isSubmitting ? <CircularProgress size={24.5} /> : "SIGN IN"}
              </Button>
            </Grid>

            <Grid item>
              <Button
                to="/password-reset"
                component={RouterLink}
                color="primary"
                className={classes.formControl}
              >
                Forgot password?
              </Button>

              <Button
                to="/registration"
                component={RouterLink}
                color="primary"
                className={classes.formControl}
              >
                Request an account
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
