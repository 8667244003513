import React, { useEffect } from "react";

import { useAuthState, useAuthDispatch, expireSession } from "../context";

export default function AuthVerify() {
  const dispatch = useAuthDispatch();
  const { sessionExpirationDate } = useAuthState();

  useEffect(() => {
    const timeToExpire = sessionExpirationDate - new Date();
    const sessionExpirationTimer = setTimeout(() => {
      console.log("expiring!");
      expireSession(dispatch);
    }, timeToExpire);

    return () => clearTimeout(sessionExpirationTimer);
  }, []);

  return <></>;
}
