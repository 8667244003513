import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default function KBIntroduction() {
  const classes = useStyles();

  return (
    <Typography variant="body2" component="p">
      This page will walk you through the suggested educational resources
      (articles, short courses, videos, and more to help you learn about
      energetics and the field of studies related to it. The content is curated
      by SMEs in this community and inputs are crowdsourced from around the US
      and ally nations. The program will recommend an educational path to you
      based on your profile and / will track your progress as you continue to
      grow and develop. Please make sure to provide feedback on each asset so
      that we can better curate your curriculum and track your points as you
      level up in the community!
    </Typography>
  );
}
