import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "hooks";

import useStyles from "./styles";
import Comments from "components/comments";
import EventCard from "./EventCard";

const GET_EVENT_REPORT = /* GraphQL */ `
  query($id: String!) {
    eventReportById(id: $id) {
      id
      eventType
      eventName
      startDate
      endDate
      eventDistribution
      reportDistribution
      mainTakeaway
      keywords
      agenda
      attendees
      images {
        file
      }
      enteredBy {
        firstName
        lastName
      }
      entryDate
      presentations {
        presenterName
        presenterOrg
        title
        dateTime
        presentation
        takeaways {
          takeaway
        }
      }
      conversations {
        discussion
        files {
          file
        }
        participants {
          fullName
          organization
          jobTitle
        }
      }
      additionalDetails {
        label
        type
        detail
      }
      comments {
        content
        createdOn
        user {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export default function EventReportView() {
  let { id } = useParams();
  const api = useAxios();
  const classes = useStyles();
  const [eventReport, setEventReport] = useState();
  const [comments, setComments] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", {
        query: GET_EVENT_REPORT,
        variables: { id },
      })
      .then((res) => {
        const data = res.data.data;
        setEventReport(data.eventReportById);
        setComments(data.eventReportById?.comments);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (eventReport === null) {
    return <div>No event report with that ID exists</div>;
  }

  return (
    <>
      <EventCard
        key={eventReport.id}
        event={eventReport}
        showCommentButton={false}
        viewButton={false}
        viewDetails={true}
      />
      <Comments
        contentType="EVENT_REPORT"
        objectId={eventReport.id}
        comments={comments}
      />
    </>
  );
}
