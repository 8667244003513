import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Help from "@material-ui/icons/Help";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

import { logout } from "context";
import { useAuthDispatch } from "../context";
import logo from "../images/etc_logo.png";

import { useNotifyState } from "notifications/context";

import { OverviewDialog } from "./overview";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  unread: {
    fontWeight: 700,
  },
  allItem: {
    fontSize: 12,
    color: "blue",
    fontWeight: "bold",
    marginLeft: "auto",
    marginRight: "auto",
    width: "82px",
  },
  banner: {
    display: "flex",
    width: "60%",
    background: "rgb(63, 81, 181)",
    border: "0px",
    paddingBottom: "30px",
    margin: "auto",
    textAlign: "center",
  },
  bannerText: {
    fontSize: "1.05em",
    color: "white",
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    right: 10,
    top: 10,
  },
}))(Badge);

export default function MenuAppBar({ children }) {
  const dispatch = useAuthDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [overviewOpen, setOverviewOpen] = React.useState(false);

  const [unreadNotifys, setUnreadNotifys] = useState(0);
  const [anchorN, setAnchorN] = React.useState(null);
  const [openN, setOpenN] = React.useState(false);

  const { notificationsList, markNotifyRead } = useNotifyState();

  useEffect(() => {
    count = 0;
    if (notificationsList.length > 0) {
      notificationsList.forEach((n) =>
        n.categoryEnabled && !n.read && !n.deleted ? count++ : null
      );
    }
    setUnreadNotifys(count);
  }, [notificationsList]);

  //also move this somewhere so its reusable
  const buildLink = (type, c_id) => {
    let rtn = null;

    switch (type) {
      case "note":
        rtn = "/notes/" + c_id;
        break;
      case "ewave":
        rtn = "/notes/ewaves/" + c_id;
        break;
      case "eventreport":
        rtn = "/events/" + c_id;
        break;
      default:
        console.log("error, unknown notification source type");
        return null;
    }

    return rtn;
  };

  const readNotification = (n_id) => {
    markNotifyRead(n_id);
    handleCloseN();
  };

  const handleOverviewOpen = () => {
    setOverviewOpen(true);
  };

  const handleOverviewClose = () => {
    setOverviewOpen(false);
  };

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleNMenu = (e) => {
    setAnchorN(e.currentTarget);
    setOpenN(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseN = () => {
    setOpenN(false);
    setAnchorN(null);
  };

  const handleSignOut = () => {
    logout(dispatch);
  };

  let count = 0;

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {children}
        <img
          className={classes.logo}
          src={logo}
          alt="EWARE Logo"
          style={{ height: 45 }}
        />
        <Typography className={classes.title}>EWARE</Typography>
        <Paper className={classes.banner} square elevation={0}>
          <Typography className={classes.bannerText}>
            This system contains ETC proprietary data and is approved for CUI
            data storage
          </Typography>
        </Paper>
        <div>
          <StyledBadge badgeContent={unreadNotifys} color="secondary">
            <IconButton
              aria-label="current users notifications"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleNMenu}
              color="inherit"
            >
              {unreadNotifys > 0 ? (
                <NotificationsActiveIcon />
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>
          </StyledBadge>
          <Menu
            id="menu-appbar"
            anchorEl={anchorN}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openN}
            onClose={handleCloseN}
          >
            {notificationsList?.map((notification, key) => {
              if (
                count < 4 &&
                notification.categoryEnabled &&
                !notification.deleted
              ) {
                count++;
                return (
                  <div>
                    <MenuItem
                      key={key}
                      component={Link}
                      to={buildLink(
                        notification.notificationCategory.contentType.model,
                        notification.sourceId
                      )}
                      onClick={() => readNotification(notification.id)}
                      className={notification.read ? null : classes.unread}
                    >
                      {notification?.notificationCategory?.defaultMessage}
                      {/* {!notification?.read && (  //Add an icon to indicate unread state?
                        <Tooltip title="New!">
                          <Icon>
                            <NewReleasesIcon
                              color="primary"
                              fontSize="small"
                            ></NewReleasesIcon>
                          </Icon>
                        </Tooltip>
                      )} */}
                    </MenuItem>
                    {count < 4 && <Divider variant="middle" />}
                    {count === 4 && <Divider />}
                  </div>
                );
              } else {
                return null;
              }
            })}
            {/* Add a miniaturized timestamp? Like Xm, or Xd ago */}
            <MenuItem
              component={Link}
              onClick={handleCloseN}
              to={"/notifications"}
              className={classes.allItem}
            >
              View All
            </MenuItem>
          </Menu>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOverviewOpen}
            onClose={handleOverviewClose}
            color="inherit"
          >
            <Help />
          </IconButton>
          <OverviewDialog open={overviewOpen} onClose={handleOverviewClose} />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem component={Link} onClick={handleClose} to={"/profile"}>
              Profile
            </MenuItem>
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
