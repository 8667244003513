import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import { useAxios } from "hooks";

import { ScatterPlot } from "components/visualizations";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

const GET_WORD_EMBEDDING = /* GraphQL */ `
  query($embeddingType: String!) {
    wordEmbedding(embeddingType: $embeddingType) {
      json
    }
  }
`;

const embeddingTypeDefault = "TSNE";

// TODO (aimrie): Add additional types when they
// are available on the backend (PCA, Spectral, etc.);
const embeddingTypeOptions = [embeddingTypeDefault];

export default function WordEmbedding() {
  const api = useAxios();
  const classes = useStyles();
  const [embeddingType, setEmbeddingType] = useState(embeddingTypeDefault);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .post("/graphql", {
        query: GET_WORD_EMBEDDING,
        variables: { embeddingType },
      })
      .then((res) => {
        const data = res.data.data;

        setData(JSON.parse(data.wordEmbedding.json));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [embeddingType]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Autocomplete
        disabled
        value={embeddingType}
        autoHighlight
        disableClearable
        id="embeddingType"
        className={classes.formControl}
        options={embeddingTypeOptions}
        getOptionLabel={(option) => option}
        onChange={(e, value) => {
          setEmbeddingType(value);
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label="Embedding Type"
              name="embeddingType"
            />
          );
        }}
      />
      <ScatterPlot
        className={classes.card}
        data={data}
        xAxisLabel="t-SNE 1"
        yAxisLabel="t-SNE 2"
      />
    </>
  );
}
