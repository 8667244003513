const ENTITY_LABEL_COLORS = {
  ORG: "#7aecec",
  PRODUCT: "#bfeeb7",
  GPE: "#feca74",
  LOC: "#ff9561",
  PERSON: "#aa9cfc",
  NORP: "#c887fb",
  FACILITY: "#9cc9cc",
  EVENT: "#ffeb80",
  LAW: "#ff8197",
  LANGUAGE: "#ff8197",
  WORK_OF_ART: "#f0d0ff",
  DATE: "#bfe1d9",
  TIME: "#bfe1d9",
  MONEY: "#e4e7d2",
  QUANTITY: "#e4e7d2",
  ORDINAL: "#e4e7d2",
  CARDINAL: "#e4e7d2",
  PERCENT: "#e4e7d2",
  element: "#D0F4EA",
  energeticApplication: "#F0A868",
  energeticFormulation: "#957964",
  energeticMaterial: "#279AF1",
  energeticRole: "#474A48",
  formulationProperty: "#537A5A",
  functionalGroup: "#FED4E7",
  materialProperty: "#048A81",
  plasticizer: "#3891A6",
  polymer: "#329F5B",
};

export default ENTITY_LABEL_COLORS;
