import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  point: {},
}));

function renderCircles(classes, data, x, y) {
  return data.map((d, i) => {
    return (
      <circle
        key={i}
        className={classes.point}
        r="5"
        cx={x(d[0])}
        cy={y(d[1])}
        fill="#3885BB"
        // TODO (aimrie): Should we apply a stroke to highlight labeled points?
        // stroke="#3f5175"
        // strokeWidth="1px"
        fillOpacity={0.5}
      />
    );
  });
}

function Points({ data, x, y }) {
  const classes = useStyles();
  const [circles, setCircles] = useState(null);

  useEffect(() => {
    setCircles(renderCircles(classes, data, x, y));
  }, [data, x, y]);

  return <g>{circles}</g>;
}

Points.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array),
  x: PropTypes.func,
  y: PropTypes.func,
};

export default Points;
