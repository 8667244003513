import PropTypes from "prop-types";
import * as d3 from "d3";

import Axis from "./Axis";
import Points from "./Points";
import Annotations from "./Annotations";

function ScatterPlot({
  data,
  annotationKeys,
  width,
  height,
  xAxisLabel,
  yAxisLabel,
}) {
  const margin = { top: 10, right: 30, bottom: 40, left: 50 };
  const w = width - margin.left - margin.right;
  const h = height - margin.top - margin.bottom;
  const translate = `translate(${margin.left}, ${margin.top})`;

  const points = Object.values(data);

  const x = d3
    .scaleLinear()
    .domain(
      d3.extent(points, function (d) {
        return d[0];
      })
    )
    .range([0, w]);

  const xAxis = d3
    .axisBottom(x)
    .tickSize(-h * 1.3)
    .ticks(10);

  const y = d3
    .scaleLinear()
    .domain(
      d3.extent(points, function (d) {
        return d[1];
      })
    )
    .range([h, 0]);

  const yAxis = d3
    .axisLeft(y)
    .tickSize(-w * 1.3)
    .ticks(10);

  return (
    <svg
      id="v1-scatterplot"
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
    >
      <g transform={translate}>
        <Axis h={h} axis={xAxis} axisType="x" axisLabel="X" />
        <Axis h={h} axis={yAxis} axisType="y" axisLabel="Y" />
        <Points data={points} x={x} y={y} />
        <Annotations data={data} annotationKeys={annotationKeys} x={x} y={y} />
        {xAxisLabel && (
          <text textAnchor="end" x={w} y={h + margin.top + 20}>
            {xAxisLabel}
          </text>
        )}
        {yAxisLabel && (
          <text
            textAnchor="end"
            x={-margin.top}
            y={-margin.left + 20}
            transform="rotate(-90)"
          >
            {yAxisLabel}
          </text>
        )}
      </g>
    </svg>
  );
}

ScatterPlot.defaultProps = {
  width: 1200,
  height: 800,
};

ScatterPlot.propTypes = {
  data: PropTypes.object.isRequired,
  annotationKeys: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
  height: PropTypes.number,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default ScatterPlot;
