import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import moment from "moment";

import { useAuthState } from "context/auth";
import EwareCard from "../shared/EwareCard";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "5px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  whiteSpace: {
    whiteSpace: "pre-line",
  },
  titlePad: {
    marginTop: "5px",
  },
}));

export default function EventCard({
  event,
  showCommentButton,
  viewButton,
  viewDetails,
}) {
  const classes = useStyles();
  const commentCount = event.comments.length;
  const {
    user: { email },
  } = useAuthState();

  if (!event.id && !event.pk) {
    console.log("NO KEY!!!!");
  }

  const eventTypeToString = (eventType) => {
    switch (eventType) {
      case "C":
        return "Conference";
      case "P":
        return "Presentation";
      case "T":
        return "Temporary Duty";
      case "S":
        return "Site Visit";
      default:
        return "Other";
    }
  };

  const nameCheck = (enteredBy) => {
    let tmpName = enteredBy.firstName + " " + enteredBy.lastName;
    return tmpName.trim().length < 1 ? "Name not provided" : tmpName;
  };

  const detailTypeToString = (detailType) => {
    switch (detailType) {
      case "OB":
        return "Observation";
      case "WA":
        return "Way Ahead";
      default:
        return "Other";
    }
  };

  const cardContent = {
    title: event.eventName,
    type: "event report",
    targetId: event.pk || event.id,
    numComments: commentCount,
    buttons: viewButton && [
      {
        type: "view",
        show: true,
      },
    ],
  };

  return (
    <EwareCard
      content={cardContent}
      idx={event.id || event.pk}
      showCommentButton={showCommentButton}
      viewButton={viewButton}
    >
      <Grid container>
        {/* entire card internals grid */}
        <Grid item xs={6} container direction="column">
          {/* left side internals grid */}
          <Grid>
            <Grid item>
              <Typography component="span" color="textSecondary">
                Event Type:{" "}
              </Typography>
              <Typography component="span">
                {eventTypeToString(event.eventType)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="span" color="textSecondary">
                Event Distribution:{" "}
              </Typography>
              <Typography component="span">
                {event.eventDistribution}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="span" color="textSecondary">
                Report Distribution:{" "}
              </Typography>
              <Typography component="span">
                {event.reportDistribution}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="column"
          alignContent="space-around"
        >
          {/* right side internals grid */}
          <Grid>
            <Grid item>
              <Typography component="span" color="textSecondary">
                Date:{" "}
              </Typography>
              <Typography component="span">
                {moment(event.startDate).format("MMM Do, YYYY")} -{" "}
                {moment(event.endDate).format("MMM Do, YYYY")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="span" color="textSecondary">
                Entered by:{" "}
              </Typography>
              <Typography component="span">
                {nameCheck(event.enteredBy)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography component="span" color="textSecondary">
            Main Takeaway:{" "}
          </Typography>
          <Typography component="span">{event.mainTakeaway}</Typography>
        </Grid>
      </Grid>
      {viewDetails && (
        <Grid container spacing={2}>
          {" "}
          {/* details section internals grid */}
          {event.presentations.length > 0 && (
            <Grid item>
              <Divider variant="middle" light />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.titlePad}
              >
                Presentations
              </Typography>
            </Grid>
          )}
          {event.presentations.map((p, index, arr) => {
            return (
              <div>
                <Grid item>
                  <Typography component="span">
                    #{index + 1} - {p.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="span" color="textSecondary">
                    Presenter:{" "}
                  </Typography>
                  <Typography component="span">
                    {p.presenterName} - {p.presenterOrg}
                  </Typography>
                </Grid>
                {p.takeaways.length > 0 && (
                  <Grid item>
                    <Typography component="span" color="textSecondary">
                      Takeaways:{" "}
                    </Typography>
                  </Grid>
                )}
                {p.takeaways.map((t) => {
                  return (
                    <Grid item>
                      <Typography component="span">{t.takeaway}</Typography>
                    </Grid>
                  );
                })}
                {arr.length - 1 !== index && <Divider variant="middle" light />}
              </div>
            );
          })}
          {event.conversations.length > 0 && (
            <Grid item>
              <Divider variant="middle" light />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.titlePad}
              >
                Conversations
              </Typography>
            </Grid>
          )}
          {event.conversations.map((c, index, arr) => {
            return (
              <div>
                <Grid item>
                  <Typography component="span" color="textSecondary">
                    #{index + 1} Discussion
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="span">{c.discussion}</Typography>
                  <Typography component="span" color="textSecondary">
                    Participants:{" "}
                  </Typography>
                  {c.participants.map((p, index) => {
                    return (
                      <Typography component="span">
                        {p.fullName} - {p.organization} - {p.jobTitle}
                      </Typography>
                    );
                  })}
                  {arr.length - 1 !== index && (
                    <Divider variant="middle" light />
                  )}
                </Grid>
              </div>
            );
          })}
          {event.additionalDetails.length > 0 && (
            <Grid item>
              <Divider variant="middle" light />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.titlePad}
              >
                Additional Details
              </Typography>
            </Grid>
          )}
          {event.additionalDetails.map((a, index, arr) => {
            return (
              <Grid item>
                <Typography component="span" color="textSecondary">
                  #{index + 1} - {a.label} - {detailTypeToString(a.type)}
                </Typography>
                <Typography>{a.detail}</Typography>
                {arr.length - 1 !== index && <Divider variant="middle" light />}
              </Grid>
            );
          })}
        </Grid>
      )}
    </EwareCard>
  );
}
