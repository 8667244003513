import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Pagination } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

import { useAxios } from "hooks";
import ArticleCard from "./ArticleCard";

const useStyles = makeStyles((theme) => ({
  intro: {
    borderRadius: "5px",
    padding: theme.spacing(1),
  },
}));

const MIN_DATE_DEF = "1900-01-01";
const MAX_DATE_DEF = "2200-01-01";

const GET_KEYWORDS = /* GraphQL */ `
  query {
    allFilterKeywords {
      keyword
    }
  }
`;

/* 
  KNOWN ISSUE 
  Improve search capability
  see EWARE-289
*/

// Might seem a little unintuitive but "Searching" is searching the database for all articles
//  and "Filtering" is filtering the list returned from the search.
//  Therefore the Search query contains all keywords the system approves of, and the Filter query
//    pares down the search results.
const GET_FILTERED_ARTICLES = /* GraphQL */ `
  query(
    $filterArray: [String]
    $orderBy: Direction
    $minDateString: Date
    $maxDateString: Date
  ) {
    allSpiderEventsDocs(
      filter: {
        name: { terms: $filterArray }
        # Casting strings into dates rather than deal with date object magics
        pubDate: {
          range: {
            lower: { date: $minDateString }
            upper: { date: $maxDateString }
          }
        }
      }
      ordering: { pubDate: $orderBy }
    ) {
      edges {
        node {
          pk
          id
          source
          name
          pubDate
          timestamp
          abstract
          sourceUrl
          author
        }
      }
    }
  }
`;

const GET_FILTERED_SEARCHED_ARTICLES = /* GraphQL */ `
  query(
    $searchString: String
    $filterArray: [String]
    $orderBy: Direction
    $minDateString: Date
    $maxDateString: Date
  ) {
    allSpiderEventsDocs(
      search: {
        name: { value: $searchString }
        abstract: { value: $searchString }
      }
      filter: {
        name: { terms: $filterArray }
        # Casting strings into dates rather than deal with date object magics
        pubDate: {
          range: {
            lower: { date: $minDateString }
            upper: { date: $maxDateString }
          }
        }
      }
      ordering: { pubDate: $orderBy }
    ) {
      edges {
        node {
          pk
          id
          source
          name
          pubDate
          timestamp
          abstract
          sourceUrl
          author
        }
      }
    }
  }
`;

const sourceMap = new Map([
  ["AirForceNews", "U.S. Air Force"],
  ["AFRL", "U.S. Air Force Research Laboratory"],
  ["ArmyNews", "U.S. Army"],
  ["ARL", "U.S. Army Research Laboratory"],
  ["Boeing", "Boeing"],
  ["BreakingDef", "Breaking Defense"],
  ["Lockheed", "Lockheed Martin"],
  ["LosAlamos", "Los Alamos National Laboratory"],
  ["NAVAIR", "U.S. Naval Air Systems Command"],
  ["NRL", "U.S. Naval Research Laboratory"],
  ["NAVSEA", "U.S. Naval Sea Systems Command"],
  ["NavyNews", "U.S. Navy Office of Information"],
  ["ONR", "U.S. OFfice of Naval Research"],
  ["Picatinny", "U.S. Army Picatinny Arsenal"],
]);

export default function NewsPage() {
  const api = useAxios();
  const [page, setPage] = useState(1);

  const [defaultSearchArray, setDefaultSearchArray] = useState([]);
  const [filterArray, setFilterArray] = useState([]); //updated async because hook, when it does update it triggers useEffect

  const handlePage = (e, value) => {
    setPage(value);
  };

  const handleMinDate = (e) => {
    let mD = e.target.value;

    mD.length > 1 ? setMinDate(e.target.value) : setMinDate(MIN_DATE_DEF);
  };

  const handleMaxDate = (e) => {
    let mD = e.target.value;

    console.log(mD.length);
    mD.length > 1 ? setMaxDate(e.target.value) : setMaxDate(MAX_DATE_DEF);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let tempArray = []; //Do not update filterArray by pushing each term individually

    console.log(e);

    e.target[0].value
      .split(/[, ]/)
      .filter((t) => t)
      .map((r) => tempArray.push(r.toLowerCase()));

    setFilterArray([...tempArray]); //update filterArray with all terms at once - triggering useEffect when complete (update is async)
  };

  const classes = useStyles();

  const [orderBy, setOrderBy] = useState("DESC");

  const handleSorting = () => {
    orderBy === "DESC" ? setOrderBy("ASC") : setOrderBy("DESC");
  };

  const [articles, setArticles] = useState([]);
  const [pagedRecs, setPagedRecs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  //Simplified: just use dates in every query but default the range to include everything
  const [minDateString, setMinDate] = useState(MIN_DATE_DEF);
  const [maxDateString, setMaxDate] = useState(MAX_DATE_DEF);

  const searchAndFilter = (searchString) => {
    api
      .post("/graphql", {
        query: GET_FILTERED_SEARCHED_ARTICLES,
        variables: {
          searchString,
          filterArray: filterArray.length ? filterArray : null,
          orderBy,
          minDateString,
          maxDateString,
        },
      })
      .then((res) => {
        const data = res.data.data;

        setArticles(data.allSpiderEventsDocs.edges);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onlyFilter = () => {
    api
      .post("/graphql", {
        query: GET_FILTERED_ARTICLES,
        variables: {
          filterArray: filterArray.length ? filterArray : null,
          orderBy,
          minDateString,
          maxDateString,
        },
      })
      .then((res) => {
        const data = res.data.data;

        setArticles(data.allSpiderEventsDocs.edges);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    api
      .post("/graphql", {
        query: GET_KEYWORDS,
      })
      .then((res) => {
        const data = res.data.data;

        setDefaultSearchArray(data.allFilterKeywords.map((x) => x.keyword));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let searchString = defaultSearchArray.join(" ");

    if (searchString.length > 0) {
      searchAndFilter(searchString);
    } else {
      onlyFilter();
    }
  }, [defaultSearchArray, filterArray, orderBy, minDateString, maxDateString]);

  useEffect(() => {
    const start = (page - 1) * 20;
    const end = page * 20;

    setPagedRecs(articles.slice(start, end));
  }, [page, articles]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">News Feed</Typography>
      </Grid>
      <Grid item>
        <Card className={classes.intro}>
          <CardContent>
            <Typography variant="body2" paragraph>
              Welcome to the EWARE News Feed. The items below are brief
              summaries of news articles collected automatically by the EWARE
              system.
            </Typography>
            <Typography variant="body2" paragraph>
              To access the original news articles directly, click the title of
              the article you wish to view.
            </Typography>
            <Typography variant="body2">
              In order to filter the displayed results, enter keywords to search
              for in the search field.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item>
        <Card className={classes.card}>
          <CardContent>
            <form onSubmit={handleSearch}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    label="Search"
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputProps={{ type: "search", readOnly: false }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    onChange={handleMinDate}
                    label="Published After"
                    name="minDate"
                    type="date"
                    margin="normal"
                    variant="outlined"
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    onChange={handleMaxDate}
                    label="Published Before"
                    name="maxDate"
                    type="date"
                    margin="normal"
                    variant="outlined"
                    className={classes.TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {isLoading && (
        <Grid item alignItems="center">
          <p> Loading...</p> <CircularProgress />
        </Grid>
      )}

      {!isLoading &&
        pagedRecs.map(({ node: article }, i) => {
          return (
            <Grid item xs={12}>
              <ArticleCard article={article} key={i} />
            </Grid>
          );
        })}

      <Grid item container direction="row">
        <FormControlLabel
          control={<Switch onChange={handleSorting} />}
          label={
            "Sort: " + (orderBy === "ASC" ? "Newest First" : "Oldest First")
          }
          className={classes.right}
        />

        <Pagination
          count={Math.ceil(articles.length / 20)}
          page={page}
          onChange={handlePage}
        />
      </Grid>
    </Grid>
  );
}
