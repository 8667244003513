import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function EntityTable({ entities }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="entity data table">
        <TableHead>
          <TableRow>
            <TableCell>text</TableCell>
            <TableCell align="right">label</TableCell>
            <TableCell align="right">start</TableCell>
            <TableCell align="right">end</TableCell>
            <TableCell align="right">start_char</TableCell>
            <TableCell align="right">end_char</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entities.map((entity) => (
            <TableRow key={entity.charStart}>
              <TableCell component="th" scope="row">
                {entity.mention}
              </TableCell>
              <TableCell align="right">{entity.label}</TableCell>
              <TableCell align="right">{entity.tokStart}</TableCell>
              <TableCell align="right">{entity.tokEnd}</TableCell>
              <TableCell align="right">{entity.charStart}</TableCell>
              <TableCell align="right">{entity.charEnd}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
