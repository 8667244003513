import { API, createAxiosClient } from "api";
import jwtDecode from "jwt-decode";

const createExpirationDate = () => {
  const HOURS_IN_FUTURE = 10;
  let date = new Date();
  date.setHours(date.getHours() + HOURS_IN_FUTURE);

  return date;
};

const handleOtpDeviceId = (token) => {
  const { otp_device_id } = jwtDecode(token);

  return otp_device_id;
};

export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let response = await API.post("/graphql", loginPayload);
    let data = await response.data.data.tokenAuth;

    if (data.success) {
      data.sessionExpirationDate = createExpirationDate();
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("currentUser", JSON.stringify(data.user));
      localStorage.setItem("token", JSON.stringify(data.token));
      localStorage.setItem("refreshToken", JSON.stringify(data.refreshToken));
      localStorage.setItem(
        "sessionExpirationDate",
        JSON.stringify(data.sessionExpirationDate)
      );

      if (process.env.REACT_APP_2FA_ENABLED === "false") {
        localStorage.setItem("otpDeviceId", true);
      }

      return data;
    }

    dispatch({
      type: "LOGIN_ERROR",
      errors: { ...data.errors },
    });
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", errors: error });
  }
}

export async function logout(dispatch) {
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("sessionExpirationDate");
  localStorage.removeItem("otpDeviceId");
}

export async function expireSession(dispatch) {
  dispatch({ type: "EXPIRE_SESSION" });
  localStorage.removeItem("currentUser");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("sessionExpirationDate");
  localStorage.removeItem("otpDeviceId");
}

export const setNewToken = (dispatch, token) => {
  const payload = {};
  payload.token = token;
  payload.otpDeviceId = handleOtpDeviceId(token);
  dispatch({
    type: "SET_NEW_TOKEN",
    payload,
  });
  localStorage.setItem("token", JSON.stringify(payload.token));
  localStorage.setItem("otpDeviceId", JSON.stringify(payload.otpDeviceId));
};

export async function updateAccount(dispatch, tokens, updatePayload) {
  try {
    dispatch({ type: "REQUEST_UPDATE_ACCOUNT" });
    let response = await createAxiosClient(dispatch, tokens).post(
      "/graphql",
      updatePayload
    );

    let data = await response.data.data.updateAccount;

    if (data.success) {
      dispatch({ type: "UPDATE_ACCOUNT", payload: updatePayload.variables });
      localStorage.setItem(
        "currentUser",
        JSON.stringify(updatePayload.variables)
      );
      return data;
    }

    dispatch({ type: "UPDATE_ACCOUNT_ERROR", error: data.errors });
    return;
  } catch (error) {
    dispatch({ type: "UPDATE_ACCOUNT_ERROR", error: error });
  }
}

export function otpVerify(dispatch, state, payload) {
  dispatch({ type: "REQUEST_OTP_VERIFICATION" });
  return createAxiosClient(dispatch, state)
    .post("/graphql", payload)
    .then((res) => {
      const data = res.data.data.otpVerify;
      if (data.success) {
        data.otpDeviceId = handleOtpDeviceId(data.token);
        dispatch({
          type: "OTP_VERIFICATION_SUCCESS",
          payload: data,
        });
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("otpDeviceId", JSON.stringify(data.otpDeviceId));
      } else {
        dispatch({
          type: "OTP_VERIFICATION_ERROR",
          error: JSON.parse(data.errors[0]),
        });
      }
      return data;
    })
    .catch((err) => {
      dispatch({
        type: "OTP_VERIFICATION_ERROR",
        error: JSON.parse(err),
      });
      return err;
    });
}
