import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import { useAuthState } from "../../context";
import NoteCard from "components/notes/NoteCard";

import { useAxios } from "hooks";

/*
  If the user is staff, returns all recommendations
  regardless of interests
*/
const GET_STAFF_RECOMMENDATIONS = /* GraphQL */ `
  query {
    allNoteDocuments(ordering: { entryDate: DESC }) {
      edges {
        node {
          pk
          title
          topic
          details
          interests
          whyItMatters
          entryDate
          references
          url
          enteredBy {
            email
            firstName
            lastName
          }
          comments {
            createdOn
          }
        }
      }
    }
  }
`;

const GET_RECOMMENDATIONS = /* GraphQL */ `
  query($interestTerms: [String]) {
    allNoteDocuments(
      filter: { interests: { terms: $interestTerms } }
      ordering: { entryDate: DESC }
    ) {
      edges {
        node {
          pk
          title
          topic
          details
          interests
          whyItMatters
          references
          entryDate
          url
          enteredBy {
            email
            firstName
            lastName
          }
          comments {
            createdOn
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({}));

export default function Recommendations() {
  const api = useAxios();
  const { user } = useAuthState();
  const classes = useStyles();
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [toggleStaffView, setToggleStaffView] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setRecommendations([]);

    const constructInterestTerms = (obj) => {
      const keys = Object.keys(obj);
      const res = [];
      for (let i = 0; i < keys.length; i++) {
        res.push(obj[keys[i]]);
      }
      return res;
    };

    const payload =
      user.isStaff && toggleStaffView
        ? {
            query: GET_STAFF_RECOMMENDATIONS,
          }
        : {
            query: GET_RECOMMENDATIONS,
            variables: {
              interestTerms: constructInterestTerms(JSON.parse(user.interests)),
            },
          };

    api
      .post("/graphql", payload)
      .then((res) => {
        const data = res.data.data;

        setRecommendations(data.allNoteDocuments.edges);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [toggleStaffView]);

  return (
    <Grid container width={300} spacing={3}>
      {user.isStaff && (
        <FormControlLabel
          control={
            <Switch
              checked={toggleStaffView}
              onChange={() => setToggleStaffView(!toggleStaffView)}
            />
          }
          label="Disable Filter"
        />
      )}
      {isLoading && (
        <div>
          <p> Loading...</p> <CircularProgress />
        </div>
      )}
      {!isLoading && recommendations.length === 0 && (
        <div>You have no recommended notes at this time</div>
      )}
      {recommendations.map(({ node: note }, i) => {
        return (
          <Grid className={classes.outercard} item xs={12} xl={6}>
            <NoteCard
              key={note.pk}
              note={note}
              showCommentButton={true}
              viewButton={true}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
